import axios from "axios";
import { toast } from "react-toastify";
import { userActions } from "./userReducer";
import { messageActions } from "./messageReducer";
import { dataURItoBlob } from "utils/image";

const FETCH_AMENITIES = "FETCH_AMENITIES";
const FETCH_AMENITIES_SUCCESS = "FETCH_AMENITIES_SUCCESS";
const FETCH_AMENITIES_FAIL = "FETCH_AMENITIES_FAIL";

const FETCH_AMENITY = "FETCH_AMENITY";
const FETCH_AMENITY_SUCCESS = "FETCH_AMENITY_SUCCESS";
const FETCH_AMENITY_FAIL = "FETCH_AMENITY_FAIL";

const ADD_AMENITY = "ADD_AMENITY";
const ADDING_AMENITY = "ADD_AMENITY";
const ADD_AMENITY_SUCCESS = "ADD_AMENITY_SUCCESS";
const ADD_AMENITY_FAIL = "ADD_AMENITY_FAIL";

const UPDATE_AMENITY = "UPDATE_AMENITY";
const UPDATE_AMENITY_SUCCESS = "UPDATE_AMENITY_SUCCESS";
const UPDATE_AMENITY_FAIL = "UPDATE_AMENITY_FAIL";

const FETCH_AMENITY_SCHEDULE = "FETCH_AMENITY_SCHEDULE";
const FETCH_AMENITY_SCHEDULE_SUCCESS = "FETCH_AMENITY_SCHEDULE_SUCCESS";
const FETCH_AMENITY_SCHEDULE_FAIL = "FETCH_AMENITY_SCHEDULE_FAIL";

const RESERVE_AMENITY = "RESERVE_AMENITY";
const RESERVE_AMENITY_SUCCESS = "RESERVE_AMENITY_SUCCESS";
const RESERVE_AMENITY_FAIL = "RESERVE_AMENITY_FAIL";

const RESET_AMENITY = "RESET_AMENITY";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export const amenityActions = {
    FETCH_AMENITIES,
    FETCH_AMENITIES_SUCCESS,
    FETCH_AMENITIES_FAIL,
    FETCH_AMENITY,
    FETCH_AMENITY_SUCCESS,
    FETCH_AMENITY_FAIL,
    ADD_AMENITY,
    ADDING_AMENITY,
    ADD_AMENITY_SUCCESS,
    ADD_AMENITY_FAIL,
    UPDATE_AMENITY,
    UPDATE_AMENITY_SUCCESS,
    UPDATE_AMENITY_FAIL,
    FETCH_AMENITY_SCHEDULE,
    FETCH_AMENITY_SCHEDULE_SUCCESS,
    FETCH_AMENITY_SCHEDULE_FAIL,
    RESERVE_AMENITY,
    RESERVE_AMENITY_SUCCESS,
    RESERVE_AMENITY_FAIL,
    RESET_AMENITY
};

export default (state, action) => {
    const { payload } = action;
    switch (action.type) {
        case ADD_AMENITY_SUCCESS:
            return state.concat(payload);
        case UPDATE_AMENITY_SUCCESS:
            return state.map(a =>
                a.id === payload.id
                    ? Object.assign({}, a, {
                          name: payload.name,
                          rate: payload.rate,
                          capacity: payload.capacity,
                          thumbnail: payload.thumbnail,
                          description: payload.description,
                          buildingId: payload.buildingId,
                          buildingName: payload.buildingName
                      })
                    : a
            );
        default:
            return state;
    }
};

export const fetchAmenities = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_AMENITIES });
        return axios
            .request({
                url: `/api/${state.isAdmin ? "admin" : "account"}/amenities`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_AMENITIES_SUCCESS,
                        payload: res.amenities
                    });
                    return res.amenities;
                } else {
                    dispatch({ type: FETCH_AMENITIES_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_AMENITIES_FAIL });
            });
    };
};

export const fetchAmenity = ({ amenityId, date }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_AMENITY });
        return axios
            .request({
                url: `/api/${state.isAdmin ? "admin" : "account"}/amenity`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                params: {
                    id: amenityId,
                    date
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_AMENITY_SUCCESS,
                        payload: res
                    });
                } else {
                    dispatch({ type: FETCH_AMENITY_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_AMENITY_FAIL });
            });
    };
};

export const addAmenity = ({ name, building, rate, capacity, description, images }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: ADD_AMENITY });
        let formData = new FormData();
        formData.append("buildingId", building.value);
        formData.append("name", name);
        formData.append("rate", rate);
        formData.append("capacity", capacity);
        formData.append("description", description);
        const blobsToSend = images.map(i => dataURItoBlob(i.preview));
        blobsToSend.map((blob, i) =>
            i === 0
                ? formData.append("thumbnail", blob)
                : formData.append(`images[${i}]`, blob)
        );

        return axios
            .request({
                url: `/api/admin/add_amenity`,
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                timeout: 60000,
                onUploadProgress: progressEvent => {
                    if (progressEvent.lengthComputable) {
                        const percentLoaded =
                            progressEvent.loaded / progressEvent.total;
                        dispatch({
                            type: ADDING_AMENITY,
                            payload: percentLoaded * 100
                        });
                    }
                },
                data: formData,
                cancelToken: source.token
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: ADD_AMENITY_SUCCESS,
                        payload: {
                            id: res.amenity.id,
                            name,
                            rate,
                            capacity,
                            thumbnail: res.amenity.thumbnail,
                            description,
                            buildingId: building.value,
                            buildingName: building.label
                        }
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                    toast("Successfully added new amenity.");
                } else {
                    dispatch({ type: ADD_AMENITY_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: ADD_AMENITY_FAIL });
            });
    };
};

export const updateAmenity = ({
    id,
    name,
    rate,
    capacity,
    description,
    building,
    images
}) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: UPDATE_AMENITY });
        let formData = new FormData();
        formData.append("id", id);
        formData.append("buildingId", building.value);
        formData.append("name", name);
        formData.append("rate", rate);
        formData.append("capacity", capacity);
        formData.append("description", description);
        const blobsToSend = images.map(i => dataURItoBlob(i.preview));
        blobsToSend.map((blob, i) =>
            i === 0
                ? formData.append("thumbnail", blob)
                : formData.append(`images[${i}]`, blob)
        );

        return axios
            .request({
                url: `/api/admin/update_amenity`,
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                timeout: 60000,
                onUploadProgress: progressEvent => {
                    if (progressEvent.lengthComputable) {
                        const percentLoaded =
                            progressEvent.loaded / progressEvent.total;
                        dispatch({
                            type: ADDING_AMENITY,
                            payload: percentLoaded * 100
                        });
                    }
                },
                data: formData,
                cancelToken: source.token
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: UPDATE_AMENITY_SUCCESS,
                        payload: {
                            id,
                            name,
                            rate,
                            capacity,
                            thumbnail: res.amenity.thumbnail,
                            description,
                            buildingId: building.value,
                            buildingName: building.label
                        }
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                    toast("Successfully updated amenity.");
                } else {
                    dispatch({ type: UPDATE_AMENITY_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: UPDATE_AMENITY_FAIL });
            });
    };
};

export const fetchAmenitySchedule = ({ amenityId, date }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_AMENITY_SCHEDULE });
        return axios
            .request({
                url: `/api/${
                    state.isAdmin ? "admin" : "account"
                }/amenity_schedule`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                params: {
                    amenityId,
                    date
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_AMENITY_SCHEDULE_SUCCESS,
                        payload: res.schedule
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                } else {
                    dispatch({ type: FETCH_AMENITY_SCHEDULE_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_AMENITY_SCHEDULE_FAIL });
            });
    };
};

export const reserveAmenity = ({
    amenityId,
    bookings,
    amount,
    isOwner,
    unitId,
    firstName,
    lastName,
    email,
    contactNo,
    isPaid,
    referenceNumber,
    remarks
}) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: RESERVE_AMENITY });
        return axios
            .request({
                url: `/api/${
                    state.isAdmin ? "admin" : "account"
                }/reserve_amenity`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    amenityId,
                    bookings,
                    amount,
                    isOwner,
                    unitId,
                    firstName,
                    lastName,
                    email,
                    contactNo,
                    isPaid,
                    referenceNumber,
                    remarks
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: RESERVE_AMENITY_SUCCESS,
                        payload: res
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                } else {
                    dispatch({ type: RESERVE_AMENITY_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Something went wrong please try again later."
                    });
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: RESERVE_AMENITY_FAIL });
            });
    };
};

export const resetAmenity = () => {
    return dispatch => dispatch({ type: RESET_AMENITY });
};

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import styled, { css } from "styled-components";
import { Link } from "react-router-dom";
import {
    FiMaximize2,
    FiMinimize2,
    FiEye,
    FiChevronRight,
    FiInfo,
} from "react-icons/fi";
import colors from "utils/colors";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";
import ConfirmationModal from "containers/ConfirmationModal";
import { currencyFormat } from "utils/InputPatterns";
import { fetchUnit } from "reducers/unitReducer";
import { paypalRedirect, dragonPayRedirect } from "reducers/paymentReducer";
import {
    ContentContainer,
    MainHeader,
    SubHeader,
    InfoContainer,
    InfoLabel,
    InfoText,
    PesoSign,
} from "styles/Containers";
import {
    TextLink,
    PaypalButton,
    DragonPayButton,
    DownloadButton,
    NewItemLink,
    ClickPayNowbutton,
} from "styles/Buttons";
import OnlinePaymentModal from "../OnlinePaymentModal";


const TenantDetailsContainer = styled.div`
    width: 200px;
    padding: 8px;
    margin-top: 18px;
    border: solid 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 4px;

    ${(props) =>
        props.detailsVisible
            ? css`
                  width: 400px;
              `
            : css`
                  cursor: pointer;
              `}
`;

const Separator = styled.div`
    height: 1px;
    border: solid 0.5px #ddd;
    margin: 20px 0;
`;

const PaymentInfoText = styled.div`
    display: flex;
    align-items: center;
    color: ${colors.PRIMARY};
    font-size: 12px;
`;

class AccountUnitContainer extends Component {
    state = {
        tenantDetailsVisible: false,
        confirmationModalVisible: false,
        onlinePaymentModalVisible: false,
    };

    componentDidMount() {
        const { unitId, isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchUnit(unitId));
        }
    }

    showTenantDetails() {
        const { tenantDetailsVisible } = this.state;
        if (!tenantDetailsVisible) {
            this.setState({ tenantDetailsVisible: true });
        }
    }

    hideTenantDetails() {
        this.setState({ tenantDetailsVisible: false });
    }

    handlePaypalClick() {
        const { unit, dispatch } = this.props;
        if (unit.nearestBill) {
            dispatch(paypalRedirect(unit.nearestBill.id)).then(() =>
                this.setState({ confirmationModalVisible: true })
            );
        }
    }

    handleDragonPayClick() {
        const { unit, dispatch } = this.props;
        if (unit.nearestBill) {
            dispatch(dragonPayRedirect(unit.nearestBill.id)).then(() =>
                this.setState({ confirmationModalVisible: true })
            );
        }
    }

    handleCreditCardPaymentSuccess() {
        const { unitId, dispatch } = this.props;
        dispatch(fetchUnit(unitId));
    }

    showOnlinePaymentModal() {
        this.setState({ onlinePaymentModalVisible: true });
    }

    hideOnlinePaymentModal() {
        this.setState({ onlinePaymentModalVisible: false });
    }

    render() {
        const {
            tenantDetailsVisible,
            confirmationModalVisible,
            onlinePaymentModalVisible
        } = this.state;

        const { unit, isLoading, isModalLoading, backTo } = this.props;

        // console.log(unit)

        return (
            <Fragment>
                <PageHeader
                    title={backTo && backTo.label}
                    backTo={backTo && backTo.value}
                />
                {isLoading ? (
                    <Spinner />
                ) : (
                    unit && (
                        <ContentContainer>
                            <MainHeader>Unit {unit.number}</MainHeader>
                            <SubHeader>{unit.propertyName}</SubHeader>
                            <InfoContainer>
                                <div>
                                    <InfoLabel>Building</InfoLabel>
                                    <InfoText>{unit.buildingName}</InfoText>
                                </div>
                                <div>
                                    <InfoLabel>Floor</InfoLabel>
                                    <InfoText>{unit.floor}</InfoText>
                                </div>
                                <div>
                                    <InfoLabel>Area</InfoLabel>
                                    <InfoText>{unit.floorArea}sqm</InfoText>
                                </div>
                            </InfoContainer>
                            <InfoContainer>
                                <div>
                                    <InfoLabel>Type</InfoLabel>
                                    <InfoText>{unit.unitType}</InfoText>
                                </div>
                            </InfoContainer>
                            {unit.isOwner && unit.tenant && (
                                <TenantDetailsContainer
                                    onClick={() => this.showTenantDetails()}
                                    detailsVisible={tenantDetailsVisible}
                                >
                                    <InfoContainer
                                        style={{
                                            marginTop: "0",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            <InfoLabel>Tenant</InfoLabel>
                                            <InfoText>
                                                {unit.tenant.name}
                                            </InfoText>
                                        </div>
                                        <div>
                                            {tenantDetailsVisible ? (
                                                <Fragment>
                                                    <Link
                                                        style={{
                                                            color: "black",
                                                            textDecoration:
                                                                "none",
                                                        }}
                                                        to={{
                                                            pathname: `/account/tenants/${unit.tenant.id}`,
                                                            state: {
                                                                backTo: {
                                                                    label:
                                                                        "Back to Unit",
                                                                    value: `/account/units/${unit.id}`,
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        <FiEye
                                                            style={{
                                                                marginLeft:
                                                                    "5px",
                                                                cursor:
                                                                    "pointer",
                                                            }}
                                                        />
                                                    </Link>
                                                    <FiMinimize2
                                                        style={{
                                                            marginLeft: "5px",
                                                            cursor: "pointer",
                                                        }}
                                                        onClick={() =>
                                                            this.hideTenantDetails()
                                                        }
                                                    />
                                                </Fragment>
                                            ) : (
                                                <FiMaximize2 />
                                            )}
                                        </div>
                                    </InfoContainer>
                                    {tenantDetailsVisible && (
                                        <InfoContainer>
                                            <div>
                                                <InfoLabel>
                                                    Contract Start
                                                </InfoLabel>
                                                <InfoText>
                                                    {moment(
                                                        unit.tenant
                                                            .contractStart
                                                    ).format("MM/DD/YYYY")}
                                                </InfoText>
                                            </div>
                                            <div>
                                                <InfoLabel>
                                                    Contract End
                                                </InfoLabel>
                                                <InfoText>
                                                    {moment(
                                                        unit.tenant.contractEnd
                                                    ).format("MM/DD/YYYY")}
                                                </InfoText>
                                            </div>
                                        </InfoContainer>
                                    )}
                                </TenantDetailsContainer>
                            )}
                            {!unit.isOwner && (
                                <InfoContainer>
                                    <div>
                                        <InfoLabel>Contract Start</InfoLabel>
                                        <InfoText>
                                            {moment(unit.contractStart).format(
                                                "MM/DD/YYYY"
                                            )}
                                        </InfoText>
                                    </div>
                                    <div>
                                        <InfoLabel>Contract End</InfoLabel>
                                        <InfoText>
                                            {moment(unit.contractEnd).format(
                                                "MM/DD/YYYY"
                                            )}
                                        </InfoText>
                                    </div>
                                </InfoContainer>
                            )}
                            {unit.nearestBill && (
                                <Fragment>
                                    <Separator />
                                    <SubHeader>Next Outstanding Bill</SubHeader>
                                    <div
                                        style={{
                                            display: "flex",
                                            flexWrap: "wrap",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        <div>
                                            <div
                                                style={{
                                                    width: "300px",
                                                    margin: "20px 20px 20px 0",
                                                }}
                                            >
                                                <div>
                                                    {unit.nearestBill.category}
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div>Due:</div>
                                                    <div>
                                                        {moment(
                                                            unit.nearestBill
                                                                .dueDate
                                                        ).format("MM/DD/YYYY")}
                                                    </div>
                                                </div>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent:
                                                            "space-between",
                                                    }}
                                                >
                                                    <div>PHP</div>
                                                    <div>
                                                        {currencyFormat(
                                                            unit.nearestBill
                                                                .totalAmountDue
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                // <PaymentInfoText>
                                                //     <FiInfo
                                                //         style={{
                                                //             marginRight: "2px",
                                                //         }}
                                                //     />
                                                //     <PesoSign />
                                                //     <span>
                                                //         Online transactions has a
                                                //         fee of{" "}
                                                //         {unit.nearestBill
                                                //             .transactionFee
                                                //             ? currencyFormat(
                                                //                   unit.nearestBill
                                                //                       .transactionFee
                                                //               )
                                                //             : "0.00"}
                                                //     </span>
                                                // </PaymentInfoText>
                                            }
                                            <ClickPayNowbutton
                                                onClick={() =>
                                                    this.showOnlinePaymentModal()
                                                    }
                                                style={{
                                                    width: "fit-content",
                                                }}
                                                >
                                                    Click here to pay now!
                                            </ClickPayNowbutton>
                                            <div style={{ marginTop: "20px" }}>
                                                <TextLink
                                                    to={{
                                                        pathname: `/account/bills/${unit.nearestBill.id}`,
                                                        state: {
                                                            backTo: {
                                                                label:
                                                                    "Back to Unit",
                                                                value: `/account/units/${unit.id}`,
                                                            },
                                                        },
                                                    }}
                                                >
                                                    <FiChevronRight
                                                        style={{
                                                            marginBottom:
                                                                "-3px",
                                                        }}
                                                    />{" "}
                                                    View Details
                                                </TextLink>
                                            </div>
                                        </div>
                                        <div>
                                            {confirmationModalVisible && (
                                                <ConfirmationModal
                                                    body="Page needs to be refreshed to show changes after payment."
                                                    modalVisible={
                                                        confirmationModalVisible
                                                    }
                                                    submitText="Refresh now"
                                                    cancelText="Maybe later"
                                                    onSubmit={() =>
                                                        location.reload()
                                                    }
                                                    closeModal={() =>
                                                        this.setState({
                                                            confirmationModalVisible: false,
                                                        })
                                                    }
                                                />
                                            )}
                                            {onlinePaymentModalVisible && (
                                                <OnlinePaymentModal
                                                    transactionFee=
                                                        {unit.nearestBill.transactionFee
                                                            ? currencyFormat(unit.nearestBill.transactionFee)
                                                            : "0.00"}
                                                    totalAmountDue={unit.nearestBill.totalAmountDue}
                                                    remainingBalance={unit.nearestBill.remainingBalance}
                                                    modalVisible={onlinePaymentModalVisible}
                                                    submitText="Close"
                                                    onSubmit={this.hideOnlinePaymentModal.bind(
                                                        this
                                                    )}
                                                    closeModal={this.hideOnlinePaymentModal.bind(
                                                        this
                                                    )}
                                                    handlePaypalClick={this.handlePaypalClick.bind(
                                                        this
                                                    )}
                                                    handleDragonPayClick={this.handleDragonPayClick.bind(
                                                        this
                                                    )}
                                                />
                                            )}
                                        </div>
                                    </div>
                                </Fragment>
                            )}
                        </ContentContainer>
                    )
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        unit: state.unit,
        isLoading: state.isLoading,
        isModalLoading: state.isModalLoading,
        isAuthorized: state.isAuthorized,
    };
};

export default connect(mapStateToProps)(AccountUnitContainer);

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import "react-datepicker/dist/react-datepicker.css";
import styled from "styled-components";
import colors from "utils/colors";
import { messageActions } from "reducers/messageReducer";
import MessageContainer from "containers/MessageContainer";
import { Loader } from "containers/Loader";
import { addAmenity, updateAmenity } from "reducers/amenityReducer";
import { positiveNumber } from "utils/InputPatterns";
import {
    FlexCenterContainer,
    InfoContainer,
    InfoLabel
} from "styles/Containers";
import { modifyImage, readFile } from "utils/image";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import { Input, TextArea, InputCurrency, Select } from "styles/Inputs";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";

const AmenityThumbnailPreviewContainer = styled(FlexCenterContainer)`
    height: 175px;
`;

const AmenityThumbnailPreview = styled.img`
    height: 175px;
    width: 250px;
    border-radius: 6px;
    margin-top: 18px;
`;

const EmptyThumbnailContainer = styled(FlexCenterContainer)`
    height: 175px;
    width: 250px;
    border: dashed 1px ${colors.GRAY};
`;

class AdminAmenityModal extends Component {
    state = {
        id: null,
        name: "",
        rate: null,
        capacity: "",
        description: "",
        images: [],
        preview: null,
        building: null,
        isAdding: false,
        hasError: false
    };

    componentDidMount() {
        const { amenity } = this.props;
        if (amenity) {
            this.setState({
                id: amenity.id,
                name: amenity.name,
                rate: amenity.rate,
                capacity: amenity.capacity,
                description: amenity.description,
                preview: amenity.thumbnail,
                building: {
                    value: amenity.buildingId,
                    label: amenity.buildingName
                }
            });
        }
    }

    handleImageChange(e) {
        this.setState({ isAdding: true });

        const { images } = this.state;
        let newImages = [];
        /* create an array of promises */
        Array.from(e.target.files).forEach((f, i) => {
            newImages.push(readFile(f));
        });

        Promise.all(newImages).then(res =>
            Promise.all(res.map(r => modifyImage(r))).then(allRes => {
                const concatAllRes = images.concat(allRes);
                this.setState({
                    images: concatAllRes,
                    preview: concatAllRes[0].preview,
                    isAdding: false
                });
            })
        );
    }

    handleRateChange(e, maskedValue, floatValue) {
        this.setState({ rate: floatValue });
    }

    async handleSubmit() {
        const {
            amenity,
            amenityNames,
            closeModal,
            onSuccess,
            dispatch
        } = this.props;
        const { id, name, rate, capacity, images, description, building } = this.state;

        let errorMessage = "";
        errorMessage =
            !name || !rate || !capacity || !description || !building
                ? "Please fill up all required fields."
                : "";
        errorMessage =
            capacity === "0"
            ? errorMessage.length > 0
                ? errorMessage + " Minimum capacity should be 1."
                : "Minimum capacity should be 1."
            : errorMessage;
        errorMessage =
            !amenity && images.length === 0
                ? errorMessage.length > 0
                    ? errorMessage + " Please attach a thumbnail."
                    : "Please attach a thumbnail."
                : errorMessage;
        errorMessage =
            name &&
            (amenity
                ? amenityNames.filter(a => a !== amenity.name).includes(name)
                : amenityNames.includes(name))
                ? errorMessage.length > 0
                    ? errorMessage + " Name should be unique."
                    : "Name should be unique."
                : errorMessage;

        if (errorMessage.length > 0) {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: errorMessage
            });
            this.setState({ hasError: true });
        } else {
            if (id) {
                await dispatch(updateAmenity(this.state));
            } else {
                await dispatch(addAmenity(this.state));
            }
            if (!this.props.errorMessage) {
                this.setState({ hasError: false });
                onSuccess();
                closeModal();
            }
        }
    }

    handleClose() {
        const { closeModal, dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE
        });
        this.setState({ hasError: false });
        closeModal();
    }

    render() {
        const {
            amenity,
            amenityNames,
            buildings,
            modalVisible,
            isModalLoading
        } = this.props;
        const {
            id,
            name,
            rate,
            capacity,
            description,
            preview,
            building,
            hasError
        } = this.state;

        const buildingOptions =
            buildings &&
            buildings.map(b => {
                return { value: b.id, label: b.name };
            });

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={
                    isModalLoading ? null : this.handleClose.bind(this)
                }
                style={modalStyle({ height: 720, width: 420 })}
            >
                <CloseButton
                    onClick={() => (isModalLoading ? null : this.handleClose())}
                />
                <ModalHeaderContainer>
                    <ModalHeader>{id ? "Edit" : "New"} Amenity</ModalHeader>
                </ModalHeaderContainer>
                <ModalBody
                    height="480px"
                    style={{
                        overflowX: "auto"
                    }}
                >
                    <InfoContainer style={{ marginTop: 0 }}>
                        <div style={{ width: "100%" }}>
                            <InfoLabel required>BUILDING</InfoLabel>
                            <Select
                                value={building}
                                disabled={isModalLoading}
                                required={!building && hasError}
                                onChange={selectedBuilding =>
                                    this.setState({
                                        building: selectedBuilding,
                                        unit: null
                                    })
                                }
                                options={buildingOptions}
                            />
                        </div>
                    </InfoContainer>
                    <InfoContainer>
                        <div style={{ width: "120px" }}>
                            <InfoLabel required>RATE</InfoLabel>
                            <InputCurrency
                                disabled={isModalLoading}
                                required={!rate && hasError}
                                allowEmpty
                                value={rate}
                                onChangeEvent={(e, maskedValue, floatValue) =>
                                    this.setState({
                                        rate: floatValue
                                    })
                                }
                            />
                        </div>
                        <div
                            style={{
                                width: "40px",
                                fontSize: "18px",
                                marginTop: "32px",
                                marginLeft: "4px"
                            }}
                        >
                            /hour
                        </div>
                        <div style={{ width: "50%" }}>
                            <InfoLabel required>CAPACITY</InfoLabel>
                            <Input
                                type="number"
                                min={1}
                                required={
                                    (!capacity || capacity === "0") && hasError
                                }
                                disabled={isModalLoading}
                                value={capacity}
                                onKeyDown={e =>
                                    positiveNumber(e)
                                }
                                onChange={e =>
                                    this.setState({
                                        capacity:
                                            e.target.value
                                    })
                                }
                            />
                        </div>
                    </InfoContainer>
                    <div>
                        <InfoLabel required>NAME</InfoLabel>
                        <Input
                            disabled={isModalLoading}
                            required={
                                (!name || amenityNames.includes(name)) &&
                                hasError
                            }
                            value={name}
                            onChange={e =>
                                this.setState({
                                    name: e.target.value
                                })
                            }
                        />
                    </div>
                    <InfoLabel
                        style={{ marginTop: "18px" }}
                        required={!amenity}
                    >
                        THUMBNAIL
                    </InfoLabel>
                    <input
                        type="file"
                        accept="image/*"
                        onChange={e => this.handleImageChange(e)}
                        disabled={isModalLoading}
                    />
                    <AmenityThumbnailPreviewContainer>
                        {preview ? (
                            <AmenityThumbnailPreview src={preview} />
                        ) : (
                            <EmptyThumbnailContainer>
                                Image Preview Here
                            </EmptyThumbnailContainer>
                        )}
                    </AmenityThumbnailPreviewContainer>
                    <div style={{ marginTop: "18px" }}>
                        <InfoLabel required>DESCRIPTION</InfoLabel>
                        <TextArea
                            rows={4}
                            required={!description && hasError}
                            disabled={isModalLoading}
                            value={description}
                            onChange={e =>
                                this.setState({
                                    description: e.target.value
                                })
                            }
                        />
                    </div>
                    <MessageContainer />
                </ModalBody>
                <ModalFooter style={{ height: "110px" }}>
                    {isModalLoading ? (
                        <Loader text="PROCESSING..." />
                    ) : (
                        <Fragment>
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px"
                                }}
                                onClick={() => this.handleSubmit()}
                                text={id ? "UPDATE AMENITY" : "ADD AMENITY"}
                            />
                            <PrimaryAltButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px",
                                    marginTop: "8px"
                                }}
                                onClick={() => this.handleClose()}
                                text="CANCEL"
                            />
                        </Fragment>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        amenityNames: state.amenities && state.amenities.map(a => a.name),
        buildings: state.buildings,
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};
export default connect(mapStateToProps)(AdminAmenityModal);

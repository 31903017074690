import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import colors from "utils/colors";
import styled, { css } from "styled-components";
import moment from "moment";
import Modal from "react-modal";
import { FiX } from "react-icons/fi";
import { MdAddCircleOutline } from "react-icons/md";
import { messageActions } from "reducers/messageReducer";
import MessageContainer from "./MessageContainer";
import { Loader } from "./Loader";
import { positiveNumber, currencyFormat } from "utils/InputPatterns";
import { addBill } from "reducers/billReducer";
import { InfoHeader, InfoContainer, InfoLabel, PesoSign } from "styles/Containers";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter
} from "styles/Modals";
import {
    Input,
    TextArea,
    FlippedInputDatePickerContainer,
    InputDatePicker,
    InputCurrency,
    Select
} from "styles/Inputs";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";


const AddDiscountButton = styled.button`
    display: flex;
    align-items: center;
    margin-top: 20px;
    color: ${colors.BLUE};
    background-color: white;
    font-size: small;
    cursor: pointer;
    border: none;

    :focus {
        outline: none;
    }

    ${props =>
        props.disabled &&
        css`
            color: gray;
            cursor: unset;
        `}
`;

const TotalContainer = styled.div`
    position: relative;
    z-index: 0;
    margin: 20px 0;
    font-weight: bold;
`;

const DiscountAction = styled.button`
    background-color: white;
    border: none;
    font-size: unset;
    width: 20px;
    padding: 10px;
    cursor: pointer;

    :focus {
        outline: none;
    }
`;

class UnitBillModal extends Component {

    state = {
        amount: null,
        rate: "",
        consumption: "",
        discounts: [],
        coverageStart: null,
        coverageEnd: null,
        billDate: new Date(),
        dueDate: null,
        billingCategory: null,
        notifyTenant: this.props.unit && this.props.unit.tenant ? true : false,
        remarks: "",
        hasError: false,
    };

    async handleSubmit(subTotal, total) {
        const { building, unit, closeModal, onSubmit, dispatch } = this.props;
        const {
            amount,
            rate,
            consumption,
            coverageStart,
            coverageEnd,
            billDate,
            dueDate,
            billingCategory,
            discounts
        } = this.state;

        const requiredFieldsFilled =
            billDate &&
            dueDate &&
            (coverageStart || coverageEnd
                ? coverageStart && coverageEnd
                : true);
        const totalOnlyRequiredFieldsFilled = amount && amount > 0;
        const floorSizedDependentRequiredFieldsFilled =
            rate && rate.trim().length > 0;
        const utilityTypeRequiredFieldsFilled =
            rate &&
            rate.trim().length > 0 &&
            consumption &&
            consumption.trim().length > 0;
        const discountsFieldsNotFilled =
            discounts &&
            discounts.length > 0 &&
            Boolean(
                discounts.find(
                    d =>
                        !d.description ||
                        d.description.trim().length === 0 ||
                        !d.amount ||
                        d <= 0
                )
            );

        if (
            !billingCategory ||
            !requiredFieldsFilled ||
            (billingCategory.value.type === "total_only" &&
                !totalOnlyRequiredFieldsFilled) ||
            (billingCategory.value.type === "floor_sized_dependent" &&
                !floorSizedDependentRequiredFieldsFilled) ||
            (billingCategory.value.type === "rate_type" &&
                !utilityTypeRequiredFieldsFilled) ||
            discountsFieldsNotFilled
        ) {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: "Please fill all required fields."
            });
            this.setState({ hasError: true });
        } else {
            const unitBill = Object.assign({}, this.state, {
                buildingId: building.id,
                buildingName: building.name,
                unitId: unit.id,
                unitNumber: unit.number,
                subTotal: subTotal,
                total: total
            });
            await dispatch(addBill(unitBill));
            if (!this.props.errorMessage) {
                this.setState({ hasError: false });
                closeModal();
                if (Boolean(onSubmit)) {
                    onSubmit();
                }
            }
        }
    }

    handleClose() {
        const { closeModal, dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE
        });
        this.setState({ hasError: false });
        closeModal();
    }

    handleAddDiscount() {
        const { discounts } = this.state;
        this.setState({
            discounts: discounts.concat({
                id: discounts.length,
                description: "",
                amount: null
            })
        });
    }

    handleDiscountDescriptionChange(id, description) {
        const { discounts } = this.state;
        const newDiscounts = discounts.map(discount =>
            discount.id === id
                ? Object.assign({}, discount, {
                      description: description
                  })
                : discount
        );
        this.setState({ discounts: newDiscounts });
    }

    handleDiscountAmountChange(e, maskedValue, floatValue) {
        const { discounts } = this.state;
        const newDiscounts = discounts.map(discount =>
            discount.id.toString() === e.target.id
                ? Object.assign({}, discount, {
                      amount: floatValue
                  })
                : discount
        );
        this.setState({ discounts: newDiscounts });
    }

    handleRemoveDiscount(id) {
        const { discounts } = this.state;
        const newDiscounts = discounts
            .filter(discount => discount.id !== id)
            .map(discount =>
                discount.id > id
                    ? Object.assign({}, discount, { id: discount.id - 1 })
                    : discount
            );
        this.setState({ discounts: newDiscounts });
    }

    handleAmountChange(e, maskedValue, floatValue) {
        this.setState({ amount: floatValue });
    }

    render() {
        const {
            unit,
            billingCategories,
            isModalLoading,
            modalVisible
        } = this.props;
        const {
            amount,
            discounts,
            rate,
            consumption,
            coverageStart,
            coverageEnd,
            billDate,
            dueDate,
            billingCategory,
            notifyTenant,
            hasError
        } = this.state;

        const billingCategoryOptions = billingCategories.map(bc => {
            return {
                value: bc,
                label: bc.name
            };
        });

        const discountRows = discounts.map(discount => (
            <InfoContainer key={discount.id} style={{ marginTop: "8px" }}>
                <div style={{ width: "315px" }}>
                    <Input
                        required={
                            (!discount.description ||
                                discount.description.trim().length === 0) &&
                            hasError
                        }
                        value={discount.description}
                        disabled={isModalLoading}
                        onChange={e =>
                            this.handleDiscountDescriptionChange(
                                discount.id,
                                e.target.value
                            )
                        }
                    />
                </div>
                <div
                    style={{
                        width: "200px"
                    }}
                >
                    <InputCurrency
                        id={discount.id}
                        required={
                            (!discount.amount || discount.amount <= 0) &&
                            hasError
                        }
                        disabled={isModalLoading}
                        allowEmpty
                        value={discount.amount}
                        onChangeEvent={this.handleDiscountAmountChange.bind(
                            this
                        )}
                    />
                </div>
                <DiscountAction
                    onClick={() => this.handleRemoveDiscount(discount.id)}
                >
                    <FiX />
                </DiscountAction>
            </InfoContainer>
        ));

        const subTotal = billingCategory
            ? billingCategory.value.type === "floor_sized_dependent"
                ? (Number(unit.floorArea) * Number(rate)).toFixed(2)
                : billingCategory.value.type === "rate_type"
                ? (Number(consumption) * Number(rate)).toFixed(2)
                : amount
            : 0;

        const totalDiscounts =
            discounts.length > 0
                ? discounts.reduce(
                      (acc, curr) => acc + (Number(curr.amount) || 0),
                      0
                  )
                : 0;

        const total = (subTotal - totalDiscounts).toFixed(2);

        // console.log(notifyTenant);

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={
                    isModalLoading ? null : this.handleClose.bind(this)
                }
                style={modalStyle({
                    height: billingCategory ? 700 : 260,
                    width: billingCategory ? 670 : 400
                })}
            >
                <CloseButton
                    onClick={() => (isModalLoading ? null : this.handleClose())}
                />
                <ModalHeaderContainer>
                    <ModalHeader>New Unit Bill</ModalHeader>
                </ModalHeaderContainer>
                {billingCategory ? (
                    <Fragment>
                        <ModalBody height="420px" style={{ overflowX: "auto" }}>
                            <InfoHeader style={{ marginTop: "0" }}>
                                BILL INFORMATION
                            </InfoHeader>
                            <InfoContainer>
                                <div style={{ width: "360px" }}>
                                    <InfoLabel>CATEGORY</InfoLabel>
                                    <Select
                                        value={billingCategory}
                                        onChange={billingCategory =>
                                            this.setState({ billingCategory })
                                        }
                                        options={billingCategoryOptions}
                                    />
                                </div>
                                {billingCategory.value.type ===
                                    "total_only" && (
                                    <div
                                        style={{
                                            width: "200px"
                                        }}
                                    >
                                        <InfoLabel required>AMOUNT</InfoLabel>
                                        <InputCurrency
                                            required={
                                                (!amount || amount <= 0) &&
                                                hasError
                                            }
                                            disabled={isModalLoading}
                                            allowEmpty
                                            value={amount}
                                            onChangeEvent={this.handleAmountChange.bind(
                                                this
                                            )}
                                        />
                                    </div>
                                )}
                                {billingCategory.value.type ===
                                    "floor_sized_dependent" && (
                                    <Fragment>
                                        <div
                                            style={{
                                                width: "90px"
                                            }}
                                        >
                                            <InfoLabel>FLOOR AREA</InfoLabel>
                                            <div
                                                style={{
                                                    fontSize: "18px",
                                                    marginTop: "17px"
                                                }}
                                            >
                                                {unit.floorArea}sqm
                                            </div>
                                        </div>
                                        <div
                                            style={{
                                                width: "115px"
                                            }}
                                        >
                                            <InfoLabel required>RATE</InfoLabel>
                                            <Input
                                                type="number"
                                                min={0}
                                                required={!rate && hasError}
                                                disabled={isModalLoading}
                                                value={rate}
                                                onKeyDown={e =>
                                                    positiveNumber(e)
                                                }
                                                onChange={e =>
                                                    this.setState({
                                                        rate: e.target.value
                                                    })
                                                }
                                                style={{
                                                    textAlign: "right"
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: "41px",
                                                fontSize: "18px",
                                                marginTop: "32px",
                                                marginLeft: "4px"
                                            }}
                                        >
                                            /sqm
                                        </div>
                                    </Fragment>
                                )}
                                {billingCategory.value.type === "rate_type" && (
                                    <Fragment>
                                        <div
                                            style={{
                                                width: "100px"
                                            }}
                                        >
                                            <InfoLabel required>
                                                UTILIZATION
                                            </InfoLabel>
                                            <Input
                                                type="number"
                                                min={0}
                                                required={
                                                    !consumption && hasError
                                                }
                                                disabled={isModalLoading}
                                                value={consumption}
                                                onKeyDown={e =>
                                                    positiveNumber(e)
                                                }
                                                onChange={e =>
                                                    this.setState({
                                                        consumption:
                                                            e.target.value
                                                    })
                                                }
                                                style={{
                                                    textAlign: "right"
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: "41px",
                                                fontSize: "18px",
                                                marginTop: "32px",
                                                marginLeft: "4px"
                                            }}
                                        >
                                            units
                                        </div>
                                        <div
                                            style={{
                                                width: "100px"
                                            }}
                                        >
                                            <InfoLabel required>RATE</InfoLabel>
                                            <Input
                                                type="number"
                                                min={0}
                                                required={!rate && hasError}
                                                disabled={isModalLoading}
                                                value={rate}
                                                onKeyDown={e =>
                                                    positiveNumber(e)
                                                }
                                                onChange={e =>
                                                    this.setState({
                                                        rate: e.target.value
                                                    })
                                                }
                                                style={{
                                                    textAlign: "right"
                                                }}
                                            />
                                        </div>
                                        <div
                                            style={{
                                                width: "41px",
                                                fontSize: "18px",
                                                marginTop: "32px",
                                                marginLeft: "4px"
                                            }}
                                        >
                                            /unit
                                        </div>
                                    </Fragment>
                                )}
                            </InfoContainer>
                            <InfoContainer>
                                <div style={{ width: "128.75px" }}>
                                    <InfoLabel>COVERAGE START</InfoLabel>
                                    <InputDatePicker
                                        selected={coverageStart}
                                        required={
                                            !coverageStart &&
                                            coverageEnd &&
                                            hasError
                                        }
                                        disabled={isModalLoading}
                                        selectsStart
                                        startDate={coverageStart}
                                        endDate={coverageEnd}
                                        onChange={date =>
                                            this.setState({
                                                coverageStart: date,
                                                coverageEnd:
                                                    moment(date) >=
                                                    moment(coverageEnd)
                                                        ? null
                                                        : coverageEnd
                                            })
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        width: "128.75px"
                                    }}
                                >
                                    <InfoLabel>COVERAGE END</InfoLabel>
                                    <InputDatePicker
                                        selected={coverageEnd}
                                        selectsEnd
                                        required={
                                            !coverageEnd &&
                                            coverageStart &&
                                            hasError
                                        }
                                        disabled={
                                            isModalLoading || !coverageStart
                                        }
                                        minDate={coverageStart}
                                        startDate={coverageStart}
                                        endDate={coverageEnd}
                                        onChange={date =>
                                            this.setState({
                                                coverageEnd: date
                                            })
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        width: "128.75px"
                                    }}
                                >
                                    <InfoLabel required>BILL DATE</InfoLabel>
                                    <InputDatePicker
                                        required={!billDate && hasError}
                                        selected={billDate}
                                        disabled={isModalLoading}
                                        onChange={date =>
                                            this.setState({
                                                billDate: date,
                                                dueDate:
                                                    moment(date) >=
                                                    moment(dueDate)
                                                        ? null
                                                        : dueDate
                                            })
                                        }
                                    />
                                </div>
                                <div
                                    style={{
                                        width: "128.75px"
                                    }}
                                >
                                    <InfoLabel required>DUE DATE</InfoLabel>
                                    <FlippedInputDatePickerContainer>
                                        <InputDatePicker
                                            required={!dueDate && hasError}
                                            selected={dueDate}
                                            disabled={
                                                isModalLoading || !billDate
                                            }
                                            minDate={billDate}
                                            popperPlacement="bottom-end"
                                            onChange={date =>
                                                this.setState({
                                                    dueDate: date
                                                })
                                            }
                                        />
                                    </FlippedInputDatePickerContainer>
                                </div>
                            </InfoContainer>
                            {
                                unit.tenant && (
                                    <InfoContainer>
                                        <InfoLabel>
                                            NOTIFY TENANT{" "}
                                            <input
                                                type="checkbox"
                                                checked={notifyTenant}
                                                onChange={() =>
                                                    this.setState({
                                                        notifyTenant: !notifyTenant
                                                    })
                                                }
                                            />
                                        </InfoLabel>
                                    </InfoContainer>
                                )
                            }
                            {(billingCategory.value.type ===
                                "floor_sized_dependent" ||
                                billingCategory.value.type === "rate_type") && (
                                <InfoContainer>
                                    <div style={{ marginTop: "24px" }}>
                                        Sub Total: <PesoSign />{currencyFormat(subTotal)}
                                    </div>
                                </InfoContainer>
                            )}
                            <InfoHeader>DISCOUNTS</InfoHeader>
                            {discounts && discounts.length > 0 && (
                                <Fragment>
                                    <InfoContainer>
                                        <InfoLabel
                                            style={{
                                                width: "315px",
                                                marginBottom: 0
                                            }}
                                            required
                                        >
                                            DISCOUNT DESCRIPTION
                                        </InfoLabel>
                                        <InfoLabel
                                            style={{
                                                width: "200px",
                                                marginBottom: 0
                                            }}
                                            required
                                        >
                                            DISCOUNT AMOUNT
                                        </InfoLabel>
                                    </InfoContainer>
                                    {discountRows}
                                </Fragment>
                            )}
                            <AddDiscountButton
                                disabled={isModalLoading}
                                onClick={
                                    !isModalLoading
                                        ? this.handleAddDiscount.bind(this)
                                        : null
                                }
                            >
                                <MdAddCircleOutline
                                    style={{ marginRight: "2px" }}
                                />
                                ADD DISCOUNT
                            </AddDiscountButton>
                            <div style={{ marginTop: "18px" }}>
                                <InfoHeader>REMARKS</InfoHeader>
                                <TextArea
                                    rows={4}
                                    disabled={isModalLoading}
                                    onChange={e =>
                                        this.setState({
                                            remarks: e.target.value
                                        })
                                    }
                                />
                            </div>
                            <MessageContainer />
                        </ModalBody>
                        <TotalContainer>
                            Total: <PesoSign />{currencyFormat(total)}
                        </TotalContainer>
                    </Fragment>
                ) : (
                    <ModalBody height="90px">
                        <InfoLabel>
                            What kind of bill are you creating?
                        </InfoLabel>
                        <Select
                            value={billingCategory}
                            onChange={billingCategory =>
                                this.setState({ billingCategory })
                            }
                            options={billingCategoryOptions}
                        />
                    </ModalBody>
                )}
                <ModalFooter
                    style={{ height: billingCategory ? "110px" : "65px" }}
                >
                    {isModalLoading ? (
                        <Loader text="PROCESSING..." />
                    ) : (
                        <Fragment>
                            {billingCategory && (
                                <PrimaryButton
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        fontSize: "14px"
                                    }}
                                    onClick={() =>
                                        this.handleSubmit(subTotal, total)
                                    }
                                    text="ADD UNIT BILL"
                                />
                            )}
                            <PrimaryAltButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px",
                                    marginTop: "8px"
                                }}
                                onClick={() => this.handleClose()}
                                text="CANCEL"
                            />
                        </Fragment>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        building: state.building,
        unit: state.unit,
        billingCategories: state.building && state.building.billingCategories,
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};

export default connect(mapStateToProps)(UnitBillModal);

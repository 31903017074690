import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import styled from "styled-components";
import { FiSearch, FiEdit } from "react-icons/fi";
import colors from "utils/colors";
import AdminAmenityModal from "./AdminAmenityModal";
import { currencyFormat } from "utils/InputPatterns";
import { fetchAmenities } from "reducers/amenityReducer";
import {
    ContentContainer,
    FlexContainer,
    FlexWrapContainer,
    LinkContainer,
    PesoSign
} from "styles/Containers";
import { Input, IconInputContainer } from "styles/Inputs";
import {
    TableTextHeaderContainer,
    TableTextHeader,
    Pagination
} from "styles/Tables";
import { AddItemButton } from "styles/Buttons";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";

const FiltersContainer = styled.div`
    height: 60px;
    padding: 14px 20px;
    margin-bottom: 50px;
    border: solid 0.5px ${colors.LIGHT_GRAY};
    border-radius: 30px;
    box-shadow: 0 2px 8px -6px rgba(0, 0, 0, 0.2);
`;

const AmenityThumbnail = styled.img`
    height: 175px;
    width: 250px;
    border-radius: 6px;
    margin-right: 18px;
    cursor: pointer;
`;

const AmenityThumbnailDetailsContainer = styled.div`
    padding-left: 4px;
    margin-bottom: 18px;
`;

const MobileContainer = styled.div`
    ${props =>
        props.showOnMobile
            ? `
            @media screen and (min-width: 481px) {
                display: none;
            }
            margin-bottom: 0.5rem;
            input {
                width: 100%;
            }
        `
            : `
            @media screen and (max-width:480px) {
                display: none;
            }
        `}
`;

const SeachBar = ({ onChange, showOnMobile }) => (
    <MobileContainer showOnMobile={showOnMobile}>
        <IconInputContainer>
            <FiSearch style={{
                    fontSize: "18px",
                }}/>
            <Input
                style={{
                    height: "30px",
                    width: showOnMobile ? "100%" : "200px",
                    border: "solid 0.5px #a2a2a280",
                    borderRadius: "15px"
                }}
                placeholder="Search amenities"
                onChange={onChange}
            />
        </IconInputContainer>
    </MobileContainer>
);

const amenitiesPerPage = 9;

class AdminAmenitiesContainer extends Component {
    state = {
        filter: {
            property: "",
            name: ""
        },
        filteredAmenities: null,
        selectedAmenity: null,
        amenityModalVisible: false,
        offset: 1
    };

    constructor(props) {
        super(props);
        this.handleKeyDown = this.handleKeyDown.bind(this);
    }

    componentDidMount() {
        const { isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchAmenities()).then(amenities =>
                this.setState({
                    filteredAmenities: amenities || []
                })
            );
        }
        document.addEventListener("keydown", this.handleKeyDown);
    }

    componentWillUnmount() {
        document.removeEventListener("keydown", this.handleKeyDown);
        document.removeEventListener("click", this.hidePropertyFilterDropdown);
    }

    handleKeyDown(e) {
        if (e.keyCode === 37) {
            e.preventDefault();
            this.handlePreviousPage();
        } else if (e.keyCode === 39) {
            e.preventDefault();
            this.handleNextPage();
        }
    }

    handleNextPage() {
        const { filteredAmenities, offset } = this.state;
        this.setState({
            offset:
                offset < Math.ceil(filteredAmenities.length / amenitiesPerPage)
                    ? offset + 1
                    : offset
        });
    }

    handlePreviousPage() {
        const { offset } = this.state;
        this.setState({ offset: offset > 1 ? offset - 1 : offset });
    }

    showAmenityModalVisible(selectedAmenity) {
        this.setState({
            amenityModalVisible: true,
            selectedAmenity: selectedAmenity || null
        });
        document.removeEventListener("keydown", this.handleKeyDown);
    }

    hideAmenityModalVisible() {
        this.setState({ amenityModalVisible: false });
        document.addEventListener("keydown", this.handleKeyDown);
    }

    handleAmenitySubmitSuccess() {
        const { amenities } = this.props;
        this.setState({
            filteredAmenities: amenities
        });
    }

    handleFilterPropertyChange(property) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                property
            }),
            filteredAmenities: this.filterAmenities({
                property,
                name: filter.name
            }),
            offset: 1
        });
    }

    handleFilterNameChange(name) {
        const { filter } = this.state;
        this.setState({
            filter: Object.assign({}, filter, {
                name
            }),
            filteredAmenities: this.filterAmenities({
                name
            }),
            offset: 1
        });
    }

    filterAmenities({ name }) {
        const { amenities } = this.props;

        return (
            amenities &&
            amenities.filter(amenity =>
                name
                    ? amenity.name.toLowerCase().includes(name.toLowerCase())
                    : true
            )
        );
    }

    render() {
        const { isLoading } = this.props;
        const {
            filteredAmenities,
            selectedAmenity,
            amenityModalVisible,
            offset
        } = this.state;
        const range = offset * amenitiesPerPage;

        const amenityThumbnails =
            filteredAmenities &&
            filteredAmenities.map((amenity, i) => {
                if (i >= range - amenitiesPerPage && i < range) {
                    return (
                        <div key={amenity.id}>
                            <LinkContainer
                                to={`/admin/amenities/${amenity.id}`}
                            >
                                <AmenityThumbnail src={amenity.thumbnail} />
                            </LinkContainer>
                            <AmenityThumbnailDetailsContainer>
                                <FlexContainer style={{ alignItems: "center" }}>
                                    <div
                                        style={{
                                            fontSize: "14px",
                                            fontWeight: "bold"
                                        }}
                                    >
                                        {amenity.name}
                                    </div>
                                    <FiEdit
                                        style={{
                                            margin: "-1px 0 0 4px",
                                            cursor: "pointer"
                                        }}
                                        onClick={() =>
                                            this.showAmenityModalVisible(
                                                amenity
                                            )
                                        }
                                    />
                                </FlexContainer>
                                <FlexContainer
                                    style={{
                                        justifyContent: "space-between",
                                        marginRight: "18px"
                                    }}>
                                    {
                                        amenity.capacity &&
                                        <div
                                            style={{
                                                color: colors.GRAY,
                                                fontSize: "10px"
                                            }}>
                                                Capacity: {amenity.capacity}
                                        </div>
                                    }
                                    <div
                                        style={{
                                            color: colors.GRAY,
                                            fontSize: "10px"
                                        }}
                                    >
                                        <PesoSign />
                                        {currencyFormat(amenity.rate)}/hour
                                    </div>
                                </FlexContainer>
                            </AmenityThumbnailDetailsContainer>
                        </div>
                    );
                }
            });

        return (
            <Fragment>
                <PageHeader />
                {isLoading ? (
                    <Spinner />
                ) : (
                    <ContentContainer>
                        <SeachBar
                            onChange={e =>
                                this.handleFilterNameChange(e.target.value)
                            }
                            showOnMobile
                        />
                        <FiltersContainer>
                            <FlexContainer>
                                <SeachBar
                                    onChange={e =>
                                        this.handleFilterNameChange(
                                            e.target.value
                                        )
                                    }
                                    showOnMobile={false}
                                />
                            </FlexContainer>
                        </FiltersContainer>
                        <TableTextHeaderContainer
                            style={{ marginBottom: "12px" }}
                        >
                            <TableTextHeader>Amenities</TableTextHeader>
                            <AddItemButton
                                onClick={() => this.showAmenityModalVisible()}
                                text="NEW AMENITY"
                            />
                        </TableTextHeaderContainer>
                        <FlexWrapContainer>{amenityThumbnails}</FlexWrapContainer>
                        {filteredAmenities &&
                            filteredAmenities.length > amenitiesPerPage && (
                                <Pagination
                                    itemsPerPage={amenitiesPerPage}
                                    offset={offset}
                                    length={filteredAmenities.length}
                                    handlePreviousPage={() =>
                                        this.handlePreviousPage()
                                    }
                                    handleNextPage={() => this.handleNextPage()}
                                />
                            )}
                        {amenityModalVisible && (
                            <AdminAmenityModal
                                amenity={selectedAmenity}
                                modalVisible={amenityModalVisible}
                                closeModal={() =>
                                    this.hideAmenityModalVisible()
                                }
                                onSuccess={() =>
                                    this.handleAmenitySubmitSuccess()
                                }
                            />
                        )}
                    </ContentContainer>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        amenities: state.amenities,
        isLoading: state.isLoading,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(AdminAmenitiesContainer);

import axios from "axios";
import { toast } from "react-toastify";
import { userActions } from "./userReducer";
import { unitActions } from "./unitReducer";
import { messageActions } from "./messageReducer";

const FETCH_TENANTS = "FETCH_TENANTS";
const FETCH_TENANTS_SUCCESS = "FETCH_TENANTS_SUCCESS";
const FETCH_TENANTS_FAIL = "FETCH_TENANTS_FAIL";

const ADD_TENANT = "ADD_TENANT";
const ADD_TENANT_SUCCESS = "ADD_TENANT_SUCCESS";
const ADD_TENANT_FAIL = "ADD_TENANT_FAIL";

const UPDATE_TENANT = "UPDATE_TENANT";
const UPDATE_TENANT_SUCCESS = "UPDATE_TENANT_SUCCESS";
const UPDATE_TENANT_FAIL = "UPDATE_TENANT_FAIL";

const FETCH_TENANT = "FETCH_TENANT";
const FETCH_TENANT_SUCCESS = "FETCH_TENANT_SUCCESS";
const FETCH_TENANT_FAIL = "FETCH_TENANT_FAIL";

export const tenantActions = {
    ADD_TENANT,
    ADD_TENANT_SUCCESS,
    ADD_TENANT_FAIL,
    UPDATE_TENANT,
    UPDATE_TENANT_SUCCESS,
    UPDATE_TENANT_FAIL,
    FETCH_TENANT,
    FETCH_TENANT_SUCCESS,
    FETCH_TENANT_FAIL,
    FETCH_TENANTS,
    FETCH_TENANTS_SUCCESS,
    FETCH_TENANTS_FAIL,
};

export default (state, action) => {
    const { payload } = action;
    switch (action.type) {
        case ADD_TENANT_SUCCESS:
            return state.concat(payload);
        case UPDATE_TENANT_SUCCESS:
            return Object.assign({}, state, payload);
        default:
            return state;
    }
};

export const fetchTenants = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_TENANTS });
        return axios
            .request({
                url: `/api/${state.isAdmin ? "admin" : "account"}/tenants`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: FETCH_TENANTS_SUCCESS,
                        payload: res.tenants,
                    });
                    return res.tenants;
                } else {
                    dispatch({ type: FETCH_TENANTS_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password.",
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_TENANTS_FAIL });
            });
    };
};

export const updateUnitTenant = ({
    tenant,
    contractStart,
    contractEnd,
    newTenant,
    unitId,
}) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: ADD_TENANT });
        return axios
            .request({
                url: `/api/admin/update_unit_tenant`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`,
                },
                data: {
                    tenantId: tenant ? tenant.value : null,
                    tenantType: newTenant.isSelected && newTenant.type.label,
                    tenantName:
                        newTenant.isSelected &&
                        newTenant.type.value === "Company"
                            ? newTenant.name
                            : null,
                    tenantFirstName:
                        newTenant.isSelected &&
                        newTenant.type.value === "Individual"
                            ? newTenant.firstName
                            : null,
                    tenantLastName:
                        newTenant.isSelected &&
                        newTenant.type.value === "Individual"
                            ? newTenant.lastName
                            : null,
                    tenantEmail: newTenant.isSelected ? newTenant.email : null,
                    tenantPhoneNumber: newTenant.isSelected
                        ? newTenant.phoneNumber
                        : null,
                    birthdate:
                        newTenant.isSelected &&
                        newTenant.type.value === "Individual"
                            ? newTenant.birthdate
                            : null,
                    contactFirstName:
                        newTenant.isSelected &&
                        newTenant.type &&
                        newTenant.type.value === "Company"
                            ? newTenant.contactFirstName
                            : null,
                    contactLastName:
                        newTenant.isSelected &&
                        newTenant.type &&
                        newTenant.type.value === "Company"
                            ? newTenant.contactLastName
                            : null,
                    contactEmail:
                        newTenant.isSelected &&
                        newTenant.type &&
                        newTenant.type.value === "Company"
                            ? newTenant.contactEmail
                            : null,
                    contactPhoneNumber:
                        newTenant.isSelected &&
                        newTenant.type &&
                        newTenant.type.value === "Company"
                            ? newTenant.contactPhoneNumber
                            : null,
                    contractStart,
                    contractEnd,
                    unitId,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: ADD_TENANT_SUCCESS,
                        payload: {
                            id: res.id,
                            name: tenant
                                ? tenant.label
                                : newTenant.isSelected &&
                                  (newTenant.type.value === "Company"
                                      ? newTenant.name
                                      : newTenant.firstName +
                                        " " +
                                        newTenant.lastName),
                            contractStart,
                            contractEnd,
                        },
                    });
                    dispatch({
                        type: unitActions.UPDATE_UNIT_SUCCESS,
                        payload: {
                            ...state.unit,
                            tenant: {
                                id: res.id,
                                name: tenant
                                    ? tenant.label
                                    : newTenant.isSelected &&
                                      (newTenant.type.value === "Company"
                                          ? newTenant.name
                                          : newTenant.firstName +
                                            " " +
                                            newTenant.lastName),
                                contractStart,
                                contractEnd,
                            },
                        },
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE,
                    });
                    toast("Successfully added tenant.");
                } else {
                    dispatch({ type: ADD_TENANT_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password.",
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: ADD_TENANT_FAIL });
            });
    };
};

export const updateTenant = ({
    id,
    type,
    name,
    firstName,
    lastName,
    email,
    phoneNumber,
    age,
    sex,
    occupation,
    nationality,
    birthdate,
    contactFirstName,
    contactLastName,
    contactEmail,
    contactPhoneNumber,
}) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: UPDATE_TENANT });
        return axios
            .request({
                url: `/api/admin/update_tenant`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`,
                },
                data: {
                    id: id,
                    tenantType: type.label,
                    tenantName: type.value === "Company" ? name : null,
                    tenantFirstName:
                        type.value === "Individual" ? firstName : null,
                    tenantLastName:
                        type.value === "Individual" ? lastName : null,
                    tenantEmail: email,
                    tenantPhoneNumber: phoneNumber,
                    age: type.value === "Individual" ? age : null,
                    sex: type.value === "Individual" ? sex && sex.label : null,
                    occupation: type.value === "Individual" ? occupation : null,
                    nationality:
                        type.value === "Individual" ? nationality : null,
                    birthdate:
                        type.value === "Individual" ? birthdate : null,
                    contactFirstName:
                        type.value === "Company" ? contactFirstName : null,
                    contactLastName:
                        type.value === "Company" ? contactLastName : null,
                    contactEmail:
                        type.value === "Company" ? contactEmail : null,
                    contactPhoneNumber:
                        type.value === "Company" ? contactPhoneNumber : null,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: UPDATE_TENANT_SUCCESS,
                        payload: {
                            id,
                            type: type.label,
                            name:
                                type.value === "Company"
                                    ? name
                                    : firstName + " " + lastName,
                            firstName:
                                type.value === "Individual" ? firstName : "",
                            lastName:
                                type.value === "Individual" ? lastName : "",
                            email,
                            phoneNumber,
                            age: type.value === "Individual" ? age : null,
                            sex:
                                type.value === "Individual"
                                    ? sex && sex.label
                                    : null,
                            occupation:
                                type.value === "Individual" ? occupation : null,
                            nationality:
                                type.value === "Individual"
                                    ? nationality
                                    : null,
                            birthdate:
                                type.value === "Individual"
                                    ? birthdate
                                    : null,
                            contactFirstName:
                                type.value === "Company"
                                    ? contactFirstName
                                    : null,
                            contactLastName:
                                type.value === "Company"
                                    ? contactLastName
                                    : null,
                            contactEmail:
                                type.value === "Company" ? contactEmail : null,
                            contactPhoneNumber:
                                type.value === "Company"
                                    ? contactPhoneNumber
                                    : null,
                        },
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE,
                    });
                    toast("Successfully updated tenant.");
                } else {
                    dispatch({ type: UPDATE_TENANT_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password.",
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: UPDATE_TENANT_FAIL });
            });
    };
};

export const fetchTenant = (tenantId) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_TENANT });
        return axios
            .request({
                url: `/api/${state.isAdmin ? "admin" : "account"}/tenant`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`,
                },
                params: {
                    id: tenantId,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: FETCH_TENANT_SUCCESS,
                        payload: {
                            tenant: res.tenant,
                            units: res.units,
                            bills: res.dues,
                            reservations: res.reservations,
                            payments: res.payments,
                        },
                    });
                } else {
                    dispatch({ type: FETCH_TENANT_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password.",
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_TENANT_FAIL });
            });
    };
};

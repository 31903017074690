import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import moment from "moment";
import MessageContainer from "containers/MessageContainer";
import { Loader } from "containers/Loader";
import { messageActions } from "reducers/messageReducer";
import { currencyFormat, isValidEmail } from "utils/InputPatterns";
import colors from "utils/colors";
import { reserveAmenity } from "reducers/amenityReducer";
import {
    InfoHeader,
    InfoContainer,
    InfoLabel,
    FlexContainer,
    PesoSign,
} from "styles/Containers";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalSubHeader,
    ModalBody,
    ModalFooter,
} from "styles/Modals";
import { Input, TextArea, Select } from "styles/Inputs";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";
import styled from "styled-components";

const typeOptions = [
    {
        value: "Guest",
        label: "Guest",
    },
    {
        value: "Owner",
        label: "Owner",
    },
    {
        value: "Tenant",
        label: "Tenant",
    },
];

const TableContainer = styled.div`
    max-height: 200px;
    overflow-y: scroll;
`;

const Table = styled.div`
    display: table;
    border-collapse: separate;
    width: 100%;
    background-color: ${colors.LIGHTER_GRAY};
    border-bottom: solid 0.5px ${colors.LIGHT_GRAY};
`;

const TableHeaderCell = styled.div`
    display: table-cell;
    position: sticky;
    top: 0;
    padding: 8px;
    background-color: ${colors.LIGHTER_GRAY};
    border-style: solid;
    border-width: 0.5px 0;
    border-color: ${colors.LIGHT_GRAY};
`;

const TableRow = styled.div`
    display: table-row;
    border-style: solid;
    border-width: 0 0.5px;
    border-color: ${colors.LIGHT_GRAY};

    > div:first-child {
        border-left: solid 0.5px ${colors.LIGHT_GRAY};
    }

    > div:last-child {
        border-right: solid 0.5px ${colors.LIGHT_GRAY};
    }
`;

const TableCell = styled.div`
    display: table-cell;
    padding: 8px;
`;

const TotalAmountDueContainer = styled.div`
    heigth: 60px;
    background-color: ${colors.LIGHTER_GRAY};
    font-size: 18px;
    font-weight: 500;
    text-align: center;
    padding: 20px 0;
`;

class AdminBookingModal extends Component {
    state = {
        type: null,
        building: null,
        unit: null,
        firstName: "",
        lastName: "",
        contactNo: "",
        email: "",
        isPaid: true,
        referenceNumber: "",
        remarks: "",
        hasError: false,
    };

    componentWillUnmount() {
        const { dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE,
        });
    }

    async handleSubmit() {
        const { amenity, bookings, onSuccess, dispatch } = this.props;
        const {
            type,
            unit,
            firstName,
            lastName,
            contactNo,
            email,
            isPaid,
            referenceNumber,
            remarks,
        } = this.state;

        let errorMessage = "";
        const requiredFieldsFilled =
            type && type.value === "Guest"
                ? firstName.trim().length > 0 &&
                  lastName.trim().length > 0 &&
                  email.trim().length > 0 &&
                  contactNo.trim().length > 0
                : Boolean(unit);

        errorMessage = !requiredFieldsFilled
            ? "Please fill up all required fields."
            : errorMessage;
        errorMessage =
            email && !isValidEmail(email)
                ? errorMessage.length > 0
                    ? errorMessage + " Invalid email."
                    : "Invalid email."
                : errorMessage;

        if (errorMessage.length > 0) {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: errorMessage,
            });
            this.setState({ hasError: true });
        } else {
            await dispatch(
                reserveAmenity({
                    amenityId: amenity.id,
                    bookings,
                    amount: bookings.length * amenity.rate,
                    isOwner: type && type.value === "Owner",
                    unitId:
                        type && type.value !== "Guest" && unit && unit.value,
                    firstName,
                    lastName,
                    email,
                    contactNo,
                    isPaid,
                    referenceNumber,
                    remarks,
                })
            );
            if (!this.props.errorMessage) {
                dispatch({
                    type: messageActions.CLEAR_MESSAGE,
                });
                onSuccess();
            }
        }
    }

    render() {
        const {
            amenity,
            bookings,
            buildings,
            units,
            modalVisible,
            closeModal,
            isModalLoading,
        } = this.props;
        const {
            type,
            building,
            unit,
            firstName,
            lastName,
            contactNo,
            email,
            isPaid,
            referenceNumber,
            remarks,
            hasError,
        } = this.state;

        const filteredUnits =
            type && type.value === "Tenant"
                ? units && units.filter((u) => u.hasTenant)
                : units;

        const filteredBuildings =
            type && type.value === "Tenant"
                ? buildings &&
                  buildings.filter((b) =>
                      filteredUnits.find((u) => u.buildingId === b.id)
                  )
                : buildings;

        const buildingOptions =
            filteredBuildings &&
            filteredBuildings.map((b) => {
                return { value: b.id, label: b.name };
            });

        const unitOptions =
            building &&
            filteredUnits &&
            filteredUnits
                .filter((u) => u.buildingId === building.value)
                .map((u) => {
                    return { value: u.id, label: u.name };
                });

        const sortedBookings = bookings.sort(
            (a, b) =>
                moment(a, "MMMM D, YYYY h:mm A") -
                moment(b, "MMMM D, YYYY h:mm A")
        );

        const compiledBookings = sortedBookings.reduce((acc, curr) => {
            const currMoment = moment(curr, "MMMM D, YYYY h:mm A");
            const lastBooking = acc.length > 0 && acc[acc.length - 1];

            return lastBooking &&
                lastBooking.date + " " + lastBooking.endTime === curr
                ? acc.map((a, i) =>
                      i === acc.length - 1
                          ? Object.assign({}, a, {
                                endTime: currMoment
                                    .add(1, "h")
                                    .format("h:mm A"),
                                times: a.times + 1,
                            })
                          : a
                  )
                : acc.concat({
                      date: currMoment.format("MMMM D, YYYY"),
                      startTime: currMoment.format("h:mm A"),
                      endTime: currMoment.add(1, "h").format("h:mm A"),
                      times: 1,
                  });
        }, []);

        const bookingRows = compiledBookings.map((b, i) => (
            <TableRow key={"booking_" + i}>
                <TableCell>{b.date}</TableCell>
                <TableCell>
                    {b.startTime} - {b.endTime}
                </TableCell>
                <TableCell style={{ textAlign: "right" }}>
                    <PesoSign /> {currencyFormat(amenity.rate * b.times)}
                </TableCell>
            </TableRow>
        ));

        const totalAmountDue = bookings.length * amenity.rate;

        return (
            <Fragment>
                {type ? (
                    <Modal
                        isOpen={modalVisible}
                        onRequestClose={isModalLoading ? null : closeModal}
                        style={modalStyle({ height: 650, width: 500 })}
                    >
                        <CloseButton
                            onClick={isModalLoading ? null : closeModal}
                        />
                        <ModalHeaderContainer>
                            <ModalHeader>Book Reservation</ModalHeader>
                            <ModalSubHeader>{amenity.name}</ModalSubHeader>
                        </ModalHeaderContainer>
                        <ModalBody height="410px" style={{ overflowX: "auto" }}>
                            <InfoLabel>BOOKING TYPE</InfoLabel>
                            <Select
                                value={type}
                                disabled={isModalLoading}
                                onChange={(selectedType) =>
                                    this.setState({
                                        type: selectedType,
                                    })
                                }
                                options={typeOptions}
                            />
                            <InfoHeader>CONTACT INFORMATION</InfoHeader>
                            {type.value === "Guest" ? (
                                <Fragment>
                                    <InfoContainer>
                                        <div style={{ width: "222.5px" }}>
                                            <InfoLabel required>
                                                FIRST NAME
                                            </InfoLabel>
                                            <Input
                                                placeholder="e.g. Joe"
                                                value={firstName}
                                                required={
                                                    (!firstName ||
                                                        firstName.trim()
                                                            .length === 0) &&
                                                    hasError
                                                }
                                                disabled={isModalLoading}
                                                onChange={(e) =>
                                                    this.setState({
                                                        firstName:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div style={{ width: "222.5px" }}>
                                            <InfoLabel required>
                                                LAST NAME
                                            </InfoLabel>
                                            <Input
                                                placeholder="e.g. Star"
                                                value={lastName}
                                                required={
                                                    (!lastName ||
                                                        lastName.trim()
                                                            .length === 0) &&
                                                    hasError
                                                }
                                                disabled={isModalLoading}
                                                onChange={(e) =>
                                                    this.setState({
                                                        lastName:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </InfoContainer>
                                    <InfoContainer>
                                        <div style={{ width: "222.5px" }}>
                                            <InfoLabel required>
                                                CONTACT NUMBER
                                            </InfoLabel>
                                            <Input
                                                placeholder="e.g. +6398765432100"
                                                type="number"
                                                min={0}
                                                value={contactNo}
                                                required={
                                                    (!contactNo ||
                                                        contactNo.trim()
                                                            .length === 0) &&
                                                    hasError
                                                }
                                                disabled={isModalLoading}
                                                onChange={(e) =>
                                                    this.setState({
                                                        contactNo:
                                                            e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                        <div style={{ width: "222.5px" }}>
                                            <InfoLabel required>
                                                EMAIL
                                            </InfoLabel>
                                            <Input
                                                placeholder="e.g. example@mail.com"
                                                value={email}
                                                required={
                                                    (!email ||
                                                        email.trim().length ===
                                                            0 ||
                                                        !isValidEmail(email)) &&
                                                    hasError
                                                }
                                                disabled={isModalLoading}
                                                onChange={(e) =>
                                                    this.setState({
                                                        email: e.target.value,
                                                    })
                                                }
                                            />
                                        </div>
                                    </InfoContainer>
                                </Fragment>
                            ) : (
                                <InfoContainer>
                                    <div style={{ width: "222.5px" }}>
                                        <InfoLabel>BUILDING</InfoLabel>
                                        <Select
                                            value={building}
                                            disabled={isModalLoading}
                                            onChange={(selectedBuilding) =>
                                                this.setState({
                                                    building: selectedBuilding,
                                                    unit: null,
                                                })
                                            }
                                            options={buildingOptions}
                                        />
                                    </div>
                                    <div style={{ width: "222.5px" }}>
                                        <InfoLabel>UNIT</InfoLabel>
                                        <Select
                                            value={unit}
                                            disabled={
                                                isModalLoading || !building
                                            }
                                            required={!unit && hasError}
                                            onChange={(selectedUnit) =>
                                                this.setState({
                                                    unit: selectedUnit,
                                                })
                                            }
                                            options={unitOptions}
                                            isSearchable
                                        />
                                    </div>
                                </InfoContainer>
                            )}
                            <InfoHeader>BOOKING INFORMATION</InfoHeader>
                                <div>
                                    Rate:
                                    <PesoSign />{currencyFormat(amenity.rate)}/hour
                                </div>
                                {
                                    amenity.capacity &&
                                    <div>
                                        Capacity: {amenity.capacity}
                                    </div>
                                }
                            <InfoLabel>
                                <FlexContainer>
                                    PAID?
                                    <InfoLabel style={{ marginLeft: `0.5rem` }}>
                                        Yes
                                    </InfoLabel>
                                    <input
                                        style={{ marginLeft: `0.5rem` }}
                                        type="radio"
                                        name="isPaid"
                                        defaultChecked
                                        onChange={() =>
                                            this.setState({
                                                isPaid: true,
                                            })
                                        }
                                    />
                                    <InfoLabel style={{ marginLeft: `0.5rem` }}>
                                        No
                                    </InfoLabel>
                                    <input
                                        style={{ marginLeft: `0.5rem` }}
                                        type="radio"
                                        name="isPaid"
                                        onChange={() =>
                                            this.setState({
                                                isPaid: false,
                                            })
                                        }
                                    />
                                </FlexContainer>
                            </InfoLabel>
                            {isPaid && (
                                <div>
                                    <InfoLabel>REFERENCE NUMBER</InfoLabel>
                                    <Input
                                        disabled={isModalLoading}
                                        onChange={(e) =>
                                            this.setState({
                                                referenceNumber: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            )}
                            <div>
                                <div>Overview:</div>
                                <TableContainer>
                                    <Table>
                                        <TableRow>
                                            <TableHeaderCell>
                                                Date
                                            </TableHeaderCell>
                                            <TableHeaderCell>
                                                Time
                                            </TableHeaderCell>
                                            <TableHeaderCell
                                                style={{ textAlign: "right" }}
                                            >
                                                Amount
                                            </TableHeaderCell>
                                        </TableRow>
                                        {bookingRows}
                                    </Table>
                                </TableContainer>
                            </div>
                            <div>
                                <div>Total Due:</div>
                                <TotalAmountDueContainer>
                                    <PesoSign />{currencyFormat(totalAmountDue)}
                                </TotalAmountDueContainer>
                            </div>
                            <div style={{ marginTop: "18px" }}>
                                <InfoLabel>REMARKS</InfoLabel>
                                <TextArea
                                    rows={4}
                                    disabled={isModalLoading}
                                    onChange={(e) =>
                                        this.setState({
                                            remarks: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <MessageContainer />
                        </ModalBody>
                        <ModalFooter>
                            {isModalLoading ? (
                                <Loader text="BOOKING..." />
                            ) : (
                                <PrimaryButton
                                    style={{
                                        height: "38px",
                                        width: "100%",
                                        fontSize: "14px",
                                    }}
                                    onClick={() => this.handleSubmit()}
                                    text="CONFIRM"
                                />
                            )}
                        </ModalFooter>
                    </Modal>
                ) : (
                    <Modal
                        isOpen={modalVisible}
                        onRequestClose={isModalLoading ? null : closeModal}
                        style={modalStyle({ height: 200, width: 400 })}
                    >
                        <ModalBody height="90px">
                            <InfoLabel>Booking For</InfoLabel>
                            <Select
                                value={type}
                                disabled={isModalLoading}
                                onChange={(selectedType) =>
                                    this.setState({ type: selectedType })
                                }
                                options={typeOptions}
                            />
                        </ModalBody>
                        <ModalFooter style={{ height: "65px" }}>
                            <PrimaryAltButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px",
                                    marginTop: "8px",
                                }}
                                onClick={closeModal}
                                text="CANCEL"
                            />
                        </ModalFooter>
                    </Modal>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        amenity: state.amenity,
        buildings: state.buildings,
        units: state.units,
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage,
    };
};
export default connect(mapStateToProps)(AdminBookingModal);

import axios from "axios";
import { toast } from "react-toastify";
import { userActions } from "./userReducer";
import { messageActions } from "./messageReducer";

const UPLOAD_UNITS = "UPLOAD_UNITS";
const UPLOAD_UNITS_SUCCESS = "UPLOAD_UNITS_SUCCESS";
const UPLOAD_UNITS_FAIL = "UPLOAD_UNITS_FAIL";
const UPLOADING_UNITS = "UPLOADING_UNITS";
const RESET_UPLOAD_RESULTS = "RESET_UPLOAD_RESULTS";

const CancelToken = axios.CancelToken;
const source = CancelToken.source();

export const uploadActions = {
    UPLOAD_UNITS,
    UPLOAD_UNITS_SUCCESS,
    UPLOAD_UNITS_FAIL,
    UPLOADING_UNITS,
    RESET_UPLOAD_RESULTS
};

export const resetUploadResults = () => {
    return dispatch => {
        dispatch({ type: RESET_UPLOAD_RESULTS });
    };
};

export const uploadUnits = ({ building, file }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: UPLOAD_UNITS });
        let formData = new FormData();
        formData.append("buildingId", building.value);
        formData.append("file", file);

        axios
            .request({
                url: `/api/admin/bulk_upload`,
                method: "POST",
                headers: {
                    "Content-Type": "multipart/form-data",
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                timeout: 60000,
                onUploadProgress: progressEvent => {
                    if (progressEvent.lengthComputable) {
                        const percentLoaded =
                            progressEvent.loaded / progressEvent.total;
                        dispatch({
                            type: UPLOADING_UNITS,
                            payload: percentLoaded * 100
                        });
                    }
                },
                data: formData,
                cancelToken: source.token
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: UPLOAD_UNITS_SUCCESS,
                        payload: {
                            numberOfUnits: res.units.length,
                            numberOfOwners: res.owners.length,
                            failed: res.failedRows
                        }
                    });
                } else {
                    dispatch({ type: UPLOAD_UNITS_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: UPLOAD_UNITS_FAIL });
            });
    };
};

import React, { Component } from "react";
import { Route, Switch, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import styled from "styled-components";
import AdminNavigation from "./AdminNavigation";
import AdminUnitsContainer from "./AdminUnitsContainer";
import AdminUnitContainer from "./AdminUnitContainer";
import AdminBillsContainer from "./AdminBillsContainer";
import BillContainer from "../BillContainer";
import OwnersContainer from "../OwnersContainer";
import OwnerContainer from "../OwnerContainer";
import TenantsContainer from "../TenantsContainer";
import TenantContainer from "../TenantContainer";
import BuildingContainer from "./BuildingContainer";
import PaymentContainer from "../PaymentContainer";
import AdminAmenitiesContainer from "./AdminAmenitiesContainer";
import AdminAmenityContainer from "./AdminAmenityContainer";
import AdminReservationContainer from "./AdminReservationContainer";
import AdminReservationsContainer from "./AdminReservationsContainer";
import AdminRequisitionContainer from "./AdminRequisitionContainer";
import AdminRequisitionsContainer from "./AdminRequisitionsContainer";
import NotificationsContainer from "../NotificationsContainer";
import AdminDashboardContainer from "./AdminDashboardContainer";
import HelpModalContainer from "containers/HelpModalContainer";

/* overflow */
const MainContainer = styled.div`
    background-color: white;
    margin-left: 205px;
    height: 100vh;
    overflow: auto;
`;

class AdminLoggedIn extends Component {
    state = {
        showHelpModal: false,
    };

    componentDidMount() {}

    toggleHelpModal(value) {
        this.setState({ showHelpModal: value });
    }

    render() {
        const { showHelpModal } = this.state;
        const { toggleHelpModal } = this.props;

        return (
            <MainContainer>
                <AdminNavigation
                    showModal={() => {
                        this.toggleHelpModal(true);
                    }}
                />
                {showHelpModal && (
                    <HelpModalContainer
                        hideModal={() => {
                            this.toggleHelpModal(false);
                        }}
                    />
                )}
                <Switch>
                    <Route
                        exact
                        path="/admin/units/:unitId"
                        render={({ match, location }) => (
                            <AdminUnitContainer
                                unitId={match.params.unitId}
                                backTo={location.state && location.state.backTo}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/admin/bills/:billId"
                        render={({ match, location }) => (
                            <BillContainer
                                billId={match.params.billId}
                                backTo={location.state && location.state.backTo}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/admin/units"
                        component={AdminUnitsContainer}
                    />
                    <Route
                        exact
                        path="/admin/bills"
                        component={AdminBillsContainer}
                    />
                    <Route
                        exact
                        path="/admin/owners/:ownerId"
                        render={({ match, location }) => (
                            <OwnerContainer
                                ownerId={match.params.ownerId}
                                backTo={location.state && location.state.backTo}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/admin/owners"
                        component={OwnersContainer}
                    />
                    <Route
                        exact
                        path="/admin/tenants/:tenantId"
                        render={({ match, location }) => (
                            <TenantContainer
                                tenantId={match.params.tenantId}
                                backTo={location.state && location.state.backTo}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/admin/tenants"
                        component={TenantsContainer}
                    />
                    <Route
                        exact
                        path="/admin/amenities/:amenityId"
                        render={({ match }) => (
                            <AdminAmenityContainer
                                amenityId={match.params.amenityId}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/admin/amenities"
                        component={AdminAmenitiesContainer}
                    />
                    <Route
                        exact
                        path="/admin/payments/:paymentId"
                        render={({ match, location }) => (
                            <PaymentContainer
                                paymentId={match.params.paymentId}
                                backTo={location.state && location.state.backTo}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/admin/reservations/:reservationId"
                        render={({ match, location }) => (
                            <AdminReservationContainer
                                reservationId={match.params.reservationId}
                                backTo={location.state && location.state.backTo}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/admin/reservations"
                        render={({ location }) => (
                            <AdminReservationsContainer
                                filters={
                                    location.state && location.state.filters
                                }
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/admin/requisitions/:requisitionId"
                        render={({ match }) => (
                            <AdminRequisitionContainer
                                requisitionId={match.params.requisitionId}
                            />
                        )}
                    />
                    <Route
                        exact
                        path="/admin/requisitions"
                        component={AdminRequisitionsContainer}
                    />
                    <Route
                        exact
                        path="/admin/notifications"
                        component={NotificationsContainer}
                    />
                    <Route
                        exact
                        path="/admin/settings"
                        component={BuildingContainer}
                    />
                    <Route path="/admin" component={AdminDashboardContainer} />
                </Switch>
            </MainContainer>
        );
    }
}

export default withRouter(connect()(AdminLoggedIn));

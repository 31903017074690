import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import ConfirmationModal from "../ConfirmationModal";
import UnitTypeModal from "./UnitTypeModal";
import { selectBuilding, removeUnitType } from "reducers/buildingReducer";
import { Select, FiltersContainer, FilterLabel } from "styles/Inputs";
import {
    TabHeaderContainer,
    TabNewItemContainer,
    ActiveTabHeader,
    InactiveTabHeader,
    QuickActiveTabHeader,
    QuickInactiveTabHeader
} from "styles/Tabs";
import {
    Table,
    TableHeader,
    TableRow,
    TableCell,
    TableRowEditAction,
    TableRowRemoveAction,
    RevisedEmptyListContainer
} from "styles/Tables";
import { AddItemButton, NewItemLink } from "styles/Buttons";
import { FlexContainer,
     FlexColumnContainer,
     WhiteCardBox,
     HRContainer } from "styles/Containers";
import colors from "utils/colors";

class BuildingUnitTypesContainer extends Component {
    state = {
        unitType: null,
        unitTypeModalVisible: false,
        confirmationModalVisible: false
    };

    showUnitTypeModal(unitType) {
        if (unitType) {
            this.setState({
                unitType,
                unitTypeModalVisible: true
            });
        } else {
            this.setState({ unitType: null, unitTypeModalVisible: true });
        }
    }

    hideUnitTypeModal() {
        this.setState({ unitTypeModalVisible: false });
    }

    showConfirmationModal(unitType) {
        this.setState({
            unitType,
            confirmationModalVisible: true
        });
    }

    hideConfirmationModal() {
        this.setState({ confirmationModalVisible: false });
    }

    handleRemoveUnitType() {
        const { building, dispatch } = this.props;
        const { unitType } = this.state;
        dispatch(
            removeUnitType({
                buildingId: building.id,
                name: unitType
            })
        );
    }

    handleBuildingChange(selectedBuilding) {
        const { dispatch } = this.props;
        dispatch(selectBuilding(selectedBuilding.value));
    }

    render() {
        const {
            unitTypes,
            building,
            buildingOptions,
            tabVisible,
            onTabChange
        } = this.props;
        const {
            unitType,
            unitTypeModalVisible,
            confirmationModalVisible
        } = this.state;
        const selectedBuilding = building && {
            value: building.id,
            label: building.name
        };

        const unitTypeRows =
            unitTypes &&
            unitTypes.map((unitType, i) => (
                <TableRow gray={i % 2 === 0} key={"unitType_" + i}>
                    <TableCell>{unitType}</TableCell>
                    <TableRowEditAction
                        onClick={() => this.showUnitTypeModal(unitType)}
                        text="EDIT"
                    />
                    {
                        // <TableRowRemoveAction
                        //     onClick={() => this.showConfirmationModal(unitType)}
                        //     text="REMOVE"
                        // />
                    }
                </TableRow>
            ));

        return (
            <Fragment>
                {tabVisible && (
                    <FlexContainer>

                        <WhiteCardBox
                            style={{
                                width: "20%",
                                minWidth: "230px",
                                marginRight: "3%",
                            }}>
                            <p>Quick Links</p>
                            <HRContainer />
                            <FlexColumnContainer>
                                <QuickActiveTabHeader style={{
                                        marginBottom: "10px",
                                    }}>
                                        Unit Types
                                </QuickActiveTabHeader>
                                <QuickInactiveTabHeader
                                    onClick={() => onTabChange("billingCategories")}
                                    style={{
                                        marginBottom: "10px",
                                    }}>
                                        Billing Categories
                                </QuickInactiveTabHeader>
                                <QuickInactiveTabHeader
                                    onClick={() => onTabChange("uploads")}>
                                        Uploads
                                </QuickInactiveTabHeader>
                            </FlexColumnContainer>
                        </WhiteCardBox>

                        <WhiteCardBox style={{
                                width: "77%",
                                maxWidth: "700px",
                            }}>
                            <FlexContainer style={{
                                justifyContent: "space-between"
                            }}>
                                <h3>Unit Types</h3>
                                <AddItemButton
                                    onClick={() => this.showUnitTypeModal()}
                                    text="Add new type"
                                    style={{
                                        float: "right",
                                        width: "155px",
                                    }}
                                    textStyle={{
                                        fontSize: "12px",
                                        width: "70%",
                                    }}
                                />
                            </FlexContainer>
                            <FiltersContainer>
                                <div style={{ width: "200px" }}>
                                    <FilterLabel>Building</FilterLabel>
                                    <Select
                                        value={selectedBuilding}
                                        onChange={this.handleBuildingChange.bind(
                                            this
                                        )}
                                        options={buildingOptions}
                                        isSearchable
                                    />
                                </div>
                            </FiltersContainer>
                            {unitTypes && unitTypes.length > 0 ? (
                                <Fragment>
                                    <Table>
                                        <TableHeader>
                                            <TableCell>Unit Types</TableCell>
                                            <TableCell></TableCell>
                                            {/* <TableCell></TableCell> */}
                                        </TableHeader>
                                        {unitTypeRows}
                                    </Table>
                                </Fragment>
                            ) : (
                                <RevisedEmptyListContainer>
                                    There are no unit types yet.{" "}
                                    <NewItemLink
                                        onClick={() => this.showUnitTypeModal()}
                                        style={{
                                            color: colors.BLUE_GREEN
                                        }}
                                    >
                                        Click here to add one.
                                    </NewItemLink>
                                </RevisedEmptyListContainer>
                            )}
                            {unitTypeModalVisible && (
                                <UnitTypeModal
                                    unitType={unitType}
                                    unitTypeNames={
                                        unitTypes && unitType
                                            ? unitTypes.map(
                                                  ut => ut !== unitType && ut
                                              )
                                            : unitTypes || []
                                    }
                                    modalVisible={unitTypeModalVisible}
                                    closeModal={this.hideUnitTypeModal.bind(this)}
                                />
                            )}
                            {confirmationModalVisible && (
                                <ConfirmationModal
                                    body="Are you sure you want to remove this unit type?"
                                    modalVisible={confirmationModalVisible}
                                    onSubmit={this.handleRemoveUnitType.bind(this)}
                                    submitText="YES"
                                    closeModal={this.hideConfirmationModal.bind(
                                        this
                                    )}
                                    cancelText="NO"
                                />
                            )}
                        </WhiteCardBox>
                    </FlexContainer>
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        buildingOptions:
            state.buildings &&
            state.buildings.map(building => {
                return { value: building.id, label: building.name };
            }),
        building: state.building,
        unitTypes: state.building && state.building.unitTypes
    };
};

export default connect(mapStateToProps)(BuildingUnitTypesContainer);

import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { userActions } from "./userReducer";
import { messageActions } from "./messageReducer";

const FETCH_PAYMENT = "FETCH_PAYMENT";
const FETCH_PAYMENT_SUCCESS = "FETCH_PAYMENT_SUCCESS";
const FETCH_PAYMENT_FAIL = "FETCH_PAYMENT_FAIL";

const FETCH_PAYMENTS = "FETCH_PAYMENTS";
const FETCH_PAYMENTS_SUCCESS = "FETCH_PAYMENTS_SUCCESS";
const FETCH_PAYMENTS_FAIL = "FETCH_PAYMENTS_FAIL";

const ADD_PAYMENT = "ADD_PAYMENT";
const ADD_PAYMENT_SUCCESS = "ADD_PAYMENT_SUCCESS";
const ADD_PAYMENT_FAIL = "ADD_PAYMENT_FAIL";

const ADD_PAYMENT_RESERVATION = "ADD_PAYMENT_RESERVATION";
const ADD_PAYMENT_RESERVATION_SUCCESS = "ADD_PAYMENT_RESERVATION_SUCCESS";
const ADD_PAYMENT_RESERVATION_FAIL = "ADD_PAYMENT_RESERVATION_FAIL";

const PAYPAL_REDIRECT = "PAYPAL_REDIRECT";
const PAYPAL_REDIRECT_SUCCESS = "PAYPAL_REDIRECT_SUCCESS";
const PAYPAL_REDIRECT_FAIL = "PAYPAL_REDIRECT_FAIL";

const DRAGONPAY_REDIRECT = "DRAGONPAY_REDIRECT";
const DRAGONPAY_REDIRECT_SUCCESS = "DRAGONPAY_REDIRECT_SUCCESS";
const DRAGONPAY_REDIRECT_FAIL = "DRAGONPAY_REDIRECT_FAIL";

const CREDIT_CARD_PAYMENT = "CREDIT_CARD_PAYMENT";
const CREDIT_CARD_PAYMENT_SUCCESS = "CREDIT_CARD_PAYMENT_SUCCESS";
const CREDIT_CARD_PAYMENT_FAIL = "CREDIT_CARD_PAYMENT_FAIL";

export const paymentActions = {
    FETCH_PAYMENT,
    FETCH_PAYMENT_SUCCESS,
    FETCH_PAYMENT_FAIL,
    FETCH_PAYMENTS,
    FETCH_PAYMENTS_SUCCESS,
    FETCH_PAYMENTS_FAIL,
    ADD_PAYMENT,
    ADD_PAYMENT_SUCCESS,
    ADD_PAYMENT_FAIL,
    ADD_PAYMENT_RESERVATION,
    ADD_PAYMENT_RESERVATION_SUCCESS,
    ADD_PAYMENT_RESERVATION_FAIL,
    PAYPAL_REDIRECT,
    PAYPAL_REDIRECT_SUCCESS,
    PAYPAL_REDIRECT_FAIL,
    DRAGONPAY_REDIRECT,
    DRAGONPAY_REDIRECT_SUCCESS,
    DRAGONPAY_REDIRECT_FAIL,
    CREDIT_CARD_PAYMENT,
    CREDIT_CARD_PAYMENT_SUCCESS,
    CREDIT_CARD_PAYMENT_FAIL,
};

export default (state, action) => {
    const { payload } = action;
    switch (action.type) {
        case ADD_PAYMENT_SUCCESS:
            const remainingBalance = state
                ? state.remainingBalance - payload.amount
                : 0;
            const status =
                remainingBalance === 0 ? "Paid Full" : "Paid Partial";
            return Object.assign({}, state, {
                remainingBalance,
                status,
                payments: state.payments
                    ? state.payments.concat(payload)
                    : [payload],
            });
        case ADD_PAYMENT_RESERVATION_SUCCESS:
            const reservationRemainingBalance = state
                ? state.remainingBalance - payload.amount
                : 0;
            const reservationStatus =
                reservationRemainingBalance === 0
                    ? "Reserved (Paid)"
                    : "Approved (Pending Payment)";
            return Object.assign({}, state, {
                remainingBalance: reservationRemainingBalance,
                status: reservationStatus,
                payments: state.payments
                    ? state.payments.concat(payload)
                    : [payload],
            });
        default:
            return state;
    }
};

export const fetchPayment = (paymentId) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_PAYMENT });
        return axios
            .request({
                url: `/api/${state.isAdmin ? "admin" : "account"}/payment`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`,
                },
                params: {
                    id: paymentId,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: FETCH_PAYMENT_SUCCESS,
                        payload: res.payment,
                    });
                    return res.payments;
                } else {
                    dispatch({ type: FETCH_PAYMENT_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password.",
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_PAYMENT_FAIL });
            });
    };
};

export const fetchPayments = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_PAYMENTS });
        return axios
            .request({
                url: `/api/${state.isAdmin ? "admin" : "account"}/payments`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: FETCH_PAYMENTS_SUCCESS,
                        payload: res.payments,
                    });
                    return res.payments;
                } else {
                    dispatch({ type: FETCH_PAYMENTS_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password.",
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_PAYMENTS_FAIL });
            });
    };
};

export const addPayment = ({ billId, payment }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: ADD_PAYMENT });
        return axios
            .request({
                url: `/api/admin/payment_admin`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`,
                },
                data: {
                    billId,
                    date: payment.date,
                    referenceNo: payment.referenceNumber,
                    amount: payment.amount,
                    remarks: payment.remarks,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: ADD_PAYMENT_SUCCESS,
                        payload: {
                            id: res.paymentId,
                            date: payment.date,
                            referenceNumber: res.referenceNumber,
                            amount: payment.amount,
                            remarks: payment.remarks,
                            status: "Completed",
                        },
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE,
                    });
                    toast("Successfully added new payment.");
                } else {
                    dispatch({ type: ADD_PAYMENT_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password.",
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: ADD_PAYMENT_FAIL });
            });
    };
};

export const addPaymentReservation = ({ billId, payment }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: ADD_PAYMENT_RESERVATION });
        return axios
            .request({
                url: `/api/admin/payment_admin`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`,
                },
                data: {
                    billId,
                    date: payment.date,
                    referenceNo: payment.referenceNumber,
                    amount: payment.amount,
                    remarks: payment.remarks,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: ADD_PAYMENT_RESERVATION_SUCCESS,
                        payload: {
                            date: payment.date,
                            referenceNumber: res.referenceNumber,
                            amount: payment.amount,
                            remarks: payment.remarks,
                            status: "Completed",
                        },
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE,
                    });
                    toast("Successfully added new payment.");
                } else {
                    dispatch({ type: ADD_PAYMENT_RESERVATION_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password.",
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: ADD_PAYMENT_RESERVATION_FAIL });
            });
    };
};

export const paypalRedirect = (billId) => {
    return (dispatch) => {
        dispatch({ type: PAYPAL_REDIRECT });
        return axios
            .request({
                url: `/api/create_paypal_order`,
                method: "POST",
                data: {
                    id: billId,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: PAYPAL_REDIRECT_SUCCESS });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE,
                    });
                    window.open(res.paypal_url);
                } else {
                    dispatch({ type: PAYPAL_REDIRECT_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                toast("Something went wrong please try again later.");
                dispatch({ type: PAYPAL_REDIRECT_FAIL });
            });
    };
};

export const dragonPayRedirect = (billId) => {
    return (dispatch) => {
        dispatch({ type: DRAGONPAY_REDIRECT });
        return axios
            .request({
                url: `/api/dragonpay_prompt`,
                method: "POST",
                data: {
                    id: billId,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: DRAGONPAY_REDIRECT_SUCCESS });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE,
                    });
                    window.open(res.dragonpay_url);
                } else {
                    dispatch({ type: DRAGONPAY_REDIRECT_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message,
                    });
                }
            })
            .catch((err) => {
                console.error(err);
                toast("Something went wrong please try again later.");
                dispatch({ type: DRAGONPAY_REDIRECT_FAIL });
            });
    };
};

export const creditCardPayment = ({ bill, card }) => {
    return (dispatch) => {
        dispatch({ type: CREDIT_CARD_PAYMENT });
        return axios
            .request({
                url: `/api/payment_card`,
                method: "POST",
                data: {
                    billId: bill.id,
                    name: card.name,
                    number: card.number,
                    expMonth: card.expMonth.value,
                    expYear: card.expYear,
                    cvc: card.cvc,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: CREDIT_CARD_PAYMENT_SUCCESS,
                        payload: {
                            date: new Date(),
                            referenceNumber: res.referenceNumber,
                            amount: bill.remainingBalance,
                            remarks: "",
                            status: "Completed",
                        },
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE,
                    });
                    toast("Successfully added new payment.");
                } else {
                    dispatch({ type: CREDIT_CARD_PAYMENT_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password.",
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: CREDIT_CARD_PAYMENT_FAIL });
            });
    };
};

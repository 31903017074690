import axios from "axios";
import { toast } from "react-toastify";
import { userActions } from "./userReducer";
import { ownerActions } from "./ownerReducer";
import { messageActions } from "./messageReducer";
import { download } from "utils/image";

const FETCH_UNITS = "FETCH_UNITS";
const FETCH_UNITS_SUCCESS = "FETCH_UNITS_SUCCESS";
const FETCH_UNITS_FAIL = "FETCH_UNITS_FAIL";

const FETCH_UNCLAIMED_UNITS = "FETCH_UNCLAIMED_UNITS";
const FETCH_UNCLAIMED_UNITS_SUCCESS = "FETCH_UNCLAIMED_UNITS_SUCCESS";
const FETCH_UNCLAIMED_UNITS_FAIL = "FETCH_UNCLAIMED_UNITS_FAIL";

const RESET_UNCLAIMED_UNITS = "RESET_UNCLAIMED_UNITS";

const FETCH_REQUISITIONS = "FETCH_REQUISITIONS";
const FETCH_REQUISITIONS_SUCCESS = "FETCH_REQUISITIONS_SUCCESS";
const FETCH_REQUISITIONS_FAIL = "FETCH_REQUISITIONS_FAIL";

const FETCH_REQUISITION = "FETCH_REQUISITION";
const FETCH_REQUISITION_SUCCESS = "FETCH_REQUISITION_SUCCESS";
const FETCH_REQUISITION_FAIL = "FETCH_REQUISITION_FAIL";

const ADD_UNIT = "ADD_UNIT";
const ADD_UNIT_SUCCESS = "ADD_UNIT_SUCCESS";
const ADD_UNIT_FAIL = "ADD_UNIT_FAIL";

const UPDATE_UNIT = "UPDATE_UNIT";
const UPDATE_UNIT_SUCCESS = "UPDATE_UNIT_SUCCESS";
const UPDATE_UNIT_FAIL = "UPDATE_UNIT_FAIL";

const FETCH_UNIT = "FETCH_UNIT";
const FETCH_UNIT_SUCCESS = "FETCH_UNIT_SUCCESS";
const FETCH_UNIT_FAIL = "FETCH_UNIT_FAIL";

const CLAIM_UNIT = "CLAIM_UNIT";
const CLAIM_UNIT_SUCCESS = "CLAIM_UNIT_SUCCESS";
const CLAIM_UNIT_FAIL = "CLAIM_UNIT_FAIL";

const APPROVE_REQUISITION = "APPROVE_REQUISITION";
const APPROVE_REQUISITION_SUCCESS = "APPROVE_REQUISITION_SUCCESS";
const APPROVE_REQUISITION_FAIL = "APPROVE_REQUISITION_FAIL";

const DENY_REQUISITION = "DENY_REQUISITION";
const DENY_REQUISITION_SUCCESS = "DENY_REQUISITION_SUCCESS";
const DENY_REQUISITION_FAIL = "DENY_REQUISITION_FAIL";

const GENERATE_STATEMENT_OF_ACCOUNT = "GENERATE_STATEMENT_OF_ACCOUNT";
const GENERATE_STATEMENT_OF_ACCOUNT_SUCCESS =
    "GENERATE_STATEMENT_OF_ACCOUNT_SUCCESS";
const GENERATE_STATEMENT_OF_ACCOUNT_FAIL = "GENERATE_STATEMENT_OF_ACCOUNT_FAIL";

const REMOVE_TENANT = "REMOVE_TENANT";
const REMOVE_TENANT_SUCCESS = "REMOVE_TENANT_SUCCESS";
const REMOVE_TENANT_FAIL = "REMOVE_TENANT_FAIL";


export const unitActions = {
    FETCH_UNITS,
    FETCH_UNITS_SUCCESS,
    FETCH_UNITS_FAIL,
    FETCH_UNCLAIMED_UNITS,
    FETCH_UNCLAIMED_UNITS_SUCCESS,
    FETCH_UNCLAIMED_UNITS_FAIL,
    RESET_UNCLAIMED_UNITS,
    FETCH_REQUISITIONS,
    FETCH_REQUISITIONS_SUCCESS,
    FETCH_REQUISITIONS_FAIL,
    FETCH_REQUISITION,
    FETCH_REQUISITION_SUCCESS,
    FETCH_REQUISITION_FAIL,
    ADD_UNIT,
    ADD_UNIT_SUCCESS,
    ADD_UNIT_FAIL,
    UPDATE_UNIT,
    UPDATE_UNIT_SUCCESS,
    UPDATE_UNIT_FAIL,
    FETCH_UNIT,
    FETCH_UNIT_SUCCESS,
    FETCH_UNIT_FAIL,
    CLAIM_UNIT,
    CLAIM_UNIT_SUCCESS,
    CLAIM_UNIT_FAIL,
    APPROVE_REQUISITION,
    APPROVE_REQUISITION_SUCCESS,
    APPROVE_REQUISITION_FAIL,
    DENY_REQUISITION,
    DENY_REQUISITION_SUCCESS,
    DENY_REQUISITION_FAIL,
    GENERATE_STATEMENT_OF_ACCOUNT,
    GENERATE_STATEMENT_OF_ACCOUNT_SUCCESS,
    GENERATE_STATEMENT_OF_ACCOUNT_FAIL,
    REMOVE_TENANT,
    REMOVE_TENANT_SUCCESS,
    REMOVE_TENANT_FAIL,
};

export default (state, action) => {
    const { payload } = action;
    switch (action.type) {
        case ADD_UNIT_SUCCESS:
            return state.concat(payload);
        case UPDATE_UNIT_SUCCESS:
            return Object.assign({}, state, {
                buildingName: payload.buildingName,
                number: payload.number,
                floor: payload.floor,
                floorArea: payload.floorArea,
                unitType: payload.unitType,
                ownerId: payload.ownerId,
                ownerName: payload.ownerName,
                tenant: payload.tenant
            });
        case CLAIM_UNIT_SUCCESS:
            return state.map(u =>
                u.id === payload
                    ? Object.assign({}, u, {
                          status: "Proposed"
                      })
                    : u
            );
        case APPROVE_REQUISITION_SUCCESS:
        case DENY_REQUISITION_SUCCESS:
            return Object.assign({}, state, {
                status: payload.status,
                remarks: payload.remarks
            });
        case REMOVE_TENANT_SUCCESS:
            return Object.assign({}, state, {
                tenant: null
            });
        default:
            return state;
    }
};

export const fetchUnits = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_UNITS });
        return axios
            .request({
                url: `/api/${state.isAdmin ? "admin" : "account"}/units`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_UNITS_SUCCESS,
                        payload: res
                    });
                    return res.units;
                } else {
                    dispatch({ type: FETCH_UNITS_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_UNITS_FAIL });
            });
    };
};

export const addUnit = ({
    unitNumber,
    owner,
    floor,
    floorArea,
    unitType,
    newOwner
}) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: ADD_UNIT });
        return axios
            .request({
                url: `/api/admin/add_unit`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    buildingId: state.building.id,
                    unitNo: unitNumber.trim(),
                    ownerId: newOwner.isSelected ? null : owner.value.id,
                    floor: floor,
                    floorArea,
                    unitType: unitType.label,
                    ownerType: newOwner.isSelected ? newOwner.type.label : null,
                    ownerName:
                        newOwner.isSelected && newOwner.type.value === "Company"
                            ? newOwner.name
                            : null,
                    ownerFirstName:
                        newOwner.isSelected &&
                        newOwner.type.value === "Individual"
                            ? newOwner.firstName
                            : null,
                    ownerLastName:
                        newOwner.isSelected &&
                        newOwner.type.value === "Individual"
                            ? newOwner.lastName
                            : null,
                    ownerEmail: newOwner.isSelected ? newOwner.email : null,
                    ownerPhoneNumber: newOwner.isSelected
                        ? newOwner.phoneNumber
                        : null,
                    contactFirstName:
                        newOwner.isSelected && newOwner.type
                            ? newOwner.contactFirstName
                            : null,
                    contactLastName:
                        newOwner.isSelected && newOwner.type
                            ? newOwner.contactLastName
                            : null,
                    contactEmail:
                        newOwner.isSelected && newOwner.type
                            ? newOwner.contactEmail
                            : null,
                    contactPhoneNumber:
                        newOwner.isSelected && newOwner.type
                            ? newOwner.contactPhoneNumber
                            : null
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: ADD_UNIT_SUCCESS,
                        payload: {
                            id: res.unitId,
                            buildingId: state.building.id,
                            buildingName: state.building.name,
                            number: unitNumber,
                            floor: floor,
                            floorArea: floorArea,
                            unitType: unitType.label,
                            ownerId: newOwner.isSelected
                                ? res.ownerId
                                : owner.value.id,
                            ownerName: newOwner.isSelected
                                ? newOwner.type &&
                                  newOwner.type.value === "Company"
                                    ? newOwner.name
                                    : newOwner.firstName +
                                      " " +
                                      newOwner.lastName
                                : owner && owner.value && owner.value.name
                        }
                    });
                    if (newOwner.isSelected) {
                        dispatch({
                            type: ownerActions.ADD_OWNER_SUCCESS,
                            payload: {
                                id: res.ownerId,
                                type: newOwner.type.label,
                                name:
                                    newOwner.type.value === "Company"
                                        ? newOwner.name
                                        : newOwner.firstName +
                                          " " +
                                          newOwner.lastName,
                                firstName:
                                    newOwner.type.value === "Individual"
                                        ? newOwner.firstName
                                        : null,
                                lastName:
                                    newOwner.type.value === "Individual"
                                        ? newOwner.lastName
                                        : null,
                                email: newOwner.email,
                                phoneNumber: newOwner.phoneNumber,
                                contactFirstName: newOwner.contactFirstName,
                                contactLastName: newOwner.contactLastName,
                                contactEmail: newOwner.contactEmail,
                                contactPhoneNumber: newOwner.contactPhoneNumber
                            }
                        });
                    }
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                    toast("Successfully added new unit.");
                } else {
                    dispatch({ type: ADD_UNIT_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: ADD_UNIT_FAIL });
            });
    };
};

export const updateUnit = ({
    unitId,
    unitNumber,
    owner,
    floor,
    floorArea,
    unitType,
    newOwner
}) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: UPDATE_UNIT });
        return axios
            .request({
                url: `/api/admin/update_unit`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    id: unitId,
                    buildingId: state.building.id,
                    unitNo: unitNumber.trim(),
                    ownerId: newOwner.isSelected ? null : owner.value.id,
                    floor: floor,
                    floorArea,
                    unitType: unitType.value,
                    ownerType: newOwner.isSelected ? newOwner.type.label : null,
                    ownerName:
                        newOwner.isSelected && newOwner.type.value === "Company"
                            ? newOwner.name
                            : null,
                    ownerFirstName:
                        newOwner.isSelected &&
                        newOwner.type.value === "Individual"
                            ? newOwner.firstName
                            : null,
                    ownerLastName:
                        newOwner.isSelected &&
                        newOwner.type.value === "Individual"
                            ? newOwner.lastName
                            : null,
                    ownerEmail: newOwner.isSelected ? newOwner.email : null,
                    ownerPhoneNumber: newOwner.isSelected
                        ? newOwner.phoneNumber
                        : null,
                    contactFirstName: newOwner.isSelected
                        ? newOwner.contactFirstName
                        : null,
                    contactLastName: newOwner.isSelected
                        ? newOwner.contactLastName
                        : null,
                    contactEmail: newOwner.isSelected
                        ? newOwner.contactEmail
                        : null,
                    contactPhoneNumber: newOwner.isSelected
                        ? newOwner.contactPhoneNumber
                        : null
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: UPDATE_UNIT_SUCCESS,
                        payload: {
                            buildingId: state.building.id,
                            buildingName: state.building.name,
                            number: unitNumber,
                            floor: floor,
                            floorArea: floorArea,
                            unitType: unitType.value,
                            ownerId: newOwner.isSelected
                                ? res.ownerId
                                : owner.value.id,
                            ownerName: newOwner.isSelected
                                ? newOwner.type &&
                                  newOwner.type.value === "Company"
                                    ? newOwner.name
                                    : newOwner.firstName +
                                      " " +
                                      newOwner.lastName
                                : owner && owner.value && owner.value.name,
                            tenant: state.unit.tenant
                        }
                    });
                    if (newOwner.isSelected) {
                        dispatch({
                            type: ownerActions.ADD_OWNER_SUCCESS,
                            payload: {
                                id: res.ownerId,
                                type: newOwner.type.label,
                                name:
                                    newOwner.type.value === "Company"
                                        ? newOwner.name
                                        : newOwner.firstName +
                                          " " +
                                          newOwner.lastName,
                                firstName:
                                    newOwner.type.value === "Individual"
                                        ? newOwner.firstName
                                        : null,
                                lastName:
                                    newOwner.type.value === "Individual"
                                        ? newOwner.lastName
                                        : null,
                                email: newOwner.email,
                                phoneNumber: newOwner.phoneNumber,
                                contactFirstName: newOwner.contactFirstName,
                                contactLastName: newOwner.contactLastName,
                                contactEmail: newOwner.contactEmail,
                                contactPhoneNumber: newOwner.contactPhoneNumber
                            }
                        });
                    }
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                    toast("Successfully updated unit.");
                } else {
                    dispatch({ type: UPDATE_UNIT_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: UPDATE_UNIT_FAIL });
            });
    };
};

export const fetchUnit = unitId => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_UNIT });
        return axios
            .request({
                url: `/api/${state.isAdmin ? "admin" : "account"}/unit`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                params: {
                    id: unitId
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_UNIT_SUCCESS,
                        payload: {
                            unit: res.unit,
                            tenants: res.tenants
                        }
                    });
                } else {
                    dispatch({ type: FETCH_UNIT_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_UNIT_FAIL });
            });
    };
};

export const accountFetchUnclaimedUnits = ({ buildingId, requisitionType }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_UNCLAIMED_UNITS });
        return axios
            .request({
                url: `/api/account/unclaimed_units`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                params: {
                    buildingId,
                    requisitionType
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_UNCLAIMED_UNITS_SUCCESS,
                        payload: res.units
                    });
                } else {
                    dispatch({ type: FETCH_UNCLAIMED_UNITS_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_UNCLAIMED_UNITS_FAIL });
            });
    };
};

export const resetUnclaimedUnits = () => {
    return dispatch => {
        dispatch({ type: RESET_UNCLAIMED_UNITS });
    };
};

export const accountClaimUnit = ({ unitId, requisitionType }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: CLAIM_UNIT });
        return axios
            .request({
                url: `/api/account/unit_claim`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    unitId,
                    requisitionType
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: CLAIM_UNIT_SUCCESS,
                        payload: unitId
                    });
                } else {
                    dispatch({ type: CLAIM_UNIT_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload:
                            res.message ||
                            "Something went wrong please try again later."
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Something went wrong please try again later."
                    });
                }
                dispatch({ type: CLAIM_UNIT_FAIL });
            });
    };
};

export const adminFetchRequisitions = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_REQUISITIONS });
        return axios
            .request({
                url: `/api/admin/requisitions`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_REQUISITIONS_SUCCESS,
                        payload: res.unitRequisitions
                    });
                    return res.unitRequisitions;
                } else {
                    dispatch({ type: FETCH_REQUISITIONS_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Something went wrong please try again later."
                    });
                }
                dispatch({ type: FETCH_REQUISITIONS_FAIL });
            });
    };
};

export const adminFetchRequisition = requisitionId => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_REQUISITION });
        return axios
            .request({
                url: `/api/admin/requisition`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                params: {
                    id: requisitionId
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_REQUISITION_SUCCESS,
                        payload: res.unitRequisition
                    });
                } else {
                    dispatch({ type: FETCH_REQUISITION_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Something went wrong please try again later."
                    });
                }
                dispatch({ type: FETCH_REQUISITION_FAIL });
            });
    };
};

export const approveRequisition = id => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: APPROVE_REQUISITION });
        return axios
            .request({
                url: `/api/admin/approve_claim`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    id
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: APPROVE_REQUISITION_SUCCESS,
                        payload: { status: "Approved" }
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                    toast("Successfully approved requisition.");
                } else {
                    dispatch({ type: APPROVE_REQUISITION_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: APPROVE_REQUISITION_FAIL });
            });
    };
};

export const denyRequisition = ({ id, remarks }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: DENY_REQUISITION });
        return axios
            .request({
                url: `/api/admin/deny_claim`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    id,
                    remarks
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: DENY_REQUISITION_SUCCESS,
                        payload: { status: "Denied", remarks }
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                    toast("Successfully denied requisition.");
                } else {
                    dispatch({ type: DENY_REQUISITION_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: DENY_REQUISITION_FAIL });
            });
    };
};

export const generateStatementOfAccount = ({ unitId, startDate, endDate }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: GENERATE_STATEMENT_OF_ACCOUNT });
        return axios
            .request({
                url: `/api/${
                    state.isAdmin ? "admin" : "account"
                }/generate_statement`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    unitId,
                    startDate,
                    endDate
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    download(res);
                    dispatch({
                        type: GENERATE_STATEMENT_OF_ACCOUNT_SUCCESS
                    });
                } else {
                    dispatch({ type: GENERATE_STATEMENT_OF_ACCOUNT_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: GENERATE_STATEMENT_OF_ACCOUNT_FAIL });
            });
    };
};

export const removeTenant = (
    unitId,
) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: REMOVE_TENANT });
        return axios
            .request({
                url: `/api/admin/remove_tenant`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`,
                },
                data: {
                    unit_id: unitId,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                console.log(res)
                if (res.success) {
                    dispatch({
                        type: REMOVE_TENANT_SUCCESS
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE,
                    });
                    toast("Successfully removed tenant.");
                } else {
                    dispatch({ type: REMOVE_TENANT_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password.",
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: REMOVE_TENANT_FAIL });
            });
    };
};
import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { Loader } from "containers/Loader";
import { modalStyle,
    CloseButton,
    ModalBody,
    ModalFooter,
    ModalHeader } from "styles/Modals";
import { FlexContainer } from "styles/Containers";
import colors from "utils/colors";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";
class ConfirmationModal extends Component {
    handleSubmit() {
        const { onSubmit, closeModal } = this.props;
        onSubmit();
        closeModal();
    }

    render() {
        const {
            header,
            submitText,
            closeModal,
            cancelText,
            height,
            width,
            body,
            isModalLoading,
            isAdmin,
            modalVisible,
        } = this.props;

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={closeModal}
                style={modalStyle({
                    height: height || header ? 215 : 180,
                    width: width || 450,
                    backgroundColor: !isAdmin && "rgb(0, 139, 139, 0.9)"
                })}>
                <CloseButton onClick={closeModal} />
                {isModalLoading ? (
                    <Loader text="PROCESSING..." />
                ) : (
                    <Fragment>
                        <ModalHeader
                            style={{
                                paddingTop: "1.5rem",
                                textAlign: "center",
                            }}>
                            {header}
                        </ModalHeader>
                        <ModalBody style={{
                            marginTop: header ? "15px" : "5px",
                            textAlign: "center",
                        }}>
                            {body}
                        </ModalBody>
                        <ModalFooter
                            style={{
                                height: "65px",
                            }}>
                            <FlexContainer
                                style={{
                                    justifyContent: "space-around"
                                }}>
                                {cancelText && (
                                    <PrimaryAltButton
                                        style={{
                                            height: "38px",
                                            width: "48%",
                                            fontSize: "14px",
                                            borderColor: colors.GRAY,
                                            color: colors.GRAY,
                                        }}
                                        onClick={closeModal}
                                        text={cancelText}
                                    />
                                )}
                                <PrimaryButton
                                    style={{
                                        height: "38px",
                                        width: "48%",
                                        fontSize: "14px"
                                    }}
                                    onClick={() => this.handleSubmit()}
                                    text={submitText}
                                />
                            </FlexContainer>
                        </ModalFooter>
                    </Fragment>
                )}
            </Modal>        );
    }
}

const mapStateToProps = state => {
    return {
        isModalLoading: state.isModalLoading,
        isAdmin: state.isAdmin
    };
};
export default connect(mapStateToProps)(ConfirmationModal);

import axios from "axios";
import { toast } from "react-toastify";
import { userActions } from "./userReducer";
import { messageActions } from "./messageReducer";

const FETCH_NOTIFICATIONS = "FETCH_NOTIFICATIONS";
const FETCH_NOTIFICATIONS_SUCCESS = "FETCH_NOTIFICATIONS_SUCCESS";
const FETCH_NOTIFICATIONS_FAIL = "FETCH_NOTIFICATIONS_FAIL";

const MARK_AS_READ = "MARK_AS_READ";
const MARK_AS_READ_SUCCESS = "MARK_AS_READ_SUCCESS";
const MARK_AS_READ_FAIL = "MARK_AS_READ_FAIL";

const MARK_ALL_AS_READ = "MARK_ALL_AS_READ";
const MARK_ALL_AS_READ_SUCCESS = "MARK_ALL_AS_READ_SUCCESS";
const MARK_ALL_AS_READ_FAIL = "MARK_ALL_AS_READ_FAIL";

const ADD_NOTIFICATION = "ADD_NOTIFICATION";

export const notificationActions = {
    FETCH_NOTIFICATIONS,
    FETCH_NOTIFICATIONS_SUCCESS,
    FETCH_NOTIFICATIONS_FAIL,
    MARK_AS_READ,
    MARK_AS_READ_SUCCESS,
    MARK_AS_READ_FAIL,
    MARK_ALL_AS_READ,
    MARK_ALL_AS_READ_SUCCESS,
    MARK_ALL_AS_READ_FAIL,
    ADD_NOTIFICATION
};

export default (state, action) => {
    const { payload } = action;
    switch (action.type) {
        case MARK_AS_READ_SUCCESS:
            return state.map(notification =>
                notification.id === payload
                    ? Object.assign({}, notification, { status: "Read" })
                    : notification
            );
        case MARK_ALL_AS_READ_SUCCESS:
            return state.map(notification =>
                Object.assign({}, notification, { status: "Read" })
            );
        default:
            return state;
    }
};

export const fetchNotifications = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_NOTIFICATIONS });
        return axios
            .request({
                url: `/api/${
                    state.isAdmin ? "admin" : "account"
                }/notifications`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_NOTIFICATIONS_SUCCESS,
                        payload: res.notifications
                    });
                } else {
                    dispatch({ type: FETCH_NOTIFICATIONS_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_NOTIFICATIONS_FAIL });
            });
    };
};

export const markAsRead = ({ notification }, history) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: MARK_AS_READ });
        return axios
            .request({
                url: `/api/${state.isAdmin ? "admin" : "account"}/mark_as_read`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    id: notification.id
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    notificationRedirect(
                        { notification, isAdmin: state.isAdmin },
                        history
                    );
                    dispatch({
                        type: MARK_AS_READ_SUCCESS,
                        payload: notification.id
                    });
                } else {
                    dispatch({ type: MARK_AS_READ_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: MARK_AS_READ_FAIL });
            });
    };
};

export const markAllAsRead = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: MARK_ALL_AS_READ });
        return axios
            .request({
                url: `/api/${
                    state.isAdmin ? "admin" : "account"
                }/mark_all_as_read`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: MARK_ALL_AS_READ_SUCCESS
                    });
                } else {
                    dispatch({ type: MARK_ALL_AS_READ_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: MARK_ALL_AS_READ_FAIL });
            });
    };
};

export const notificationRedirect = ({ notification, isAdmin }, history) => {
    if (notification.type === "Unit") {
        history.push(
            `/${isAdmin ? "admin" : "account"}/units/${notification.unitId}`
        );
    } else if (
        notification.type === "Bill" ||
        notification.type === "Payment"
    ) {
        history.push(
            `/${isAdmin ? "admin" : "account"}/bills/${notification.billId}`
        );
    } else if (notification.type === "Reservation") {
        history.push(
            `/${isAdmin ? "admin" : "account"}/reservations/${
                notification.reservationId
            }`
        );
    } else if (notification.type === "Unit Requisition") {
        if (isAdmin) {
            history.push(`/admin/requisitions/${notification.requisitionId}`);
        } else if (notification.unitId) {
            history.push(`/account/units/${notification.unitId}`);
        } else {
            history.push("/account/units");
        }
    }
};

export const addNotification = res => {
    return dispatch => {
        const { notification } = res;
        dispatch({
            type: ADD_NOTIFICATION,
            payload: notification
        });
    };
};

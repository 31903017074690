import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FiEdit } from "react-icons/fi";
import { Spinner } from "./Spinner";
import PageHeader from "./PageHeader";
import { fetchTenant } from "../reducers/tenantReducer";
import TenantModal from "./TenantModal";
import TenantUnitsContainer from "./TenantUnitsContainer";
import TenantBillsContainer from "./TenantBillsContainer";
import TenantReservationsContainer from "./TenantReservationsContainer";
import TenantPaymentsContainer from "./TenantPaymentsContainer";
import {
    MainHeader,
    InfoContainer,
    InfoLabel,
    InfoText,
    ProfileImage,
    HeaderContainer,
    ContentContainer,
    ProfileHeader,
    ProfileNameHolder,
    ProfileContent,
    ProfileDetails,
    ProfileDetailsBox,
    FlexColumnContainer,
    FlexContainer,
} from "../styles/Containers";
import colors from "utils/colors";
import moment from "moment";


var md5 = require("md5");

class TenantContainer extends Component {
    state = {
        tenantModalVisible: false,
        tabVisible: "units"
    };

    componentDidMount() {
        const { tenantId, isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchTenant(tenantId));
        }
    }

    showTenantModal() {
        this.setState({ tenantModalVisible: true });
    }

    hideTenantModal() {
        this.setState({ tenantModalVisible: false });
    }

    tabChangeHandler(tabVisible) {
        this.setState({ tabVisible });
    }

    render() {
        const {
            tenant,
            units,
            bills,
            reservations,
            payments,
            isLoading,
            backTo,
            isAdmin
        } = this.props;
        const { tenantModalVisible, tabVisible } = this.state;

        const hashedEmail = tenant && tenant.email && md5(tenant.email);

        return (
            <Fragment>
                <PageHeader
                    title={(backTo && backTo.label) || "Back to Tenants"}
                    backTo={
                        (backTo && backTo.value) ||
                        `/${isAdmin ? "admin" : "account"}/tenants`
                    }
                />
                {isLoading ? (
                    <Spinner />
                ) : (
                    tenant && (
                        <Fragment>
                            <ProfileHeader style={{
                                padding: "30px",
                            }}>
                               <ProfileImage
                                    background={`https://www.gravatar.com/avatar/${hashedEmail}?s=150&d=mm`}
                                />
                                <ProfileNameHolder>
                                    {tenant.name}
                                    {isAdmin && (
                                        <FiEdit
                                            style={{
                                                margin: "0px 0px -2px 10px",
                                                cursor: "pointer",
                                                color: "#7FCAFD"
                                            }}
                                            onClick={() =>
                                                this.showTenantModal()
                                            }
                                        />
                                    )}
                                    <p>{tenant.email}</p>
                                </ProfileNameHolder>
                            </ProfileHeader>
                            <FlexColumnContainer>
                                <ProfileDetails>
                                    <FlexColumnContainer>
                                    <h3>Account</h3>
                                    <FlexContainer style={{
                                        alignItems: "baseline",
                                    }}>
                                        <ProfileDetailsBox style={{
                                            width: "100%",
                                        }}>
                                            <div>
                                                <p>Name</p>
                                                <span style={{
                                                    width: "65%",
                                                }}>
                                                    {tenant.name}
                                                </span>
                                            </div>
                                            <div>
                                                <p>Email</p>
                                                <span style={{
                                                    width: "65%",
                                                }}>
                                                    {tenant.email}
                                                </span>
                                            </div>
                                            <div>
                                                <p>Phone Number</p>
                                                <span>{tenant.phoneNumber}</span>
                                            </div>
                                            {
                                                tenant.occupation &&
                                                (<div>
                                                    <p>Occupation</p>
                                                    <span>{tenant.occupation}</span>
                                                </div>)
                                             }
                                        </ProfileDetailsBox>
                                        <ProfileDetailsBox style={{
                                            paddingLeft: "30px",
                                        }}>
                                            {
                                                tenant.type === "Company" ?
                                                    (
                                                        <Fragment>
                                                            <div>
                                                                <p>Contact Name</p>
                                                                <span>{tenant.contactFirstName +
                                                                    " " +
                                                                    tenant.contactLastName}</span>
                                                            </div>
                                                            <div>
                                                                <p>Contact Email</p>
                                                                <span>{tenant.contactEmail}</span>
                                                            </div>
                                                            <div>
                                                                <p>Contact Phone Number</p>
                                                                <span>{tenant.contactPhoneNumber}</span>
                                                            </div>
                                                        </Fragment>
                                                    )   :
                                                    (
                                                        (tenant.age ||
                                                            tenant.sex ||
                                                            tenant.birthdate ||
                                                            tenant.nationality) && (
                                                                <Fragment>
                                                                    {
                                                                        tenant.age &&
                                                                        (<div>
                                                                            <p>Age</p>
                                                                            <span>{tenant.age}</span>
                                                                        </div>)
                                                                     }
                                                                    {
                                                                        tenant.sex &&
                                                                        (<div>
                                                                            <p>Sex</p>
                                                                            <span>{tenant.sex}</span>
                                                                        </div>)
                                                                    }
                                                                    {
                                                                        tenant.nationality &&
                                                                        (<div>
                                                                            <p>Nationality</p>
                                                                            <span>{tenant.nationality}</span>
                                                                        </div>)
                                                                     }
                                                                     {
                                                                        tenant.birthdate &&
                                                                        (<div>
                                                                            <p>Birthday</p>
                                                                            <span>{tenant.birthdate
                                                                                    ? moment(tenant.birthdate).format("MM/DD/YYYY")
                                                                                    : ""}
                                                                            </span>
                                                                        </div>)
                                                                     }
                                                                </Fragment>
                                                            )
                                                    )
                                            }
                                        </ProfileDetailsBox>
                                    </FlexContainer>
                                    </FlexColumnContainer>
                                </ProfileDetails>
                            </FlexColumnContainer>
                            <ContentContainer style={{
                                marginTop: "0",
                            }}>
                                <TenantUnitsContainer
                                    tabVisible={tabVisible === "units"}
                                    tenantId={tenant.id}
                                    units={units}
                                    onTabChange={this.tabChangeHandler.bind(this)}
                                    modalVisible={tenantModalVisible}
                                />
                                <TenantBillsContainer
                                    tabVisible={tabVisible === "bills"}
                                    tenantId={tenant.id}
                                    bills={bills}
                                    onTabChange={this.tabChangeHandler.bind(this)}
                                    modalVisible={tenantModalVisible}
                                />
                                <TenantReservationsContainer
                                    tabVisible={tabVisible === "reservations"}
                                    tenantId={tenant.id}
                                    reservations={reservations}
                                    onTabChange={this.tabChangeHandler.bind(this)}
                                    modalVisible={tenantModalVisible}
                                />
                                <TenantPaymentsContainer
                                    tabVisible={tabVisible === "payments"}
                                    tenantId={tenant.id}
                                    payments={payments}
                                    onTabChange={this.tabChangeHandler.bind(this)}
                                    modalVisible={tenantModalVisible}
                                />
                            </ContentContainer>
                        </Fragment>
                    )
                )}
                {tenantModalVisible && (
                    <TenantModal
                        modalVisible={tenantModalVisible}
                        closeModal={this.hideTenantModal.bind(this)}
                    />
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = state => {
    return {
        tenant: state.tenant,
        units: state.units,
        bills: state.bills,
        reservations: state.reservations,
        payments: state.payments,
        isLoading: state.isLoading,
        isAdmin: state.isAdmin,
        isAuthorized: state.isAuthorized
    };
};

export default connect(mapStateToProps)(TenantContainer);

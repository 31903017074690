import axios from "axios";
import { toast } from "react-toastify";
import { messageActions } from "./messageReducer";

const SIGNING_IN = "SIGNING_IN";
const LOGIN = "LOGIN";
const LOGIN_FAIL = "LOGIN_FAIL";
const LOGOUT = "LOGOUT";
const AUTH_SUCCESS = "AUTH_SUCCESS";

const FORGOT_PASSWORD = "FORGOT_PASSWORD";
const FORGOT_PASSWORD_SUCCESS = "FORGOT_PASSWORD_SUCCESS";
const FORGOT_PASSWORD_FAIL = "FORGOT_PASSWORD_FAIL";

const CHANGE_PASSWORD = "CHANGE_PASSWORD";
const CHANGE_PASSWORD_SUCCESS = "CHANGE_PASSWORD_SUCCESS";
const CHANGE_PASSWORD_FAIL = "CHANGE_PASSWORD_FAIL";

const RESET_PASSWORD = "RESET_PASSWORD";
const RESET_PASSWORD_SUCCESS = "RESET_PASSWORD_SUCCESS";
const RESET_PASSWORD_FAIL = "RESET_PASSWORD_FAIL";

const ACCOUNT_SIGN_UP = "ACCOUNT_SIGN_UP";
const ACCOUNT_SIGN_UP_SUCCESS = "ACCOUNT_SIGN_UP_SUCCESS";
const ACCOUNT_SIGN_UP_FAIL = "ACCOUNT_SIGN_UP_FAIL";

const FETCH_OVERVIEW = "FETCH_OVERVIEW";
const FETCH_OVERVIEW_SUCCESS = "FETCH_OVERVIEW_SUCCESS";
const FETCH_OVERVIEW_FAIL = "FETCH_OVERVIEW_FAIL";

const CONFIRM_ACCOUNT = "CONFIRM_ACCOUNT";
const CONFIRM_ACCOUNT_SUCCESS = "CONFIRM_ACCOUNT_SUCCESS";
const CONFIRM_ACCOUNT_FAIL = "CONFIRM_ACCOUNT_FAIL";

export const userActions = {
    SIGNING_IN,
    LOGIN,
    LOGIN_FAIL,
    LOGOUT,
    AUTH_SUCCESS,
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_FAIL,
    CHANGE_PASSWORD,
    CHANGE_PASSWORD_SUCCESS,
    CHANGE_PASSWORD_FAIL,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_FAIL,
    ACCOUNT_SIGN_UP,
    ACCOUNT_SIGN_UP_SUCCESS,
    ACCOUNT_SIGN_UP_FAIL,
    FETCH_OVERVIEW,
    FETCH_OVERVIEW_SUCCESS,
    FETCH_OVERVIEW_FAIL,
    CONFIRM_ACCOUNT,
    CONFIRM_ACCOUNT_SUCCESS,
    CONFIRM_ACCOUNT_FAIL,
};

const emptyUser = {
    email: null,
    name: null,
    lastName: null,
    isResettingPassword: false,
    passwordSuccess: 0,
    role: null,
};

export default (state, action) => {
    const { payload } = action;
    switch (action.type) {
        case LOGIN:
        case AUTH_SUCCESS:
            const { user } = payload;
            return {
                ...state,
                email: user.email,
                name: user.firstName,
                lastName: user.lastName,
                role: user.role,
                hasTenants: user.hasTenants,
            };
        case LOGOUT:
            return { ...state, ...emptyUser };
        default:
            return state;
    }
};

export const adminLogin = ({ username, password }, history) => {
    return (dispatch) => {
        dispatch({ type: messageActions.CLEAR_MESSAGE });
        dispatch({ type: SIGNING_IN });
        return axios
            .request({
                url: `/api/admin/authenticate`,
                method: "POST",
                data: {
                    email: username,
                    password: password,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                history.push("/admin");
                dispatch({
                    type: LOGIN,
                    payload: Object.assign({}, res, { isAdmin: true }),
                });
                toast("Successfully logged in.");
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: err.response.data.error.message,
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: LOGIN_FAIL });
            });
    };
};

export const accountLogin = ({ username, password }, history) => {
    return (dispatch) => {
        dispatch({ type: messageActions.CLEAR_MESSAGE });
        dispatch({ type: SIGNING_IN });
        return axios
            .request({
                url: `/api/account/authenticate`,
                method: "POST",
                data: {
                    email: username,
                    password: password,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                history.push("/account");
                dispatch({ type: LOGIN, payload: res });
                toast("Successfully logged in.");
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: err.response.data.error.message,
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: LOGIN_FAIL });
            });
    };
};

export const adminLogout = (history) => {
    return (dispatch) => {
        dispatch({ type: LOGOUT });
        history.push("/admin/logout");
    };
};

export const accountLogout = (history) => {
    return (dispatch) => {
        dispatch({ type: LOGOUT });
        history.push("/account/logout");
    };
};

export const forgotPassword = ({ email, isAdmin }) => {
    return (dispatch) => {
        dispatch({ type: FORGOT_PASSWORD });
        return axios
            .request({
                url: `/api/${isAdmin ? "admin" : "account"}/forgot_password`,
                method: "POST",
                data: {
                    email
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE,
                    });
                    dispatch({ type: FORGOT_PASSWORD_SUCCESS });
                } else {
                    dispatch({ type: FORGOT_PASSWORD_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: err.response.data.error.message,
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FORGOT_PASSWORD_FAIL });
            });
    };
};

export const resetPassword = ({ token, password, isAdmin }) => {
    return (dispatch) => {
        dispatch({ type: RESET_PASSWORD });
        return axios
            .request({
                url: `/api/${isAdmin ? "admin" : "account"}/reset_password`,
                method: "POST",
                data: {
                    token,
                    password,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE,
                    });
                    dispatch({ type: RESET_PASSWORD_SUCCESS });
                } else {
                    dispatch({ type: RESET_PASSWORD_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: err.response.data.error.message,
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: RESET_PASSWORD_FAIL });
            });
    };
};

export const accountSignUp = ({
    type,
    name,
    firstName,
    lastName,
    email,
    password,
    confirmPassword,
}) => {
    return (dispatch) => {
        dispatch({ type: ACCOUNT_SIGN_UP });
        return axios
            .request({
                url: `/api/account/create_account`,
                method: "POST",
                data: {
                    accountType: type.value,
                    name,
                    firstName,
                    lastName,
                    email,
                    password,
                    passwordConfirmation: confirmPassword,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE,
                    });
                    dispatch({ type: ACCOUNT_SIGN_UP_SUCCESS });
                } else {
                    dispatch({ type: ACCOUNT_SIGN_UP_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: err.response.data.error.message,
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: ACCOUNT_SIGN_UP_FAIL });
            });
    };
};

export const changePassword = ({
    newPassword,
    oldPassword,
    confirmPassword,
}) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: CHANGE_PASSWORD });
        return axios
            .request({
                url: `/api/${
                    state.isAdmin ? "admin" : "account"
                }/change_password`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`,
                },
                data: {
                    newPassword,
                    oldPassword,
                    passwordConfirmation: confirmPassword,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({ type: CHANGE_PASSWORD_SUCCESS });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE,
                    });
                } else {
                    dispatch({ type: CHANGE_PASSWORD_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: err.response.data.error.message,
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: CHANGE_PASSWORD_FAIL });
            });
    };
};

export const fetchOverview = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_OVERVIEW });
        return axios
            .request({
                url: `/api/${state.isAdmin ? "admin" : "account"}/overview`,
                method: "get",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: FETCH_OVERVIEW_SUCCESS,
                        payload: res.overview,
                    });
                } else {
                    dispatch({ type: FETCH_OVERVIEW_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password.",
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_OVERVIEW_FAIL });
            });
    };
};

export const confirmAccount = (token) => {
    return (dispatch) => {
        dispatch({ type: CONFIRM_ACCOUNT });
        return axios
            .request({
                url: `/api/account/confirm_account`,
                method: "POST",
                data: {
                    token,
                },
            })
            .then((res) => res.data)
            .then((res) => {
                if (res.success) {
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE,
                    });
                    dispatch({ type: CONFIRM_ACCOUNT_SUCCESS });
                } else {
                    dispatch({ type: CONFIRM_ACCOUNT_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message,
                    });
                }
            })
            .catch((err) => {
                if (err.response && err.response.status === 401) {
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: err.response.data.error.message,
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: CONFIRM_ACCOUNT_FAIL });
            });
    };
};

import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { messageActions } from "../reducers/messageReducer";
import MessageContainer from "./MessageContainer";
import { Loader } from "./Loader";
import { updateTenant } from "reducers/tenantReducer";
import { isValidEmail } from "utils/InputPatterns";
import { InfoContainer, InfoLabel } from "styles/Containers";
import moment from "moment";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "styles/Modals";
import { Input, Select, InputDatePicker } from "styles/Inputs";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";

const tenantTypeOptions = [
    {
        value: "Individual",
        label: "Individual",
    },
    {
        value: "Company",
        label: "Company",
    },
];

const sexOptions = [
    {
        value: "Male",
        label: "Male",
    },
    {
        value: "Female",
        label: "Female",
    },
    {
        value: "Other",
        label: "Other",
    },
];

class TenantModal extends Component {
    state = {
        id: null,
        name: "",
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: "",
        age: "",
        sex: null,
        occupation: "",
        nationality: "",
        birthdate: null,
        contactFirstName: "",
        contactLastName: "",
        contactEmail: "",
        hasError: false,
    };

    componentDidMount() {
        const { tenant } = this.props;
        if (tenant) {
            this.setState({
                id: tenant.id,
                type: { value: tenant.type, label: tenant.type },
                name: tenant.name || "",
                firstName: tenant.firstName || "",
                lastName: tenant.lastName || "",
                email: tenant.email || "",
                phoneNumber: tenant.phoneNumber || "",
                age: tenant.age || "",
                sex: tenant.sex
                    ? { value: tenant.sex, label: tenant.sex }
                    : null,
                occupation: tenant.occupation || "",
                nationality: tenant.nationality || "",
                birthdate: Date.parse(tenant.birthdate) || null,
                contactFirstName: tenant.contactFirstName || "",
                contactLastName: tenant.contactLastName || "",
                contactEmail: tenant.contactEmail || "",
                contactPhoneNumber: tenant.contactPhoneNumber || "",
            });
        }
    }

    async handleSubmit() {
        const { tenantEmails, onSubmit, closeModal, dispatch } = this.props;
        const {
            type,
            name,
            firstName,
            lastName,
            email,
            phoneNumber,
            birthdate,
            contactFirstName,
            contactLastName,
            contactEmail,
            contactPhoneNumber,
        } = this.state;

        let errorMessage = "";
        const requiredFieldsFilled =
            email.trim().length > 0 &&
            phoneNumber.trim().length > 0 &&
            (type.value === "Company"
                ? name.trim().length > 0 &&
                  contactFirstName.trim().length > 0 &&
                  contactLastName.trim().length > 0 &&
                  contactEmail.trim().length > 0 &&
                  contactPhoneNumber.trim().length > 0
                : type.value === "Individual" &&
                  firstName.trim().length > 0 &&
                  lastName.trim().length > 0) &&
                  birthdate

        const emailNotUnique = tenantEmails.includes(
            email.trim().toLowerCase()
        );

        errorMessage = !requiredFieldsFilled
            ? "Please fill up all required fields."
            : errorMessage;
        errorMessage =
            email && !isValidEmail(email)
                ? errorMessage.length > 0
                    ? errorMessage + " Invalid email."
                    : "Invalid email."
                : emailNotUnique
                ? errorMessage.length > 0
                    ? errorMessage + " Email must be unique."
                    : "Email must be unique."
                : errorMessage;

        if (errorMessage.length > 0) {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: errorMessage,
            });
            this.setState({ hasError: true });
        } else {
            await dispatch(updateTenant(this.state));
            if (!this.props.errorMessage) {
                this.setState({ hasError: false });
                closeModal();
                if (Boolean(onSubmit)) {
                    onSubmit();
                }
            }
        }
    }

    handleClose() {
        const { closeModal, dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE,
        });
        this.setState({ hasError: false });
        closeModal();
    }

    render() {
        const {
            tenantEmails,
            isTenantWithBills,
            isModalLoading,
            modalVisible,
        } = this.props;
        const {
            type,
            name,
            firstName,
            lastName,
            email,
            phoneNumber,
            age,
            sex,
            occupation,
            nationality,
            birthdate,
            contactFirstName,
            contactLastName,
            contactEmail,
            contactPhoneNumber,
            hasError,
        } = this.state;

        // const dateToday = moment();
        // const sampDate = moment("2010-01-25");

        // console.log(dateToday);

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={
                    isModalLoading ? null : this.handleClose.bind(this)
                }
                style={modalStyle({
                    height: isTenantWithBills
                        ? 540
                        : type && type.value === "Company"
                        ? 600
                        : 680,
                    width: 450,
                })}
            >
                <CloseButton
                    onClick={() => (isModalLoading ? null : this.handleClose())}
                />
                <ModalHeaderContainer>
                    <ModalHeader>UPDATE TENANT</ModalHeader>
                </ModalHeaderContainer>
                <ModalBody
                    height={
                        isTenantWithBills
                            ? "320px"
                            : type && type.value === "Company"
                            ? "380px"
                            : "460px"
                    }
                >
                    <InfoContainer style={{ marginTop: 0 }}>
                        TENANT INFORMATION
                    </InfoContainer>
                    <InfoContainer>
                        {!isTenantWithBills && (
                            <div style={{ width: "182.5px" }}>
                                <InfoLabel required>TENANT TYPE</InfoLabel>
                                <Select
                                    value={type}
                                    required={!type && hasError}
                                    onChange={(selectedTenantType) =>
                                        this.setState({
                                            type: selectedTenantType,
                                        })
                                    }
                                    options={tenantTypeOptions}
                                    placeholder="Select Tenant Type"
                                />
                            </div>
                        )}
                        {type &&
                            type.value === "Company" &&
                            !isTenantWithBills && (
                                <div style={{ width: "182.5px" }}>
                                    <InfoLabel required>COMPANY NAME</InfoLabel>
                                    <Input
                                        placeholder="e.g. Ipsum"
                                        value={name}
                                        required={
                                            (!name ||
                                                name.trim().length === 0) &&
                                            hasError
                                        }
                                        disabled={isModalLoading}
                                        onChange={(e) =>
                                            this.setState({
                                                name: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            )}
                    </InfoContainer>
                    {type && type.value === "Individual" && !isTenantWithBills && (
                        <InfoContainer>
                            <div style={{ width: "182.5px" }}>
                                <InfoLabel required>FIRST NAME</InfoLabel>
                                <Input
                                    placeholder="e.g. Joe"
                                    value={firstName}
                                    required={
                                        (!firstName ||
                                            firstName.trim().length === 0) &&
                                        hasError
                                    }
                                    disabled={isModalLoading}
                                    onChange={(e) =>
                                        this.setState({
                                            firstName: e.target.value,
                                        })
                                    }
                                />
                            </div>
                            <div style={{ width: "182.5px" }}>
                                <InfoLabel required>LAST NAME</InfoLabel>
                                <Input
                                    placeholder="e.g. Star"
                                    value={lastName}
                                    required={
                                        (!lastName ||
                                            lastName.trim().length === 0) &&
                                        hasError
                                    }
                                    disabled={isModalLoading}
                                    onChange={(e) =>
                                        this.setState({
                                            lastName: e.target.value,
                                        })
                                    }
                                />
                            </div>
                        </InfoContainer>
                    )}
                    <InfoContainer>
                        <div style={{ width: "182.5px" }}>
                            <InfoLabel required>EMAIL</InfoLabel>
                            <Input
                                placeholder="e.g. example@mail.com"
                                value={email}
                                required={
                                    (!email ||
                                        email.trim().length === 0 ||
                                        tenantEmails.includes(
                                            email.trim().toLowerCase()
                                        )) &&
                                    hasError
                                }
                                disabled={isModalLoading}
                                onChange={(e) =>
                                    this.setState({
                                        email: e.target.value,
                                    })
                                }
                            />
                        </div>
                        <div style={{ width: "182.5px" }}>
                            <InfoLabel required>NUMBER</InfoLabel>
                            <Input
                                placeholder="e.g. +6398765432100"
                                type="number"
                                value={phoneNumber}
                                required={
                                    (!phoneNumber ||
                                        phoneNumber.trim().length === 0) &&
                                    hasError
                                }
                                disabled={isModalLoading}
                                onChange={(e) =>
                                    this.setState({
                                        phoneNumber: e.target.value,
                                    })
                                }
                            />
                        </div>
                    </InfoContainer>
                    {type && type.value === "Company" ? (
                        <Fragment>
                            <InfoContainer>
                                <div style={{ width: "182.5px" }}>
                                    <InfoLabel required>
                                        CONTACT FIRST NAME
                                    </InfoLabel>
                                    <Input
                                        placeholder="e.g. Joe"
                                        value={contactFirstName}
                                        required={
                                            (!contactFirstName ||
                                                contactFirstName.trim()
                                                    .length === 0) &&
                                            hasError
                                        }
                                        disabled={isModalLoading}
                                        onChange={(e) =>
                                            this.setState({
                                                contactFirstName:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div style={{ width: "182.5px" }}>
                                    <InfoLabel required>
                                        CONTACT LAST NAME
                                    </InfoLabel>
                                    <Input
                                        placeholder="e.g. Star"
                                        value={contactLastName}
                                        required={
                                            (!contactLastName ||
                                                contactLastName.trim()
                                                    .length === 0) &&
                                            hasError
                                        }
                                        disabled={isModalLoading}
                                        onChange={(e) =>
                                            this.setState({
                                                contactLastName: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </InfoContainer>
                            <InfoContainer>
                                <div style={{ width: "182.5px" }}>
                                    <InfoLabel required>
                                        CONTACT EMAIL
                                    </InfoLabel>
                                    <Input
                                        placeholder="e.g. example@mail.com"
                                        value={contactEmail}
                                        required={
                                            (!contactEmail ||
                                                contactEmail.trim().length ===
                                                    0) &&
                                            hasError
                                        }
                                        disabled={isModalLoading}
                                        onChange={(e) =>
                                            this.setState({
                                                contactEmail: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div style={{ width: "182.5px" }}>
                                    <InfoLabel required>
                                        CONTACT NUMBER
                                    </InfoLabel>
                                    <Input
                                        placeholder="e.g. +6398765432100"
                                        type="number"
                                        value={contactPhoneNumber}
                                        required={
                                            (!contactPhoneNumber ||
                                                contactPhoneNumber.trim()
                                                    .length === 0) &&
                                            hasError
                                        }
                                        disabled={isModalLoading}
                                        onChange={(e) =>
                                            this.setState({
                                                contactPhoneNumber:
                                                    e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </InfoContainer>
                        </Fragment>
                    ) : (
                        <Fragment>
                            <InfoContainer>
                                <div style={{ width: "65px" }}>
                                    <InfoLabel>AGE</InfoLabel>
                                    <Input
                                        placeholder="e.g. 40"
                                        type="number"
                                        value={age}
                                        disabled={isModalLoading}
                                        onChange={(e) =>
                                            this.setState({
                                                age: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div style={{ width: "100px" }}>
                                    <InfoLabel>SEX</InfoLabel>
                                    <Select
                                        disabled={isModalLoading}
                                        value={sex}
                                        onChange={(selectedSex) =>
                                            this.setState({ sex: selectedSex })
                                        }
                                        options={sexOptions}
                                        placeholder="Select"
                                    />
                                </div>
                                <div style={{ width: "182.5px" }}>
                                    <InfoLabel required>
                                        BIRTHDAY
                                    </InfoLabel>
                                    <InputDatePicker
                                        selected={birthdate}
                                        required={!birthdate && hasError}
                                        disabled={isModalLoading}
                                        onChange={(date) =>
                                            this.setState({
                                                birthdate: date,
                                            })
                                        }
                                        maxDate={new Date()}
                                    />
                                </div>
                            </InfoContainer>
                            <InfoContainer>
                                <div style={{ width: "182.5px" }}>
                                    <InfoLabel>OCCUPATION</InfoLabel>
                                    <Input
                                        placeholder="e.g. Entrepreneur"
                                        value={occupation}
                                        disabled={isModalLoading}
                                        onChange={(e) =>
                                            this.setState({
                                                occupation: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                                <div style={{ width: "182.5px" }}>
                                    <InfoLabel>NATIONALITY</InfoLabel>
                                    <Input
                                        placeholder="e.g. Filipino"
                                        value={nationality}
                                        disabled={isModalLoading}
                                        onChange={(e) =>
                                            this.setState({
                                                nationality: e.target.value,
                                            })
                                        }
                                    />
                                </div>
                            </InfoContainer>
                        </Fragment>
                    )}
                    <MessageContainer />
                </ModalBody>
                <ModalFooter style={{ height: "110px" }}>
                    {isModalLoading ? (
                        <Loader text="PROCESSING..." />
                    ) : (
                        <Fragment>
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px",
                                }}
                                onClick={() => this.handleSubmit()}
                                text="UPDATE TENANT"
                            />
                            <PrimaryAltButton
                                style={{
                                    height: "38px",
                                    width: "100%",
                                    fontSize: "14px",
                                    marginTop: "8px",
                                }}
                                onClick={() => this.handleClose()}
                                text="CANCEL"
                            />
                        </Fragment>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        tenant: state.tenant,
        tenantEmails: state.tenants
            ? state.tenants.map(
                  (t) =>
                      t.id !== state.tenant.id &&
                      t.email &&
                      t.email.toLowerCase()
              )
            : [],
        isTenantWithBills: Boolean(
            state.tenant &&
                state.tenant.units &&
                state.tenant.units.find((u) => u.bills && u.bills.length > 0)
        ),
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage,
    };
};
export default connect(mapStateToProps)(TenantModal);

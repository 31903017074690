import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { FiUploadCloud } from "react-icons/fi";
import { Button } from "styles/Buttons";
import UploadModal from "./UploadModal";
import UploadUnitsResultModal from "./UploadUnitsResultModal";
import {
    TabHeaderContainer,
    ActiveTabHeader,
    InactiveTabHeader,
    TabHeaderSpacer,
    QuickActiveTabHeader,
    QuickInactiveTabHeader
} from "styles/Tabs";
import { FlexContainer,
     FlexColumnContainer,
     WhiteCardBox,
     HRContainer } from "styles/Containers";


class BuildingUploadsContainer extends Component {
    state = {
        uploadModalVisible: false,
        uploadUnitsResultModalVisible: false
    };

    showUploadModal() {
        this.setState({ uploadModalVisible: true });
    }

    hideUploadModal() {
        this.setState({ uploadModalVisible: false });
    }

    showUploadUnitsResultModal() {
        this.setState({ uploadUnitsResultModalVisible: true });
    }

    hideUploadUnitsResultModal() {
        this.setState({ uploadUnitsResultModalVisible: false });
    }

    handleBuildingChange(selectedBuilding) {
        const { dispatch } = this.props;
        dispatch(selectBuilding(selectedBuilding.value));
    }

    render() {
        const { tabVisible, onTabChange } = this.props;
        const {
            uploadModalVisible,
            uploadUnitsResultModalVisible
        } = this.state;

        return (
            <Fragment>
                {tabVisible && (
                    <FlexContainer>

                        <WhiteCardBox
                            style={{
                                width: "20%",
                                minWidth: "230px",
                                marginRight: "3%",
                            }}>
                            <p>Quick Links</p>
                            <HRContainer />
                            <FlexColumnContainer>
                                <QuickInactiveTabHeader
                                    onClick={() => onTabChange("unitTypes")}
                                    style={{
                                        marginBottom: "10px",
                                    }}
                                >
                                    Unit Types
                                </QuickInactiveTabHeader>
                                <QuickInactiveTabHeader
                                    style={{
                                        marginBottom: "10px",
                                    }}
                                    onClick={() => onTabChange("billingCategories")}
                                >
                                    Billing Categories
                                </QuickInactiveTabHeader>
                                <QuickActiveTabHeader>
                                    Uploads
                                </QuickActiveTabHeader>
                            </FlexColumnContainer>
                        </WhiteCardBox>

                        <div style={{}}>
                            <Button
                                style={{
                                    height: "75px",
                                    width: "150px",
                                    fontSize: "14px",
                                    color: "black",
                                    backgroundColor: "white",
                                    float: "none"
                                }}
                                onClick={() => this.showUploadModal()}
                            >
                                <FiUploadCloud style={{ margin: "0 5px -1px 0" }} />
                                <span>Upload Units</span>
                            </Button>
                        </div>
                        {uploadModalVisible && (
                            <UploadModal
                                modalVisible={uploadModalVisible}
                                onSuccess={() =>
                                    this.setState({
                                        uploadModalVisible: false,
                                        uploadUnitsResultModalVisible: true
                                    })
                                }
                                closeModal={this.hideUploadModal.bind(this)}
                            />
                        )}
                        {uploadUnitsResultModalVisible && (
                            <UploadUnitsResultModal
                                modalVisible={uploadUnitsResultModalVisible}
                                closeModal={this.hideUploadUnitsResultModal.bind(
                                    this
                                )}
                            />
                        )}
                    </FlexContainer>
                )}
            </Fragment>
        );
    }
}

export default connect()(BuildingUploadsContainer);

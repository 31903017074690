import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import { messageActions } from "reducers/messageReducer";
import MessageContainer from "containers/MessageContainer";
import { Loader } from "containers/Loader";
import { positiveNumber, currencyFormat } from "utils/InputPatterns";
import {
    addBillingCategory,
    updateBillingCategory
} from "reducers/buildingReducer";
import { InfoLabel,
    FlexContainer,
    FlexColumnContainer,
    PesoSign } from "styles/Containers";
import {
    modalStyle,
    CloseButton,
    ModalHeaderContainer,
    ModalHeader,
    ModalBody,
    ModalFooter,

} from "styles/Modals";
import { Input, Select, InputCurrency } from "styles/Inputs";
import { PrimaryButton, PrimaryAltButton } from "styles/Buttons";
import colors from "utils/colors";


const billTypeOptions = [
    {
        value: "total_only",
        label: "Total Only"
    },
    {
        value: "floor_sized_dependent",
        label: "Floor Area Dependent"
    },
    {
        value: "rate_type",
        label: "Rate Type"
    }
];

const overdueTypeOptions = [
    {
        value: "Raw",
        label: "Raw (Php)"
    },
    {
        value: "Percent",
        label: "Percentage (%)"
    },
];

class BillingCategoryModal extends Component {
    state = {
        building: null,
        name: "",
        billType: null,
        overdueType: null,
        overdueAmount: 0,
        isNew: true,
        hasError: false
    };

    componentDidMount() {
        const { billingCategory, building } = this.props;
        const newBuilding = building
            ? {
                  value: building.id,
                  label: building.name
              }
            : null;

        if (billingCategory) {
            this.setState({
                building: newBuilding,
                name: billingCategory.name,
                billType: billTypeOptions.find(
                    billType => billType.value === billingCategory.type
                ),
                overdueType: overdueTypeOptions.find(
                    (opt)=> opt.value === billingCategory.overdueType
                ),
                overdueAmount: billingCategory.overdueAmount,
                isNew: false,
            });
        } else {
            this.setState({
                building: newBuilding
            });
        }
    }

    async handleSubmit() {
        const {
            billingCategory,
            billingCategoryNames,
            closeModal,
            dispatch
        } = this.props;
        const { building, billType, name, overdueType, overdueAmount } = this.state;

        const errorMessage =
            !name || !name.trim().length > 0 || !billType || !overdueType || !overdueAmount
                ? "Please fill up required fields."
                : billingCategoryNames.includes(name)
                ? "Name should be unique."
                : ""

        if (errorMessage === "") {
            if (billingCategory) {
                await dispatch(
                    updateBillingCategory({
                        buildingId: building.value,
                        billingCategory: billingCategory.name,
                        overdueType: overdueType &&
                            overdueType.value,
                        overdueAmount: overdueAmount,
                        newBillingCategory: name.trim(),
                        billType: billType.value
                    })
                );
            } else {
                await dispatch(
                    addBillingCategory({
                        buildingId: building.value,
                        name: name.trim(),
                        billType: billType.value,
                        overdueType: overdueType &&
                            overdueType.value,
                        overdueAmount: overdueAmount,
                    })
                );
            }
            if (!this.props.errorMessage) {
                this.setState({ hasError: false });
                closeModal();
            }
        } else {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: errorMessage
            });
            this.setState({ hasError: true });
        }
    }

    handleClose() {
        const { closeModal, dispatch } = this.props;
        dispatch({
            type: messageActions.CLEAR_MESSAGE
        });
        this.setState({ hasError: false });
        closeModal();
    }

    handleAmountChange(e, maskedValue, floatValue) {
        this.setState({ overdueAmount: floatValue });
    }

    render() {

        const {
            building,
            billType,
            name,
            overdueType,
            overdueAmount,
            isNew,
            hasError,
        } = this.state;

        const { buildingOptions, isModalLoading, modalVisible } = this.props;

        return (
            <Modal
                isOpen={modalVisible}
                onRequestClose={
                    isModalLoading ? null : this.handleClose.bind(this)
                }
                style={modalStyle({ height: isNew ? 560 : 485, width: 500 })}
            >
                <CloseButton
                    onClick={() => (isModalLoading ? null : this.handleClose())}
                />
                <ModalHeaderContainer>
                    <ModalHeader>
                        {isNew ? "New" : "Update"} Billing Category
                    </ModalHeader>
                </ModalHeaderContainer>
                <ModalBody height={isNew ? "290px" : "200px"}>
                    {isNew && (
                        <Fragment>
                            <InfoLabel required>Building no.</InfoLabel>
                            <Select
                                value={building}
                                disabled={isModalLoading}
                                onChange={selectedBuilding =>
                                    this.setState({
                                        building: selectedBuilding
                                    })
                                }
                                options={buildingOptions}
                                isSearchable
                            />
                        </Fragment>
                    )}
                    <InfoLabel style={{ marginTop: "18px" }} required>
                        Bill type
                    </InfoLabel>
                    <Select
                        value={billType}
                        placeholder="Choose Bill Type"
                        required={!billType && hasError}
                        disabled={isModalLoading}
                        onChange={selectedBillType =>
                            this.setState({ billType: selectedBillType })
                        }
                        options={billTypeOptions}
                    />
                    <InfoLabel style={{ marginTop: "18px" }} required>
                        Bill name
                    </InfoLabel>
                    <Input
                        value={name}
                        required={hasError}
                        disabled={isModalLoading}
                        onChange={e => this.setState({ name: e.target.value })}
                        onKeyDown={e => {
                            if (e.key === "Enter") {
                                e.preventDefault();
                                this.handleSubmit();
                            }
                        }}
                    />
                    <FlexContainer style={{
                        justifyContent: "space-between"
                    }}>
                        <FlexColumnContainer style={{
                            marginRight: "10px",
                            width: "48%",
                        }}>
                            <InfoLabel required>
                                Overdue Type
                            </InfoLabel>
                            <Select
                                value={overdueType}
                                required={!overdueType && hasError}
                                disabled={isModalLoading}
                                onChange={selectedOverdueType =>
                                    this.setState({
                                        overdueType: selectedOverdueType
                                    })
                                }
                                options={overdueTypeOptions}
                            />
                        </FlexColumnContainer>
                        <FlexColumnContainer style={{
                            width: "48%",
                        }}>
                            <InfoLabel required>
                                {
                                    `Overdue Amount`
                                }
                            </InfoLabel>
                            <InputCurrency
                                min={0}
                                required={!overdueAmount && hasError}
                                disabled={isModalLoading}
                                value={overdueAmount}
                                // onChange={e =>
                                //     this.setState({
                                //         overdueAmount: e.target.value
                                //     })
                                // }
                                onChangeEvent={
                                    this.handleAmountChange.bind(this)
                                }
                                style={{
                                    textAlign: "right"
                                }}
                            />
                        </FlexColumnContainer>
                    </FlexContainer>
                    <MessageContainer />
                </ModalBody>
                <ModalFooter style={{ height: "65px" }}>
                    {isModalLoading ? (
                        <Loader text="PROCESSING..." />
                    ) : (
                        <FlexContainer style={{
                            justifyContent: "space-between"
                        }}>
                            <PrimaryAltButton
                                style={{
                                    height: "38px",
                                    width: "48%",
                                    fontSize: "14px",
                                    borderColor: colors.GRAY,
                                    color: colors.GRAY,
                                }}
                                onClick={() => this.handleClose()}
                                text="Discard"
                            />
                            <PrimaryButton
                                style={{
                                    height: "38px",
                                    width: "48%",
                                    fontSize: "14px"
                                }}
                                onClick={() => this.handleSubmit()}
                                text={
                                    isNew
                                        ? "Add billing category"
                                        : "Update billing category"
                                }
                            />
                        </FlexContainer>
                    )}
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => {
    return {
        buildingOptions:
            state.buildings &&
            state.buildings.map(building => {
                return { value: building.id, label: building.name };
            }),
        building: state.building,
        isModalLoading: state.isModalLoading,
        errorMessage: state.errorMessage
    };
};
export default connect(mapStateToProps)(BillingCategoryModal);

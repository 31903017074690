import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import BillingCategoryModal from "./BillingCategoryModal";
import ConfirmationModal from "containers/ConfirmationModal";
import styled, { css } from "styled-components";
import {
    removeBillingCategory,
    selectBuilding
} from "reducers/buildingReducer";
import { Select, FiltersContainer, FilterLabel } from "styles/Inputs";
import {
    TabHeaderContainer,
    TabNewItemContainer,
    ActiveTabHeader,
    InactiveTabHeader,
    QuickActiveTabHeader,
    QuickInactiveTabHeader
} from "styles/Tabs";
import {
    Table,
    TableHeader,
    TableRow,
    TableCell,
    TableRowEditAction,
    TableRowRemoveAction,
    EmptyListContainer,
    RevisedEmptyListContainer
} from "styles/Tables";
import { AddItemButton, NewItemLink } from "styles/Buttons";
import { FlexContainer,
     FlexColumnContainer,
     WhiteCardBox,
     HRContainer,
     PesoSign } from "styles/Containers";
import colors from "utils/colors";
import { currencyFormat } from "utils/InputPatterns";

const OverflowTable = styled(Table)`
    padding-right: 0;
    overflow-x: scroll;
    display: block;
`

class BuildingBillingCategoriesContainer extends Component {
    state = {
        billingCategory: null,
        billingCategoryModalVisible: false,
        confirmationModalVisible: false
    };

    showBillingCategoryModal(billingCategory) {
        if (billingCategory) {
            this.setState({
                billingCategory,
                billingCategoryModalVisible: true
            });
        } else {
            this.setState({
                billingCategory: null,
                billingCategoryModalVisible: true
            });
        }
    }

    hideBillingCategoryModal() {
        this.setState({ billingCategoryModalVisible: false });
    }

    showConfirmationModal(billingCategory) {
        this.setState({
            billingCategory,
            confirmationModalVisible: true
        });
    }

    hideConfirmationModal() {
        this.setState({ confirmationModalVisible: false });
    }

    handleRemoveBillingCategory() {
        const { building, dispatch } = this.props;
        const { billingCategory } = this.state;
        dispatch(
            removeBillingCategory({
                buildingId: building.id,
                name: billingCategory.name
            })
        );
    }

    handleBuildingChange(selectedBuilding) {
        const { dispatch } = this.props;
        dispatch(selectBuilding(selectedBuilding.value));
    }

    render() {
        const {
            billingCategories,
            building,
            buildingOptions,
            tabVisible,
            onTabChange
        } = this.props;
        const {
            billingCategory,
            billingCategoryModalVisible,
            confirmationModalVisible
        } = this.state;
        const selectedBuilding = building && {
            value: building.id,
            label: building.name
        };

        const billingCategoryRows =
            billingCategories &&
            billingCategories.map((billingCategory, i) => (
                <TableRow gray={i % 2 === 0} key={billingCategory.name}>
                    <TableCell>{billingCategory.name}</TableCell>
                    <TableCell>
                        {billTypeHelper(billingCategory.type)}
                    </TableCell>
                    <TableCell>{billingCategory.overdueType}</TableCell>
                    <TableCell
                        style={{ textAlign: "right" }}>
                        {
                            billingCategory.overdueAmount
                            ?   billingCategory.overdueType === "Raw"
                                ?   (
                                        <Fragment>
                                            <PesoSign />
                                            {currencyFormat(billingCategory.overdueAmount)}
                                        </Fragment>
                                )
                                :   (
                                        <Fragment>
                                            {currencyFormat(billingCategory.overdueAmount)}
                                            {`%`}
                                        </Fragment>
                                )
                            :   ""
                        }
                    </TableCell>
                    <TableRowEditAction
                        onClick={() =>
                            this.showBillingCategoryModal(billingCategory)
                        }
                        text="EDIT"
                    />
                    <TableRowRemoveAction
                        onClick={() =>
                            this.showConfirmationModal(billingCategory)
                        }
                        text="REMOVE"
                    />
                </TableRow>
            ));

        return (
            <Fragment>
                {tabVisible && (
                    <FlexContainer>

                        <WhiteCardBox
                            style={{
                                width: "20%",
                                minWidth: "230px",
                                marginRight: "3%",
                            }}>
                            <p>Quick Links</p>
                            <HRContainer />
                            <FlexColumnContainer>
                                <QuickInactiveTabHeader
                                    onClick={() => onTabChange("unitTypes")}
                                    style={{
                                        marginBottom: "10px",
                                    }}
                                >
                                    Unit Types
                                </QuickInactiveTabHeader>
                                <QuickActiveTabHeader
                                    style={{
                                        marginBottom: "10px",
                                    }}>
                                    Billing Categories
                                </QuickActiveTabHeader>
                                <QuickInactiveTabHeader
                                    onClick={() => onTabChange("uploads")}>
                                    Uploads
                                </QuickInactiveTabHeader>
                            </FlexColumnContainer>
                        </WhiteCardBox>

                        <WhiteCardBox style={{
                                width: "77%",
                                minWidth: "fit-content"
                            }}>
                            <FlexContainer style={{
                                justifyContent: "space-between"
                            }}>
                                <h3>Billing Categories</h3>
                                <AddItemButton
                                    onClick={() => this.showBillingCategoryModal()}
                                    text="Add new category"
                                    style={{
                                        float: "right",
                                        width: "180px",
                                    }}
                                    textStyle={{
                                        fontSize: "12px",
                                        width: "75%",
                                    }}
                                />
                            </FlexContainer>
                            <FiltersContainer>
                                <div style={{ width: "200px" }}>
                                    <FilterLabel>Building</FilterLabel>
                                    <Select
                                        value={selectedBuilding}
                                        onChange={this.handleBuildingChange.bind(
                                            this
                                        )}
                                        options={buildingOptions}
                                        isSearchable
                                    />
                                </div>
                            </FiltersContainer>
                            {billingCategories && billingCategories.length > 0 ? (
                                <Fragment>
                                    <Table>
                                        <TableHeader>
                                            <TableCell style={{
                                                minWidth: "180px",
                                            }}>
                                                Bill Name
                                            </TableCell>
                                            <TableCell style={{
                                                minWidth: "180px",
                                            }}>
                                                Type
                                            </TableCell>
                                            <TableCell style={{
                                                minWidth: "100px",
                                            }}>
                                                Overdue type
                                            </TableCell>
                                            <TableCell style={{
                                                minWidth: "100px"
                                            }}>
                                                Overdue amount
                                            </TableCell>
                                            <TableCell style={{
                                                minWidth: "100px",
                                            }} />
                                            <TableCell  style={{
                                                minWidth: "100px",
                                            }}/>
                                        </TableHeader>
                                        {billingCategoryRows}
                                    </Table>
                                </Fragment>
                            ) : (
                                <RevisedEmptyListContainer>
                                    There are no billing categories yet.{" "}
                                    <NewItemLink
                                        onClick={() => this.showBillingCategoryModal()}
                                        style={{
                                            color: colors.BLUE_GREEN
                                        }}
                                    >
                                        Click here to add one.
                                    </NewItemLink>
                                </RevisedEmptyListContainer>
                            )}
                            {billingCategoryModalVisible && (
                                <BillingCategoryModal
                                    billingCategory={billingCategory}
                                    billingCategoryNames={
                                        billingCategories && billingCategory
                                            ? billingCategories.map(
                                                  bc =>
                                                      bc.name !==
                                                          billingCategory.name &&
                                                      bc.name
                                              )
                                            : billingCategories &&
                                              billingCategories.map(bc => bc.name)
                                    }
                                    modalVisible={billingCategoryModalVisible}
                                    closeModal={this.hideBillingCategoryModal.bind(
                                        this
                                    )}
                                />
                            )}
                            {confirmationModalVisible && (
                                <ConfirmationModal
                                    body="Are you sure you want to remove this billing category?"
                                    modalVisible={confirmationModalVisible}
                                    onSubmit={this.handleRemoveBillingCategory.bind(
                                        this
                                    )}
                                    submitText="YES"
                                    closeModal={this.hideConfirmationModal.bind(
                                        this
                                    )}
                                    cancelText="NO"
                                />
                            )}
                        </WhiteCardBox>
                    </FlexContainer>
                )}
            </Fragment>
        );
    }
}

const billTypeHelper = billType => {
    switch (billType) {
        case "total_only":
            return "Total Only";
        case "floor_sized_dependent":
            return "Floor Area Dependent";
        case "rate_type":
            return "Rate Type";
        default:
            return "";
    }
};

const mapStateToProps = state => {
    return {
        buildingOptions:
            state.buildings &&
            state.buildings.map(building => {
                return { value: building.id, label: building.name };
            }),
        building: state.building,
        billingCategories: state.building && state.building.billingCategories,
    };
};

export default connect(mapStateToProps)(BuildingBillingCategoriesContainer);

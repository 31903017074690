import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import moment from "moment";
import { Link } from "react-router-dom";
import {
    FiEdit,
    FiMaximize2,
    FiMinimize2,
    FiEye,
    FiAlertTriangle,
    FiCalendar,
} from "react-icons/fi";
import { messageActions } from "reducers/messageReducer";
import styled, { css } from "styled-components";
import { MdAddCircleOutline } from "react-icons/md";
import { IoIosRemoveCircleOutline } from "react-icons/io";
import colors from "utils/colors";
import { Spinner } from "containers/Spinner";
import PageHeader from "containers/PageHeader";
import { fetchUnit, removeTenant } from "reducers/unitReducer";
import ConfirmationModal from "containers/ConfirmationModal";
import UnitModal from "./UnitModal";
import UnitBillsContainer from "containers/UnitBillsContainer";
import UnitRunningBalanceContainer from "containers/UnitRunningBalanceContainer";
import UnitPaymentsContainer from "containers/UnitPaymentsContainer";
import UnitActivityLogsContainer from "containers/UnitActivityLogsContainer";
import UnitTenantModal from "containers/UnitTenantModal";
import {
    FlexContainer,
    MainHeader,
    InfoContainer,
    InfoLabel,
    InfoText,
    SpacedInfoContainer,
    HRContainer,
    ClickableContainer,
    ContentContainer,
    FlexColumnContainer,
} from "styles/Containers";

const TenantDetailsContainer = styled.div`
    width: 230px;
    padding: 8px;
    border: solid 0.5px rgba(0, 0, 0, 0.2);
    border-radius: 8px 8px 8px 8px;
    border-left: 12px solid ${colors.RED};
    background-color: ${colors.LIGHTER_GRAY};

    ${(props) =>
        props.detailsVisible
            ? css`
                  width: 300px;
              `
            : css`
                  cursor: pointer;
              `}
`;

const TenantProfile = styled.div`
    background-color: lightgray;
    border-radius: 50%;
    padding: 15px;
    width: 12%;
    width: 50px;
    min-width: 45px;
    height: 50px;
    background-image: url(https://www.gravatar.com/avatar/e505654…?s=150&d=mm);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

class AdminUnitContainer extends Component {
    state = {
        unitModalVisible: false,
        tenantModalVisible: false,
        tenantDetailsVisible: false,
        confirmationModalVisible: false,
        tabVisible: "bills",
        activeModal: "",
    };

    componentDidMount() {
        const { unitId, isAuthorized, dispatch } = this.props;
        if (isAuthorized) {
            dispatch(fetchUnit(unitId));
        }
    }

    async handleSubmit(unitId) {
        const { dispatch } = this.props;

        if (!unitId) {
            dispatch({
                type: messageActions.DISPLAY_MESSAGE,
                payload: "Unit ID does not exist",
            });
            this.setState({ hasError: true });
        } else {
            await dispatch(removeTenant(unitId));
            this.hideConfirmationModal();
        }
    }

    showUnitModal() {
        this.setState({ unitModalVisible: true });
    }

    hideUnitModal() {
        this.setState({ unitModalVisible: false });
    }

    showTenantModal() {
        this.setState({ tenantModalVisible: true });
    }

    hideTenantModal() {
        this.setState({ tenantModalVisible: false });
    }

    showTenantDetails() {
        const { tenantDetailsVisible } = this.state;
        if (!tenantDetailsVisible) {
            this.setState({ tenantDetailsVisible: true });
        }
    }

    hideTenantDetails() {
        this.setState({ tenantDetailsVisible: false });
    }

    showConfirmationModal() {
        this.setState({ confirmationModalVisible: true });
    }

    hideConfirmationModal() {
        this.setState({ confirmationModalVisible: false });
    }

    render() {
        const { unit, unitId, isLoading, backTo } = this.props;
        const {
            unitModalVisible,
            tenantModalVisible,
            tenantDetailsVisible,
            confirmationModalVisible,
            tabVisible,
            activeModal,
        } = this.state;

        const unitHasOngoingTenantContract =
            unit &&
            unit.tenant &&
            unit.tenant.contractEnd &&
            moment(unit.tenant.contractEnd).isSameOrAfter(new Date(), "day");

        const tenantContractExpired =
            unit &&
            unit.tenant &&
            moment().isAfter(moment(unit.tenant.contractEnd), "day");

        return (
            <Fragment>
                <PageHeader
                    title={(backTo && backTo.label) || "Back to Units"}
                    backTo={(backTo && backTo.value) || `/admin/units`}
                />
                {isLoading ? (
                    <Spinner />
                ) : (
                    unit && (
                        <ContentContainer>
                            <div style={{ marginBottom: "30px" }}>
                                <MainHeader>
                                    Unit {unit.number}
                                    <FiEdit
                                        style={{
                                            margin: "0px 0px -2px 4px",
                                            cursor: "pointer",
                                        }}
                                        onClick={() => this.showUnitModal()}
                                    />
                                </MainHeader>
                                {/* <Link
                                    style={{
                                        textDecoration: "none"
                                    }}
                                    to={{
                                        pathname: `/admin/owners/${unit.ownerId}`,
                                        state: {
                                            backTo: {
                                                label: "Back to Unit",
                                                value: `/admin/units/${unit.id}`
                                            }
                                        }
                                    }}
                                >
                                    <SubHeader>{unit.ownerName}</SubHeader>
                                </Link> */}
                                <FlexColumnContainer>
                                    <HRContainer />
                                    <SpacedInfoContainer>
                                        <div>
                                            <InfoLabel>Building</InfoLabel>
                                            <InfoText>
                                                {unit.buildingName}
                                            </InfoText>
                                        </div>
                                        <div>
                                            <InfoLabel>Floor</InfoLabel>
                                            <InfoText>{unit.floor}</InfoText>
                                        </div>
                                        <div>
                                            <InfoLabel>Area</InfoLabel>
                                            <InfoText>
                                                {unit.floorArea}sqm
                                            </InfoText>
                                        </div>
                                        <div>
                                            <InfoLabel>Type</InfoLabel>
                                            <InfoText>{unit.unitType}</InfoText>
                                        </div>
                                        <div>
                                            <InfoLabel>Owner</InfoLabel>
                                            <Link
                                                style={{
                                                    textDecoration: "none",
                                                    color: colors.LIGHT_BLACK,
                                                }}
                                                to={{
                                                    pathname: `/admin/owners/${unit.ownerId}`,
                                                    state: {
                                                        backTo: {
                                                            label:
                                                                "Back to Unit",
                                                            value: `/admin/units/${unit.id}`,
                                                        },
                                                    },
                                                }}
                                            >
                                                <ClickableContainer>
                                                    <InfoText>
                                                        {unit.ownerName}
                                                        <FiEye
                                                            style={{
                                                                margin:
                                                                    "0 0 -2px 5px",
                                                            }}
                                                        />
                                                    </InfoText>
                                                </ClickableContainer>
                                            </Link>
                                        </div>
                                    </SpacedInfoContainer>
                                </FlexColumnContainer>
                                {unit.tenant && (
                                    <InfoLabel
                                        style={{
                                            margin: "10px 0",
                                        }}
                                    >
                                        Tenant
                                    </InfoLabel>
                                )}
                                {unit.tenant ? (
                                    <Fragment>
                                        <TenantDetailsContainer
                                            onClick={() =>
                                                this.showTenantDetails()
                                            }
                                            detailsVisible={
                                                tenantDetailsVisible
                                            }
                                        >
                                            <InfoContainer
                                                style={{
                                                    marginTop: "0",
                                                    justifyContent:
                                                        "space-between",
                                                }}
                                            >
                                                <FlexContainer>
                                                    <TenantProfile />
                                                    <InfoText
                                                        style={{
                                                            display: "flex",
                                                            alignItems:
                                                                "center",
                                                            marginLeft: "10px",
                                                        }}
                                                    >
                                                        <Link
                                                            style={{
                                                                marginRight:
                                                                    "5px",
                                                                color: "black",
                                                                textDecoration:
                                                                    "none",
                                                            }}
                                                            to={{
                                                                pathname: `/admin/tenants/${unit.tenant.id}`,
                                                                state: {
                                                                    backTo: {
                                                                        label:
                                                                            "Back to Unit",
                                                                        value: `/admin/units/${unit.id}`,
                                                                    },
                                                                },
                                                            }}
                                                        >
                                                            {unit.tenant.name}
                                                        </Link>
                                                        {!tenantDetailsVisible &&
                                                            tenantContractExpired && (
                                                                <FiAlertTriangle
                                                                    style={{
                                                                        color:
                                                                            colors.RED,
                                                                    }}
                                                                />
                                                            )}
                                                    </InfoText>
                                                </FlexContainer>
                                                <div>
                                                    {tenantDetailsVisible ? (
                                                        <FlexContainer>
                                                            <ClickableContainer
                                                                color={
                                                                    colors.RED_HOVER
                                                                }
                                                            >
                                                                <IoIosRemoveCircleOutline
                                                                    style={{
                                                                        marginRight:
                                                                            "5px",
                                                                        marginTop:
                                                                            "2px",
                                                                        strokeWidth:
                                                                            "1em",
                                                                    }}
                                                                    onClick={
                                                                        () => this.setState({activeModal: "VACATE"}, () => this.showConfirmationModal())
                                                                    }
                                                                />
                                                            </ClickableContainer>
                                                            <ClickableContainer
                                                                color={
                                                                    colors.GREEN_HOVER
                                                                }
                                                            >
                                                                <FiEdit
                                                                    onClick={() =>
                                                                        unitHasOngoingTenantContract
                                                                            ?   (
                                                                                    this.setState({ activeModal: "CREATE" }, () => this.showConfirmationModal())
                                                                                )
                                                                            :   this.showTenantModal()
                                                                    }
                                                                />
                                                            </ClickableContainer>
                                                            {
                                                                // <Link
                                                                //     style={{
                                                                //         color: "black",
                                                                //         textDecoration:
                                                                //             "none"
                                                                //     }}
                                                                //     to={{
                                                                //         pathname: `/admin/tenants/${unit.tenant.id}`,
                                                                //         state: {
                                                                //             backTo: {
                                                                //                 label:
                                                                //                     "Back to Unit",
                                                                //                 value: `/admin/units/${unit.id}`
                                                                //             }
                                                                //         }
                                                                //     }}
                                                                // >
                                                                //     <ClickableContainer>
                                                                //         <FiEye
                                                                //             style={{
                                                                //                 marginLeft:
                                                                //                     "5px"
                                                                //             }}
                                                                //         />
                                                                //     </ClickableContainer>
                                                                // </Link>
                                                            }
                                                            <ClickableContainer
                                                                color={
                                                                    colors.BLUE_HOVER
                                                                }
                                                            >
                                                                <FiMinimize2
                                                                    style={{
                                                                        marginLeft:
                                                                            "5px",
                                                                    }}
                                                                    onClick={() =>
                                                                        this.hideTenantDetails()
                                                                    }
                                                                />
                                                            </ClickableContainer>
                                                        </FlexContainer>
                                                    ) : (
                                                        <ClickableContainer
                                                            color={
                                                                colors.BLUE_HOVER
                                                            }
                                                        >
                                                            <FiMaximize2 />
                                                        </ClickableContainer>
                                                    )}
                                                </div>
                                            </InfoContainer>
                                            {tenantDetailsVisible && (
                                                <Fragment>
                                                    <InfoContainer>
                                                        <FlexColumnContainer>
                                                            <FlexContainer
                                                                style={{
                                                                    marginBottom:
                                                                        "5px",
                                                                }}
                                                            >
                                                                <FiCalendar
                                                                    style={{
                                                                        marginTop:
                                                                            "1px",
                                                                    }}
                                                                />
                                                                <InfoLabel
                                                                    style={{
                                                                        fontWeight:
                                                                            "500",
                                                                        margin:
                                                                            "0 5px",
                                                                    }}
                                                                >
                                                                    Contract
                                                                    Start:
                                                                </InfoLabel>
                                                                <InfoText>
                                                                    {moment(
                                                                        unit
                                                                            .tenant
                                                                            .contractStart
                                                                    ).format(
                                                                        "MM/DD/YYYY"
                                                                    )}
                                                                </InfoText>
                                                            </FlexContainer>
                                                            <FlexContainer>
                                                                <FiCalendar
                                                                    style={{
                                                                        marginTop:
                                                                            "1px",
                                                                    }}
                                                                />
                                                                <InfoLabel
                                                                    style={{
                                                                        fontWeight:
                                                                            "500",
                                                                        margin:
                                                                            "0 5px",
                                                                    }}
                                                                >
                                                                    Contract
                                                                    End:
                                                                </InfoLabel>
                                                                <InfoText
                                                                    style={{
                                                                        color:
                                                                            colors.RED,
                                                                    }}
                                                                >
                                                                    {moment(
                                                                        unit
                                                                            .tenant
                                                                            .contractEnd
                                                                    ).format(
                                                                        "MM/DD/YYYY"
                                                                    )}
                                                                </InfoText>
                                                            </FlexContainer>
                                                            {tenantContractExpired && (
                                                                <InfoLabel
                                                                    style={{
                                                                        color:
                                                                            colors.RED,
                                                                        fontWeight:
                                                                            "500",
                                                                        marginTop:
                                                                            "15px",
                                                                        marginBottom:
                                                                            "0",
                                                                    }}
                                                                >
                                                                    Contract has
                                                                    expired.
                                                                </InfoLabel>
                                                            )}
                                                        </FlexColumnContainer>
                                                    </InfoContainer>
                                                </Fragment>
                                            )}
                                        </TenantDetailsContainer>
                                    </Fragment>
                                ) : (
                                    <InfoContainer>
                                        <div>
                                            <InfoLabel>Tenant</InfoLabel>
                                            <InfoText
                                                style={{
                                                    color: colors.BLUE,
                                                    cursor: "pointer",
                                                }}
                                                onClick={() =>
                                                    this.showTenantModal()
                                                }
                                            >
                                                <MdAddCircleOutline
                                                    style={{
                                                        marginBottom: "-2px",
                                                    }}
                                                />
                                                Add Tenant
                                            </InfoText>
                                        </div>
                                    </InfoContainer>
                                )}
                            </div>
                            <UnitBillsContainer
                                tabVisible={tabVisible === "bills"}
                                onTabChange={(selectedTab) =>
                                    this.setState({ tabVisible: selectedTab })
                                }
                            />
                            <UnitPaymentsContainer
                                tabVisible={tabVisible === "payments"}
                                onTabChange={(selectedTab) =>
                                    this.setState({ tabVisible: selectedTab })
                                }
                            />
                            <UnitActivityLogsContainer
                                tabVisible={tabVisible === "activityLogs"}
                                onTabChange={(selectedTab) =>
                                    this.setState({ tabVisible: selectedTab })
                                }
                            />
                            <UnitRunningBalanceContainer
                                tabVisible={tabVisible === "runningBalance"}
                                onTabChange={(selectedTab) =>
                                    this.setState({ tabVisible: selectedTab })
                                }
                            />
                            {unitModalVisible && (
                                <UnitModal
                                    unit={unit}
                                    modalVisible={unitModalVisible}
                                    closeModal={this.hideUnitModal.bind(this)}
                                />
                            )}
                            {tenantModalVisible && (
                                <UnitTenantModal
                                    modalVisible={tenantModalVisible}
                                    closeModal={this.hideTenantModal.bind(this)}
                                    unitId={unitId}
                                    tenant={unit.tenant}
                                />
                            )}
                            {
                                confirmationModalVisible &&
                                unitHasOngoingTenantContract &&
                                activeModal === "CREATE" &&
                                    (
                                        <ConfirmationModal
                                            header="Create a new Tenant?"
                                            body="Tenant has a pending contract, are you sure you want to create a new one?"
                                            modalVisible={confirmationModalVisible}
                                            onSubmit={this.showTenantModal.bind(
                                                this
                                            )}
                                            submitText={`Yes, proceed >`}
                                            closeModal={this.hideConfirmationModal.bind(
                                                this
                                            )}
                                            cancelText="Cancel"
                                        />
                                    )
                            }
                            {
                                confirmationModalVisible &&
                                unitHasOngoingTenantContract &&
                                activeModal === "VACATE" &&
                                    (
                                        <ConfirmationModal
                                            body="There is an existing tenant, are you sure you want to vacate the tenant?"
                                            modalVisible={
                                                confirmationModalVisible
                                            }
                                            onSubmit={() => this.handleSubmit(unitId)}
                                            submitText="YES"
                                            closeModal={
                                                this.hideConfirmationModal.bind(this)
                                            }
                                            cancelText="NO"
                                        />
                                    )
                            }
                        </ContentContainer>
                    )
                )}
            </Fragment>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        unit: state.unit,
        isLoading: state.isLoading,
        isAuthorized: state.isAuthorized,
    };
};

export default connect(mapStateToProps)(AdminUnitContainer);

import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { FiPlus, FiEdit, FiDownload, FiCreditCard } from "react-icons/fi";
import colors from "utils/colors";
import PaypalButtonImage from "../../assets/images/PaypalButton.png";
import DragonPayImage from "../../assets/images/DragonPay.png";
import PaypalLogo from "../../assets/images/PaypalLogo.png";


export const NewItemLink = styled.a`
    color: ${colors.BLUE};
    cursor: pointer;
`;

export const NewItemContainer = styled.div`
    display: flex;
    height: 46px;
    align-items: center;
`;

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    height: 30px;
    font-size: 11px;
    border-style: none;
    border-radius: 18px;
    padding: 0 15px;
    background-color: ${colors.BLUE_GREEN};
    color: white;
    cursor: pointer;
    font-family: "Poppins", sans-serif;

    :disabled {
        background-color: gray;
        cursor: unset;
    }

    :focus {
        outline: none;
    }
`;

export const AltButton = styled.button`
    height: 30px;
    font-size: 11px;
    border-style: solid;
    border-radius: 18px;
    border-color: ${colors.BLUE_GREEN};
    padding: 0 12px;
    background-color: white;
    color: ${colors.BLUE_GREEN};
    cursor: pointer;
    font-family: "Poppins", sans-serif;

    :disabled {
        background-color: gray;
        cursor: unset;
    }

    :focus {
        outline: none;
    }
`;

export const RedAltButton = styled.button`
    height: 30px;
    font-size: 11px;
    border-style: solid;
    border-width: 0.5px;
    border-color: ${colors.RED};
    border-radius: 18px;
    padding: 0 12px;
    background-color: white;
    color: ${colors.RED};
    cursor: pointer;

    :disabled {
        background-color: gray;
        cursor: unset;
    }

    :focus {
        outline: none;
    }
`;

export const CancelButton = styled.button`
    height: 30px;
    padding: 0 12px;
    font-size: 11px;
    background-color: white;
    color: ${colors.PRIMARY};
    border: 0;
    float: right;
    cursor: pointer;

    :disabled {
        color: gray;
        cursor: unset;
    }

    :focus {
        outline: none;
    }
`;

export const TextLink = styled(Link)`
    color: ${colors.BLUE};
    text-decoration: none;
`;

export const PrimaryButton = ({ onClick, text, disabled, style }) => (
    <Button style={style} onClick={onClick} disabled={disabled}>
        <span>{text}</span>
    </Button>
);

export const PrimaryAltButton = ({ onClick, text, disabled, style }) => (
    <AltButton style={style} onClick={onClick} disabled={disabled}>
        <span>{text}</span>
    </AltButton>
);

export const AddItemButton = ({ onClick, text, disabled, style, textStyle }) => (
    <Button style={style} onClick={onClick} disabled={disabled}>
        <FiPlus style={{ marginRight: "2px" }} />
        <span style={textStyle}>{text}</span>
    </Button>
);

export const UpdateItemButton = ({ onClick, text, disabled, style }) => (
    <Button style={style} onClick={onClick} disabled={disabled}>
        <FiEdit style={{ marginRight: "2px" }} />
        <span>{text}</span>
    </Button>
);

export const ImageButton = styled.button`
    height: 32px;
    width: 170px;
    border: none;
    border-radius: 5px;
    display: flex;
    background-color: transparent;
    color: ${colors.WHITE};
    font-size: 14px
    justify-content: center;
    cursor: pointer;

    :disabled {
        background-color: gray;
        cursor: unset;
    }

    :focus {
        outline: none;
    }
`;

export const PaymentButton = ({ onClick, text, disabled, style }) => (
    <Button style={style} onClick={onClick} disabled={disabled}>
        <FiCreditCard style={{ marginRight: "4px" }} />
        <span>{text}</span>
    </Button>
);

export const PaypalButton = ({ onClick, isLoading, style }) => (
    <ImageButtonContainer onClick={onClick} disabled={isLoading} style={style}>
        {isLoading ? (
            <span>REDIRECTING</span>
        ) : (
            <img src={PaypalLogo} alt="Pay with PayPal" />
        )}
    </ImageButtonContainer>
);

export const DragonPayButton = ({ onClick, isLoading, style }) => (
    <ImageButtonContainer onClick={onClick} disabled={isLoading} style={style}>
        {isLoading ? (
            <span>REDIRECTING</span>
        ) : (
            <img
                src={DragonPayImage}
                alt="Pay via DragonPay"
                style={{ height: "32px", width: "auto" }}
            />
        )}
    </ImageButtonContainer>
);

export const ClickPayNowbutton = ({ onClick, isLoading, style }) => (
    <ImageButtonContainer onClick={onClick} disabled={isLoading} style={{
        padding: "0 15px",
        height: "40px",
        width: "200px",
        maxWidth: "fit-content",
        alignItems: "center",
    }}>
        {isLoading ? (
            <span>REDIRECTING</span>
        ) : (
            <span>Click here to pay now!</span>
        )}
    </ImageButtonContainer>
);


const ImageButtonContainer = styled(ImageButton)`
    height: 80px;
    background-color: ${colors.LIGHT_GRAY};
    width: 175px;

    :hover {
        border: 2px solid ${colors.BLUE_GREEN};
    }

    img {
        width: 100px;
    }

    :disabled {
        background-color: gray;
        cursor: unset;
    }

    :focus {
        outline: none;
    }
`

const DLButton = styled(Button)`
    display: flex;
    align-items: center;
`;

export const DownloadButton = ({ onClick, style, children }) => (
    <DLButton onClick={onClick} style={style}>
        <FiDownload style={{ marginRight: "2px" }} />
        {children}
    </DLButton>
);

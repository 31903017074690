import axios from "axios";
import moment from "moment";
import { toast } from "react-toastify";
import { userActions } from "./userReducer";
import { messageActions } from "./messageReducer";

const FETCH_BILLS = "FETCH_BILLS";
const FETCH_BILLS_SUCCESS = "FETCH_BILLS_SUCCESS";
const FETCH_BILLS_FAIL = "FETCH_BILLS_FAIL";

const ADD_BILL = "ADD_BILL";
const ADD_BILL_SUCCESS = "ADD_BILL_SUCCESS";
const ADD_BILL_FAIL = "ADD_BILL_FAIL";

const FETCH_BILL = "FETCH_BILL";
const FETCH_BILL_SUCCESS = "FETCH_BILL_SUCCESS";
const FETCH_BILL_FAIL = "FETCH_BILL_FAIL";

export const billActions = {
    ADD_BILL,
    ADD_BILL_SUCCESS,
    ADD_BILL_FAIL,
    FETCH_BILL,
    FETCH_BILL_SUCCESS,
    FETCH_BILL_FAIL,
    FETCH_BILLS,
    FETCH_BILLS_SUCCESS,
    FETCH_BILLS_FAIL
};

export default (state, action) => {
    const { payload } = action;
    switch (action.type) {
        case ADD_BILL_SUCCESS:
            return Object.assign({}, state, {
                bills: [payload].concat(state.bills)
            });
        default:
            return state;
    }
};

export const fetchBills = () => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_BILLS });
        return axios
            .request({
                url: `/api/${state.isAdmin ? "admin" : "account"}/bills`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_BILLS_SUCCESS,
                        payload: { bills: res.bills, payments: res.payments }
                    });
                    return res.bills;
                } else {
                    dispatch({ type: FETCH_BILLS_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_BILLS_FAIL });
            });
    };
};

export const addBill = unitBill => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: ADD_BILL });
        return axios
            .request({
                url: `/api/admin/add_bill`,
                method: "POST",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                data: {
                    unitId: unitBill.unitId,
                    coverageStart: unitBill.coverageStart,
                    coverageEnd: unitBill.coverageEnd,
                    billDate: unitBill.billDate,
                    dueDate: unitBill.dueDate,
                    subTotal:
                        unitBill.billingCategory.value.type === "total_only"
                            ? unitBill.amount
                            : unitBill.subTotal,
                    discounts: unitBill.discounts,
                    totalAmountDue: unitBill.total,
                    category: unitBill.billingCategory.label,
                    remarks: unitBill.remarks,
                    rate:
                        unitBill.billingCategory.value.type !== "total_only"
                            ? unitBill.rate
                            : null,
                    consumption:
                        unitBill.billingCategory.value.type === "rate_type"
                            ? unitBill.consumption
                            : null,
                    notifyTenant: unitBill.notifyTenant
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: ADD_BILL_SUCCESS,
                        payload: {
                            id: res.billId,
                            buildingId: unitBill.buildingId,
                            buildingName: unitBill.buildingName,
                            unitId: unitBill.unitId,
                            unitNumber: unitBill.unitNumber,
                            coverageStart: unitBill.coverageStart,
                            coverageEnd: unitBill.coverageEnd,
                            billDate: unitBill.billDate,
                            dueDate: unitBill.dueDate,
                            subTotal:
                                unitBill.billType === "total_only"
                                    ? unitBill.amount
                                    : unitBill.subTotal,
                            discounts: unitBill.discounts,
                            totalAmountDue: unitBill.total,
                            category: unitBill.billingCategory.label,
                            remarks: unitBill.remarks,
                            rate:
                                unitBill.billingCategory.value.type !==
                                "total_only"
                                    ? unitBill.rate
                                    : null,
                            consumption:
                                unitBill.billingCategory.value.type ===
                                "rate_type"
                                    ? unitBill.consumption
                                    : null,
                            remainingBalance: unitBill.total,
                            status: moment(unitBill.dueDate).isBefore(
                                new Date(),
                                "day"
                            )
                                ? "Overdue"
                                : "Unpaid"
                        }
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                    toast("Successfully added new bill.");
                } else {
                    dispatch({ type: ADD_BILL_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: ADD_BILL_FAIL });
            });
    };
};

export const fetchBill = ({ billId }) => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_BILL });
        return axios
            .request({
                url: `/api/${state.isAdmin ? "admin" : "account"}/bill`,
                method: "GET",
                headers: {
                    Authorization: `Bearer ${state && state.jwtoken}`
                },
                params: {
                    id: billId
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_BILL_SUCCESS,
                        payload: {
                            bill: res.bill
                        }
                    });
                } else {
                    dispatch({ type: FETCH_BILL_FAIL });
                    console.error(res.message);
                    toast("Something went wrong please try again later.");
                }
            })
            .catch(err => {
                if (err.response && err.response.status === 401) {
                    dispatch({ type: userActions.LOGOUT });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: "Please enter your email and password."
                    });
                } else {
                    console.error(err);
                    toast("Something went wrong please try again later.");
                }
                dispatch({ type: FETCH_BILL_FAIL });
            });
    };
};

export const fetchAnonymousBill = billId => {
    return (dispatch, getState) => {
        const state = getState();
        dispatch({ type: FETCH_BILL });
        return axios
            .request({
                url: `/api/anonymous_bill`,
                method: "GET",
                params: {
                    id: billId
                }
            })
            .then(res => res.data)
            .then(res => {
                if (res.success) {
                    dispatch({
                        type: FETCH_BILL_SUCCESS,
                        payload: {
                            bill: res.bill
                        }
                    });
                    dispatch({
                        type: messageActions.CLEAR_MESSAGE
                    });
                } else {
                    dispatch({ type: FETCH_BILL_FAIL });
                    dispatch({
                        type: messageActions.DISPLAY_MESSAGE,
                        payload: res.message
                    });
                }
            })
            .catch(err => {
                console.error(err);
                toast("Something went wrong please try again later.");
                dispatch({ type: FETCH_BILL_FAIL });
            });
    };
};

import React from "react";
import { Link, NavLink } from "react-router-dom";
import styled, { css } from "styled-components";
import colors from "../utils/colors";
import AppLogo from "../../assets/images/logo.png";

export const MainHeader = styled.div`
    color: ${colors.PRIMARY};
    font-weight: bold;
    font-size: x-large;
`;

export const FlexContainer = styled.div`
    display: flex;
    flex-direction: row;
`;

export const FlexColumnContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const FlexWrapContainer = styled(FlexContainer)`
    flex-wrap: wrap;
`;

export const FlexCenterContainer = styled(FlexContainer)`
    align-items: center;
    justify-content: center;
`;

export const AccountMainHeader = styled.div`
    font-weight: bold;
    font-size: 16px;
    margin-bottom: 18px;
`;

export const SubHeader = styled.div`
    color: ${colors.PRIMARY};
`;

export const InfoHeader = styled.div`
    color: ${colors.PRIMARY};
    font-size: small;
    font-weight: bold;
    margin: 20px 0 8px 0;
`;

export const InfoContainer = styled.div`
    display: flex;
    width: 100%;
    margin-top: 18px;

    > div:not(:first-child) {
        margin-left: 15px;
    }
`;

export const SpacedInfoContainer = styled(InfoContainer)`
    justify-content: space-between;
    padding-right: 100px;
    max-width: 600px;
    margin-top: 0;
`;

export const HRContainer = styled.hr`
    background-color: ${colors.TRANSPARENT_GRAY};
    margin: 10px 0;
    max-width: 600px;
    width: 100%;
    height: 1px;
    border: 0;
`;

export const FullHRContainer = styled(HRContainer)`
    max-width: 100%;
    margin-bottom: 14px;
`

export const InfoLabel = styled.div`
    margin-bottom: 8px;
    font-size: 12px;
    font-weight: 500;

    ${props =>
        props.required &&
        css`
            ::after {
                content: " *";
                color: red;
            }
        `}
`;

export const InfoText = styled.div`
    font-size: 12px;
`;

export const HeaderContainer = styled(FlexContainer)`
    margin-bottom: 30px;
`;

export const ProfileImage = styled.div`
    background-color: lightgray;
    border-radius: 50%;
    padding: 15px;
    width: 12%;
    width: 150px;
    min-width: 145px;
    height: 150px;
    margin-right: 50px;
    background-image: ${props => `url(${props.background})`};
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

export const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
`;

const LogoImage = styled.img`
    height: 120px;
    width: 120px;
    margin-left: 20px;
`;

export const BackgroundContainer = styled.div`
    display: flex;
    height: 100vh;
    width: 100%;
    background-color: ${colors.PRIMARY};
    align-items: center;
    justify-content: center;
    color: ${colors.LIGHT_BLACK};
`;

export const LoginContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 440px;
    height: auto;
    padding: 20px 50px;
    background-color: white;
    justify-content: center;
    border-radius: 30px;

    @media (min-width: 600px) {
        ${props =>
            props.height &&
            css`
                height: ${props.height};
            `}
    }

    @media (max-width: 600px) {
        display: initial;
        flex-direction: unset;
        height: 100vh;
        width: 100%;
        padding: 50px;
        border-radius: 0;
        overflow: scroll;
    }
`;

export const LoginHeader = styled.h2`
    margin: 10px 0;
    text-align: center;
    font-size: 21px;
`;

export const LoginSubHeader = styled.div`
    margin: 20px 0;
    text-align: center;
`;

export const LoginFooterContainer = styled.div`
    text-align: center;
    font-size: 11px;
    margin-top: 40px;
`;

export const Logo = () => (
    <LogoContainer>
        <LogoImage src={AppLogo} />
    </LogoContainer>
);

export const LinkContainer = styled(Link)`
    color: unset;
    text-decoration: none;
`;

export const SideNavLinkContainer = styled(NavLink)`
    color: white;
    display: flex;
    flex-direction: row;
    padding: 15px 30px;
    width: 100%;
    text-decoration: none;
    align-items: center;
    font-size: 12px;
    cursor: pointer;

    svg {
        margin-bottom: 3px;
        margin-right: 10px;
        height: 14px;
        width: 14px;
    }

    :hover {
        color: ${colors.SECONDARY};
    }
`;

export const ClickableContainer = styled.div`
    cursor: pointer;

    :hover {
        ${props =>
        props.color
            ? css`
                  color: ${props.color};
              `
            : css`
                  color: ${colors.PRIMARY};
              `}
    }
`;

export const ContentContainer = styled.div`
    min-height: calc(100vh - 110px);
    margin: 30px 0;
    padding: 0 40px 0 40px;
    overflow: auto;
`;

export const GrayContentContainer = styled(ContentContainer)`
    background-color: ${colors.LIGHT_GRAY}
    min-height: calc(100vh - 50px);
    margin: 0px;
    padding: 40px 40px 0 40px;
    overflow: auto;
`

export const HorizontalDivider = styled.div`
    height: 1px;
    width: 100%;
    border-style: solid;
    border-color: rgba(0, 0, 0, 0.2);
    border-width: 0 0 0.5px 0;
`;

export const ProfileHeader = styled(FlexContainer)`
    background-color: ${colors.LIGHTER_GRAY};
    min-height: auto;
    overflow: auto;
    align-items: center;
`

export const ProfileDetails = styled(ProfileHeader)`
    width: 85%;
    margin: 30px;
    float: left;
    padding: 30px 40px;
    justify-content: space-between;
    flex-wrap: wrap
    max-width: fit-content

    h3 {
        width: 100%;
    }
`


export const ProfileDetailsBox = styled(ProfileHeader)`
    background-color: ${colors.LIGHTER_GRAY};
    color: ${colors.LIGHT_BLACK};
    flex-direction: column;
    align-items: flex-start;
    overflow: hidden;
    max-width: fit-content;

    div {
        display: flex;
        flex-direction: row;
        width: 100%;
        margin-bottom: 0.5rem;
    }

    span {
        display: flex;
        align-items: center;
        width: 100%;
        min-width: 140px;
        text-transform: none;
        word-break: break-word;
    }

    p {
        min-width: 140px;
        font-weight: 600;
    }
`


export const ProfileNameHolder = styled(MainHeader)`

    color: ${colors.SEMI_BLACK};

    p {
        font-weight: 400;
        color: ${colors.PRIMARY};
        margin-top: 0.5rem;
        margin-bottom: 0;
        font-size: 12px;
    }

    a {
        font-size: small;
    }
`

export const ProfileContent = styled(ProfileHeader)`
    background-color: white;
    color: ${colors.LIGHT_BLACK};
    padding: 30px 65px;
    flex-direction: column;
    align-items: flex-start;

    div {
        display: flex;
        flex-direction: row;
        width: 100%;
    }

    span {
        display: flex;
        align-items: center;
        width: 30%;
        min-width: 100px;
        text-transform: none;
    }

    p {
        width: 20%;
        min-width: 100px;
    }

`

export const AmenityTitle = styled.h3`
    margin-bottom: 0;
    margin-top: 2rem;
    font-weight: 600;
`

export const PesoSign = () => (
    <span>
        &#8369;
    </span>
);


export const GrayCardBox = styled(FlexColumnContainer)`
    background-color: ${colors.LIGHTER_GRAY};
    padding: 25px 30px;
    width: 45%;
    min-width: 450px;
    max-width: 500px;
    border-radius: 10px;

    h3 {
        color: ${colors.PRIMARY};
        margin-top: 0;
        margin-bottom: 2rem;
    }

    p {
        font-size: 14px;
        font-weight: 600;
        margin: 0;
        width: 50%;
    }

    span {
        font-size: 14px;
        width: 50%;
    }


`

export const WhiteCardBox = styled(GrayCardBox)`
    background-color: white;
    border: 2px solid ${colors.LIGHT_GRAY};
    height: max-content;

    p {
        font-weight: 500;
    }
`

export const CashValue = styled.p`
    color: ${colors.BLUE}
    font-weight: 600;
`

const ErrorDesign = styled.div`
    border: 2px solid ${colors.BORDER_PINK};
    background-color: ${colors.BG_LIGHT_PINK};
    border-radius: 10px;
    font-size: 10px;
    font-weight: normal;
    padding: 15px 20px;
    margin-bottom: 15px;
`

export const ErrorContainer = ({ style, onClick, children }) => (
    <ErrorDesign style={style} onClick={onClick}>
        {children}
    </ErrorDesign>
);


